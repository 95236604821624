<div class="login-wrapper">
  <div class="content-wrapper">
    <h1>kis portás</h1>
    <div class="form-wrapper">
      <form (submit)="register()" #loginForm="ngForm">
        <custom-input
          [type]="'email'"
          id="username"
          name="username"
          label="Felhasználónév"
          [required]="true"
          [(ngModel)]="loginGroup.username"
        ></custom-input>

        <br />
        <custom-input
          [type]="'password'"
          id="password"
          name="password"
          [required]="true"
          [(ngModel)]="loginGroup.password"
          (ngModelChange)="checkRequirements()"
          label="Jelszó"
        ></custom-input>

        <br />

        <custom-input
          [type]="'password'"
          id="password"
          name="passwordCheck"
          [required]="true"
          [(ngModel)]="passwordCheck"
          (ngModelChange)="checkRequirements()"
          label="Jelszó megerősítése"
        ></custom-input>

        <br />

        <span [ngClass]="{ success: pwRequirements.atLeastOneCapital }"
          >Minimum 1 nagybetű</span
        >
        <span [ngClass]="{ success: pwRequirements.atLeastOneNumber }"
          >Minimum 1 szám</span
        >
        <span [ngClass]="{ success: pwRequirements.atLeastEighthDigits }"
          >Minimum 8 karakter</span
        >
        <span [ngClass]="{ success: pwRequirements.doubleChecked }"
          >A két jelszó megegyezik</span
        >

        <button
          class="primary-button"
          type="submit"
          [disabled]="loginForm.invalid || !checkPassword()"
        >
          <span *ngIf="!isLoading">Regisztráció</span>
          <app-loading-spinner
            [loading]="isLoading"
            *ngIf="isLoading"
          ></app-loading-spinner>
        </button>
      </form>
    </div>
    <div class="placeholder"></div>
  </div>
  <div class="background"></div>
</div>
