<div class="successful-scan-wrapper">
  <h1>{{ langService.dictionary["scan-successful"][langService.lang] }}</h1>
  <i class="success-icon"></i>
  <div>
    <button class="secondary-button" (click)="closeDialog(false)">
      {{ langService.dictionary["close"][langService.lang] }}
    </button>
    <button class="primary-button" (click)="closeDialog(true)">
      {{ langService.dictionary["next"][langService.lang] }}
    </button>
  </div>
</div>
