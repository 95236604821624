import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../@environments/environtment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private http: HttpClient) {}

  public server: string = environment.url;

  public async getVersion(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http.get<any>(`${this.server}api/version`).subscribe({
        next: (res: { data: string }) => {
          resolve(res.data);
        },
        error: () => {
          console.log('cannot get version');
          reject();
        },
      });
    });
  }
}
