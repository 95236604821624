<div class="booking-details-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-name clickable">
    <h1>{{ langService.dictionary["booking-details"][langService.lang] }}</h1>
  </div>
  <div class="form-wrapper clickable">
    <form (ngSubmit)="nextStep()" #bookingForm="ngForm">
      <div class="input-wrapper">
        <custom-input
          name="contact_name"
          [(ngModel)]="bookingDetails.contact_name"
          [required]="true"
          [label]="langService.dictionary['name'][langService.lang] + '*'"
          [maxlength]="32"
        />
      </div>

      <div class="input-wrapper">
        <custom-select
          id="nationality"
          name="nationality"
          [required]="true"
          [(ngModel)]="bookingDetails.nationality"
          [options]="
            nationalityOptions
              | countryFilter : hotelService?.selectedHotel?.countries
          "
          (ngModelChange)="setCommunicationLang($event)"
          [label]="
            langService.dictionary['nationality'][langService.lang] + '*'
          "
        />
      </div>

      <div class="input-wrapper">
        <custom-select
          id="language"
          name="language"
          [required]="true"
          [(ngModel)]="bookingDetails.language"
          [options]="
            communicationLangOptions
              | languageFilter : hotelService?.selectedHotel?.languages
          "
          [label]="
            langService.dictionary['communication-language'][langService.lang] +
            '*'
          "
        />
      </div>

      <div class="input-wrapper">
        <custom-select
          id="channel"
          name="channel"
          [required]="true"
          [(ngModel)]="bookingDetails.channel"
          [options]="chanelOptions"
          [label]="langService.dictionary['channel'][langService.lang] + '*'"
        />
      </div>

      <div class="input-wrapper">
        <custom-select
          *ngIf="bookingDetails.channel === 'KOZVETITO_ONLINE'"
          id="source"
          name="source"
          [(ngModel)]="bookingDetails.source"
          [options]="sourceOptions"
          [label]="langService.dictionary['platform'][langService.lang]"
        />
      </div>

      <div class="input-wrapper">
        <custom-input
          name="contact_email"
          [(ngModel)]="bookingDetails.contact_email"
          [type]="'email'"
          [label]="langService.dictionary['email'][langService.lang]"
          [maxlength]="64"
        />
      </div>

      <div class="input-wrapper">
        <custom-input
          name="contact_phone"
          [(ngModel)]="bookingDetails.contact_phone"
          [type]="'text'"
          [label]="langService.dictionary['phone'][langService.lang]"
          [maxlength]="12"
        />
      </div>

      <div class="input-wrapper">
        <div class="checkbox-wrapper">
          <custom-checkbox
            id="business"
            name="work_related"
            [name]="'work_related'"
            [label]="langService.dictionary['work'][langService.lang]"
            [(ngModel)]="bookingDetails.work_related"
            (ngModelChange)="resetCompanyDetails($event)"
          />
          <custom-checkbox
            id="group"
            name="group_booking"
            [name]="'group_booking'"
            [label]="langService.dictionary['group'][langService.lang]"
            [(ngModel)]="bookingDetails.group_booking"
          />
        </div>
      </div>

      <div class="input-wrapper">
        <custom-input
          *ngIf="bookingDetails.work_related === true"
          name="company_name"
          [(ngModel)]="bookingDetails.company_name"
          [type]="'text'"
          [label]="langService.dictionary['company-name'][langService.lang]"
          [maxlength]="32"
        />
      </div>

      <div class="input-wrapper">
        <custom-input
          *ngIf="bookingDetails.work_related === true"
          name="tax_number"
          [(ngModel)]="bookingDetails.tax_number"
          [type]="'text'"
          [label]="
            langService.dictionary['company-tax-number'][langService.lang]
          "
          [maxlength]="12"
        />
      </div>
      <div class="input-wrapper">
        <custom-select
          name="country"
          [(ngModel)]="bookingDetails.tax_zone"
          [options]="taxZoneOptions"
          [label]="langService.dictionary['tax-zone'][langService.lang]"
          *ngIf="bookingDetails.work_related"
        ></custom-select>
      </div>
    </form>
  </div>
  <div class="button-wrapper clickable">
    <button class="secondary-button" type="button" (click)="closeBooking()">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>
    <button
      class="primary-button"
      type="submit"
      [disabled]="bookingForm.invalid"
      (click)="nextStep($event)"
    >
      <span>{{
        langService.dictionary["choose-rooms"][langService.lang]
      }}</span>
    </button>
  </div>
</div>
