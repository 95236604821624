<div class="charge-wrapper" [ngClass]="{ success: charge.paid }">
  <form [formGroup]="addChargeForm" class="charge-details-form">
    <div class="header-wrapper">
      <div class="charge-type">
        <i class="double-bed-icon" *ngIf="charge.charge_type === 'ROOM'"></i>
        <i class="tax-icon" *ngIf="charge.charge_type === 'IFA'"></i>
        <i
          *ngIf="charge.charge_type === 'PREPAYMENT'"
          class="prepayment-icon"
        ></i>
        <i *ngIf="charge.charge_type === 'FOOD'" class="food-icon"></i>
        <i *ngIf="charge.charge_type === 'DRINK'" class="drink-icon"></i>
        <i *ngIf="charge.charge_type === 'WELLNESS'" class="wellness-icon"></i>
        <i
          *ngIf="charge.charge_type === 'OTHER'"
          class="other-service-icon"
        ></i>
      </div>
      <!-- TODO -->
      <div class="name-wrapper">
        <input
          formControlName="name"
          type="text"
          [disabled]="
            charge.charge_type === chargeTypes.ifa ||
            charge.charge_type === chargeTypes.room ||
            charge.paid
          "
        />
      </div>
    </div>
    <div
      class="delete-wrapper"
      *ngIf="
        charge.charge_id &&
        !charge.paid &&
        charge.charge_type !== chargeTypes.ifa &&
        charge.charge_type !== chargeTypes.room
      "
    >
      <button type="button" (click)="deleteCharge(charge)">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>

    <div class="cost-wrapper">
      <custom-input
        formControlName="qty"
        class="qty"
        [type]="'number'"
        [label]="langService.dictionary['qty'][langService.lang] + '*'"
        [disabled]="
          charge.paid || getPayChargeControl('payment_method').value !== null
        "
      />
      <custom-input
        formControlName="unit"
        class="unit"
        [type]="'string'"
        [label]="langService.dictionary['unit'][langService.lang] + '*'"
        [disabled]="
          charge.paid || getPayChargeControl('payment_method').value !== null
        "
      />
      <custom-select
        formControlName="vat_rate"
        class="vat-rate"
        [options]="vatRateOptions"
        [label]="langService.dictionary['vat'][langService.lang] + '*'"
        [disabled]="
          charge.paid || getPayChargeControl('payment_method').value !== null
        "
      />
      <custom-input
        formControlName="gross_price"
        class="price"
        [type]="'number'"
        [label]="langService.dictionary['gross-price'][langService.lang] + '*'"
        [disabled]="
          charge.paid || getPayChargeControl('payment_method').value !== null
        "
      />
    </div>
    <div class="total-wrapper">
      <custom-input
        class="total-price"
        [type]="'number'"
        [label]="langService.dictionary['total'][langService.lang]"
        [disabled]="true"
        [_value]="
          getAddChargeControl('gross_price').value *
          getAddChargeControl('qty').value
        "
      />
    </div>
    <div class="comment-wrapper">
      <div
        class="form-textbox comment"
        [ngClass]="{
          focused: true
        }"
      >
        <textarea
          formControlName="comment"
          type="textarea"
          [disabled]="
            charge.paid || getPayChargeControl('payment_method').value !== null
          "
        ></textarea>
        <span class="form-textbox-label">{{
          langService.dictionary["comment"][langService.lang]
        }}</span>
      </div>
    </div>
  </form>
  <form [formGroup]="payChargeForm" class="charge-payment-form">
    <div class="payment-method-wrapper">
      <custom-select
        formControlName="payment_method"
        *ngIf="charge.charge_id"
        class="payment-method"
        [disabled]="
          charge.paid ||
          (isModified() && !getPayChargeControl('payment_method').value)
        "
        [options]="paymentOptions"
        [label]="
          langService.dictionary['payment-method'][langService.lang] + '*'
        "
      >
      </custom-select>
    </div>

    <div class="payment-date-wrapper">
      <date-picker
        formControlName="payment_date"
        *ngIf="charge.charge_id"
        [disabled]="
          charge.paid ||
          (isModified() && !getPayChargeControl('payment_method').value)
        "
        [isDateString]="true"
        [label]="langService.dictionary['payment-date'][langService.lang] + '*'"
      ></date-picker>
    </div>
  </form>

  <div class="modification-button-wrapper">
    <button
      class="secondary-button"
      *ngIf="!charge.charge_id"
      [disabled]="addChargeForm.invalid"
      type="button"
      (click)="postCharge(addChargeForm.getRawValue())"
    >
      {{ langService.dictionary["add"][langService.lang] }}
    </button>
    <div
      class="button-wrapper"
      *ngIf="
        !getPayChargeControl('payment_method').value &&
        isModified() &&
        charge.charge_id
      "
    >
      <button
        class="secondary-button cancel"
        (click)="resetChargeForm()"
        type="button"
      >
        {{ langService.dictionary["cancel"][langService.lang] }}
      </button>
      <button
        class="secondary-button modify"
        (click)="editCharge()"
        type="button"
      >
        {{ langService.dictionary["modify"][langService.lang] }}
      </button>
    </div>
  </div>
  <div class="payment-button-wrapper" *ngIf="charge.charge_id && !isModified()">
    <button
      class="primary-button"
      *ngIf="
        charge.charge_id &&
        !charge.paid &&
        (!isModified() || getPayChargeControl('payment_method').value)
      "
      type="button"
      [disabled]="payChargeForm.invalid"
      (click)="payCharge()"
    >
      {{ langService.dictionary["pay"][langService.lang] }}
    </button>
    <button
      class="primary-button success"
      *ngIf="charge.charge_id && charge.paid"
      [disabled]="true"
    >
      {{ langService.dictionary["paid"][langService.lang] }}
    </button>
  </div>
</div>
