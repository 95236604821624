import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { paymentOptions, vatRateOptions } from 'src/app/@consts';
import {
  ChargeForm,
  ChargeFormValue,
  ChargeInterface,
} from 'src/app/@Interfaces';
import { ChargeService, LanguageService, RoomService } from 'src/app/@services';
import { ChargeTypes } from 'src/app/enums';

@Component({
  selector: 'charge-card',
  templateUrl: './charge-card.component.html',
  styleUrls: ['./charge-card.component.scss'],
  standalone: false,
})
export class ChargeCardComponent implements OnInit, OnDestroy {
  constructor(
    private chargeService: ChargeService,
    private roomService: RoomService,
    public langService: LanguageService
  ) {}

  @Input('charge') charge: ChargeInterface;
  @Input() set groupPayment(groupPayment: {
    payment_method: string;
    payment_date: string;
  }) {
    this.payChargeForm.patchValue({
      payment_date: groupPayment.payment_date,
    });

    this.payChargeForm.patchValue({
      payment_method: groupPayment.payment_method,
    });
  }
  @Output('refreshData') refreshData: EventEmitter<void> =
    new EventEmitter<void>();

  public addChargeForm: FormGroup<ChargeForm> = new FormGroup({
    name: new FormControl(null, [
      Validators.required,
      Validators.maxLength(32),
    ]),
    qty: new FormControl(null, Validators.required),
    unit: new FormControl(null, [
      Validators.required,
      Validators.maxLength(32),
    ]),
    vat_rate: new FormControl(null, Validators.required),
    gross_price: new FormControl(null, Validators.required),
    comment: new FormControl(null, Validators.maxLength(128)),
    room_id: new FormControl(null),
    charge_type: new FormControl(null, Validators.required),
  });

  public payChargeForm: FormGroup = new FormGroup({
    charge_id: new FormControl(null),
    payment_method: new FormControl(null),
    payment_date: new FormControl(null),
  });

  private originalCharge: ChargeFormValue;
  public readonly paymentOptions: any = paymentOptions;
  public readonly vatRateOptions: any = vatRateOptions;
  public chargeTypes = ChargeTypes;

  ngOnInit(): void {
    this.addChargeForm.reset(this.charge);
    if (this.charge.paid) this.payChargeForm.reset(this.charge);

    this.originalCharge = structuredClone(this.addChargeForm.getRawValue());

    if (this.isNewCharge(this.charge.charge_id)) {
      this.payChargeForm.get('payment_date').setValidators(Validators.required);
      this.payChargeForm
        .get('payment_method')
        .setValidators(Validators.required);
    } else {
      this.payChargeForm.patchValue({ charge_id: this.charge.charge_id });
    }
  }

  getAddChargeControl(controlName: string): FormControl {
    return this.addChargeForm.get(controlName) as FormControl;
  }

  getPayChargeControl(controlName: string): FormControl {
    return this.payChargeForm.get(controlName) as FormControl;
  }

  public postCharge(charge: ChargeFormValue): void {
    this.addChargeForm.value;
    charge.charge_type = this.charge.charge_type;
    charge.room_id = this.roomService.selectedRoom.room_id;
    charge.vat_rate = Number(charge.vat_rate);
    this.chargeService
      .postCharge(charge)
      .then(() => {
        this.refreshData.emit();
      })
      .catch(() => {});
  }

  public payCharge(): void {
    const payingCharge = this.payChargeForm.getRawValue();

    const requestBody = {
      charges: [
        {
          charge_id: payingCharge.charge_id,
          payment_method: payingCharge.payment_method,
          payment_date: payingCharge.payment_date,
        },
      ],
    };
    this.chargeService
      .payCharge(requestBody)
      .then(() => {
        this.refreshData.emit();
      })
      .catch(() => {});
  }

  public editCharge(): void {
    const charge = this.addChargeForm.getRawValue();
    delete charge.room_id;
    charge.vat_rate = Number(charge.vat_rate);
    this.chargeService
      .editCharge(charge, this.charge.charge_id)
      .then(() => {
        this.refreshData.emit();
      })
      .catch(() => {});
  }

  public deleteCharge(charge: ChargeInterface): void {
    this.chargeService
      .deleteCharge(charge.charge_id)
      .then(() => {
        this.refreshData.emit();
      })
      .catch(() => {});
  }

  public resetChargeForm(): void {
    this.addChargeForm.reset(this.originalCharge);
  }

  public isModified(): boolean {
    return (
      JSON.stringify(this.addChargeForm.getRawValue()) !==
      JSON.stringify(this.originalCharge)
    );
  }

  public isNewCharge(chargeId: number): boolean {
    return isNaN(chargeId);
  }

  ngOnDestroy(): void {}
}
