import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgModel,
} from '@angular/forms';
import { FocusService, LanguageService } from 'src/app/@services';

@Component({
    selector: 'custom-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class SelectComponent implements ControlValueAccessor {
  constructor(
    public focusService: FocusService,
    public langService: LanguageService
  ) {}
  @Input() _value: any;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() options: {
    label: any;
    value?: any;
    selected?: boolean;
    disabled?: boolean;
  }[];
  @Input() highlighted: boolean;
  @Input() transparent: boolean;
  @Output() clicked = new EventEmitter<void>();
  @Output() change = new EventEmitter<string>();
  @ViewChild('customInput', { static: false }) customInput: NgModel;

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    this.value = value;
    if (value === null && this.options) {
      this.value = this.options[0]?.value;
      this?.customInput?.control?.markAsUntouched();
    }
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onInput(event: any): void {
    this.value = event.target.value;
    this.onChange(this.value);
  }

  public sendChange(): void {
    this.onChange(this._value);
    this.change.emit(this._value);
    this.onTouched();
  }

  public emitClick(): void {
    this.clicked.emit();
  }
}
