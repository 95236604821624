import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'checkInStatus',
    standalone: false
})
export class CheckInStatusPipe implements PipeTransform {
  transform(value: string, lang: string): string {
    if (!value) return '';
    const options: any = {
      NA: { hu: 'Nincs kiküldve', en: 'Email not sent yet' },
      PENDING: { hu: 'Email kiküldve', en: 'Email sent' },
      CONFIRMED: { hu: 'Vendég kitöltötte', en: 'Guest checked in' },
    };

    return options[value][lang] || value;
  }
}
