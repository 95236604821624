import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/@dialogs/confirmation/confirmation.dialog';
import { ExtendedBookingRoom } from 'src/app/@Interfaces';
import { HotelService, LanguageService } from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { DateService } from 'src/app/@services/date.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { SidePanelTypeEnum } from 'src/app/enums';

@Component({
  selector: 'booking-room-card',
  templateUrl: './booking-room-card.component.html',
  styleUrls: ['./booking-room-card.component.scss'],
  standalone: false,
})
export class RoomPanelComponent implements OnInit, OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public hotelService: HotelService,
    private matDialog: MatDialog,
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public dateService: DateService,
    public focusService: FocusService,
    public langService: LanguageService,
    public roomService: RoomService,
    public tableService: TableService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  @Output('removeRoom') removeRoomEmitter: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() changeRoom: EventEmitter<number> = new EventEmitter<number>();
  @Input() room: ExtendedBookingRoom;
  @Input() isEditing: boolean;
  @Input() fG!: FormGroup;
  public selectedLanguage: string;
  public bookingChanel: string;
  public _sidePanelType: SidePanelTypeEnum;
  public _isEditing: boolean;
  public isLoading: boolean;
  public roomSelectOptions: any[] = [
    {
      value: null,
      label: {
        hu: 'Válasszon egy szobát',
        en: 'Choose a room',
      },
      selected: true,
      disabled: true,
    },
  ];
  public ifa: number;
  public flat_ifa: number;
  public originalValues: any;
  public isPrepaymentEnabled: boolean;
  protected totalPrice: number;
  private pricePerNight: number;

  ngOnInit(): void {
    this.setSelectableRoomIds();
    this.ifa = this.hotelService.selectedHotel.IFA;
    this.flat_ifa = this.hotelService.selectedHotel.flat_IFA;
    this.isPrepaymentEnabled =
      this.hotelService.selectedHotel.prepayment_enabled;

    if (!this.isEditing) this.roomSelectOptions[0].value = 0;
    this.totalPrice = !this.room.plus_tax
      ? this.room.gross_price
      : this.room.gross_price + this.room.adults * this.ifa;
    this.pricePerNight = Math.round(
      this.totalPrice /
        this.calendarService.calculateDaysDifference(
          this.room.arrival_date,
          this.room.departure_date
        )
    );
    this.fG.reset(this.room);
    this.setPrepayment();

    if (this.room.arrived) {
      this.getControl('adults').disable();
      this.getControl('children').disable();
    }

    if (this.isEditing) {
      this.originalValues = structuredClone(this.fG.getRawValue());
    }
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  getControl(controlName: string): FormControl {
    return this.fG.get(controlName) as FormControl;
  }

  public selectRoom(newRoomId: string): void {
    this.tableService.removeRoomById(this.room.booking_id, this.room.room_id);
    this.changeRoom.emit(Number(newRoomId));
  }

  public saveNewRoom(): void {
    this.isLoading = true;

    this.bookingService
      .addBookingRoom(
        this.bookingService.selectedBooking.booking_id,
        this.fG.getRawValue()
      )
      .then(async () => {
        this.isLoading = false;
        this.tableService.makeNewRoomsPermanent(
          this.bookingService.selectedBooking?.booking_id
        );
        delete this.room.old_room_id;
        const hotelId = this.hotelService.selectedHotel.hotel_id.toString();
        const bookingId = this.room.booking_id.toString();
        const roomId = this.room.room_id.toString();
        await this.bookingService.getBooking(hotelId, bookingId, roomId);
        this.bookingService.setSelectedBookingsRooms();
        this.originalValues = structuredClone(this.fG.getRawValue());
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public editRoom(): void {
    this.isLoading = true;

    this.bookingService
      .putBookingRoom(
        this.bookingService.selectedBooking.booking_id,
        this.fG.getRawValue()
      )
      .then(async () => {
        this.isLoading = false;
        this.originalValues = structuredClone(this.fG.getRawValue());
        await this.bookingService.getBookings();

        this.bookingService.setSelectedBookingsRooms();
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  protected updateGuestsNumber(): void {
    this.tableService.updateGuestsNumber(
      this.room.booking_id,
      this.room.room_id,
      this.getControl('adults').value,
      this.getControl('children').value
    );
  }

  public removeRoom(roomId: number): void {
    this.tableService.removeRoomById(this.room.booking_id, roomId);
    this.removeRoomEmitter.emit(roomId);
  }

  public deleteRoom(): void {
    if (this.room.newRoom) {
      this.removeRoom(this.room.room_id);
    } else {
      this.matDialog
        .open(ConfirmationDialog)
        .afterClosed()
        .subscribe((choice: boolean) => {
          if (!choice) return;

          this.bookingService
            .deleteBookingRooms(
              this.bookingService.selectedBooking.booking_id,
              this.room.old_room_id ?? this.room.room_id
            )
            .then(() => {
              this.removeRoom(this.room.room_id);
              this.bookingService.getBookings().then(() => {
                this.bookingService.setSelectedBookingsRooms();
              });
            })
            .catch(() => {});
        });
    }
  }

  public setSelectableRoomIds(): void {
    const roomIds = this.roomService.rooms.map((room) =>
      room.room_id.toString()
    );

    roomIds.forEach((roomID) => {
      this.roomSelectOptions.push({
        value: Number(roomID),
        label: {
          hu: this.roomService.getRoomName(roomID),
          en: this.roomService.getRoomName(roomID),
        },
      });
    });
  }

  public handleDateChange(arrChanged: boolean): void {
    let arrivalDate = this.fG.get('arrival_date').value;

    let departureDate = this.fG.get('departure_date').value;

    const finalDates = this.enforceDateOrder(
      arrivalDate,
      departureDate,
      arrChanged
    );

    arrivalDate = finalDates.arrDate;
    departureDate = finalDates.depDate;
    this.fG.patchValue({ arrival_date: arrivalDate });
    this.fG.patchValue({ departure_date: departureDate });
    this.tableService.handleDatePicker(
      arrivalDate,
      departureDate,
      this.room.room_id
    );
    this.updateGrossPrice();
    this.setTotalPrice();
  }

  public setRoomIdForCalendar(roomId: number): void {
    this.tableService.roomIdForCalendar = roomId;
  }

  public updateGrossPrice(): void {
    this.fG.patchValue({
      gross_price:
        this.pricePerNight *
        this.calendarService.calculateDaysDifference(
          this.getControl('arrival_date').value,
          this.getControl('departure_date').value
        ),
    });
  }

  public addNight(): void {
    const date = new Date(this.getControl('departure_date').value);
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 1);
    const stringDate = this.dateService.formatDate(newDate);
    this.room.departure_date = stringDate;
    this.fG.patchValue({
      departure_date: stringDate,
    });
    this.tableService.updateDepartureDate(
      stringDate,
      this.room.booking_id,
      this.room.room_id
    );

    this.updateGrossPrice();
    this.setTotalPrice();
  }

  public subtractNight(): void {
    if (
      this.calendarService.calculateDaysDifference(
        this.getControl('arrival_date').value,
        this.getControl('departure_date').value
      ) === 1
    ) {
      return;
    }
    const date = new Date(this.getControl('departure_date').value);
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - 1);
    const stringDate = this.dateService.formatDate(newDate);
    this.room.departure_date = stringDate;
    this.fG.patchValue({
      departure_date: stringDate,
    });
    this.tableService.updateDepartureDate(
      stringDate,
      this.room.booking_id,
      this.room.room_id
    );

    this.updateGrossPrice();
    this.setTotalPrice();
  }

  public changeIfaIncluded(val: boolean): void {
    this.fG.patchValue({
      gross_price: !val
        ? this.totalPrice
        : this.totalPrice -
          (this.flat_ifa
            ? this.ifa *
              this.calendarService.calculateDaysDifference(
                this.fG.get('arrival_date').value,
                this.fG.get('departure_date').value
              ) *
              this.fG.get('adults').value
            : this.totalPrice * this.ifa * 100),
    });
  }

  public isBookingModified(): boolean {
    return (
      JSON.stringify(this.fG.getRawValue()) !==
        JSON.stringify(this.originalValues) || this.room.newRoom
    );
  }

  public cancelModification(): void {
    if (this.room.old_room_id) this.changeRoom.emit(this.room.old_room_id);
    this.room = this.originalValues;
    this.fG.reset(this.room);
    this.tableService.restoreBooking(
      this.bookingService.selectedBooking.booking_id,
      this.room.room_id
    );
  }

  protected setTotalPrice(): void {
    const grossPrice = this.getControl('gross_price').value;
    const plusTax = this.getControl('plus_tax').value;

    if (!grossPrice || plusTax === undefined) return;

    this.totalPrice = !plusTax
      ? grossPrice
      : grossPrice +
        (this.flat_ifa
          ? this.ifa *
            this.calendarService.calculateDaysDifference(
              this.fG.get('arrival_date').value,
              this.fG.get('departure_date').value
            ) *
            this.fG.get('adults').value
          : grossPrice * this.ifa * 100);
  }

  private setPrepayment(): void {
    let prepayment = this.room.prepayment;

    if (prepayment) {
      this.fG.patchValue({ prepayment: prepayment });
      return;
    }

    if (!this.hotelService.selectedHotel.prepayment_enabled) {
      this.fG.patchValue({ prepayment: 0 });
      return;
    }
    let room = this.roomService.rooms.find(
      (room) => room.room_id === this.room.room_id
    );

    if (!room) return;

    this.fG.patchValue({
      prepayment: this.hotelService.selectedHotel.prepayment_flat
        ? this.hotelService.selectedHotel.prepayment_flat
        : room.default_price_huf *
          (this.hotelService.selectedHotel.prepayment_percent / 100),
    });
  }

  private enforceDateOrder(
    arrDate: string,
    depDate: string,
    arrChanged: boolean
  ): { arrDate: string; depDate: string } {
    if (!arrDate) arrDate = this.dateService.addDayToStringDate(depDate, -1);
    if (!depDate) depDate = this.dateService.addDayToStringDate(arrDate, 1);

    if (this.dateService.isDateSooner(arrDate, depDate))
      return { arrDate, depDate };

    if (arrChanged) depDate = this.dateService.addDayToStringDate(arrDate, 1);
    if (!arrChanged) arrDate = this.dateService.addDayToStringDate(depDate, -1);

    return { arrDate, depDate };
  }

  ngOnDestroy(): void {
    if (this.room.newRoom || !this.isBookingModified()) return;

    this.tableService.restoreBooking(this.room.booking_id, this.room.room_id);
  }
}
