<div class="room-edit-wrapper">
  <div class="header">
    <h1>{{ langService.dictionary["modify"][langService.lang] }}</h1>
    <!-- <p>Itt módosíthatja a szobák adatait</p> -->
  </div>
  <div class="hint" *ngIf="!roomService.selectedRoom">
    <span>{{ langService.dictionary["select-room"][langService.lang] }}</span>
  </div>
  <div class="form-wrapper" [hidden]="!roomService.selectedRoom">
    <form [formGroup]="form">
      <ng-container *ngIf="roomService.selectedRoom">
        <custom-input
          formControlName="name"
          [type]="'text'"
          [label]="langService.dictionary['room-name'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'number'"
          formControlName="room_number"
          [label]="
            langService.dictionary['room-number'][langService.lang] + '*'
          "
        />
        <custom-select
          formControlName="type"
          [options]="roomTypeOptions"
          [label]="langService.dictionary['room-type'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'text'"
          formControlName="building"
          [label]="
            langService.dictionary['room-building'][langService.lang] +
            '* ' +
            langService.dictionary['room-building-example'][langService.lang]
          "
          [maxlength]="32"
        />
        <custom-select
          formControlName="floor"
          [options]="floorOptions"
          [label]="langService.dictionary['floor'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'number'"
          formControlName="single_beds"
          [label]="
            langService.dictionary['single-beds'][langService.lang] + '*'
          "
        />
        <custom-input
          [type]="'number'"
          formControlName="double_beds"
          [label]="
            langService.dictionary['double-beds'][langService.lang] + '*'
          "
        />
        <custom-input
          [type]="'number'"
          formControlName="extra_beds"
          [label]="langService.dictionary['extra-beds'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'number'"
          formControlName="couch_beds"
          [label]="langService.dictionary['couch-beds'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'number'"
          formControlName="max_adults"
          [label]="langService.dictionary['max-adults'][langService.lang] + '*'"
        />
        <custom-input
          [type]="'number'"
          formControlName="max_family_adults"
          [label]="
            langService.dictionary['max-family-adults'][langService.lang] + '*'
          "
        />
        <custom-input
          [type]="'number'"
          formControlName="max_family_children"
          [label]="
            langService.dictionary['max-family-children'][langService.lang] +
            '*'
          "
        />
        <div class="row-wrapper">
          <custom-input
            [type]="'number'"
            formControlName="default_price_huf"
            [label]="
              langService.dictionary['room-price'][langService.lang] + ' HUF*'
            "
          />
          <custom-checkbox
            formControlName="plus_tax"
            [label]="langService.dictionary['plus-tax'][langService.lang]"
          />
        </div>
        <custom-checkbox
          formControlName="air_conditioning"
          [label]="langService.dictionary['air-conditioning'][langService.lang]"
        />
        <custom-checkbox
          formControlName="wheelchair_accessible"
          [label]="
            langService.dictionary['wheelchair-accessible'][langService.lang]
          "
        />
        <custom-input
          formControlName="airbnb_calendar"
          [type]="'text'"
          [label]="langService.dictionary['airbnb-calendar'][langService.lang]"
          *ngIf="hotelService.selectedHotel?.calendar_sync"
        />
        <custom-input
          formControlName="booking_calendar"
          [type]="'text'"
          [label]="langService.dictionary['booking-calendar'][langService.lang]"
          *ngIf="hotelService.selectedHotel?.calendar_sync"
        />
        <custom-input
          formControlName="szallas_calendar"
          [type]="'text'"
          [label]="langService.dictionary['szallas-calendar'][langService.lang]"
          *ngIf="hotelService.selectedHotel?.calendar_sync"
        />
      </ng-container>
    </form>
  </div>

  <div class="button-wrapper" *ngIf="roomService.selectedRoom">
    <button class="secondary-button" type="button" (click)="deselectRoom()">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>
    <button
      class="primary-button"
      type="submit"
      [disabled]="form?.invalid"
      (click)="editRoom()"
    >
      {{ langService.dictionary["modify"][langService.lang] }}
    </button>
  </div>
</div>
