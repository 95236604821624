<div class="booking-rooms-depart-wrapper" (click)="keepWindowOpen($event)">
  <div class="selector-header clickable">
    <button class="secondary-button" (click)="selectAll()">
      {{ langService.dictionary["select-all"][langService.lang] }}
    </button>
  </div>
  <div class="selector-wrapper clickable">
    <div class="room-panel-wrapper">
      <div
        *ngFor="let room of rooms"
        class="room-panel"
        [ngClass]="{
          success: room.departed,
          selected: roomsToDepart.includes(room.room_id),
          disabled: (!room.paid || !room.arrived) && !room.departed,
          selectable: room.paid && !room.departed && room.arrived
        }"
        (click)="!room.departed && room.paid ? selectToDepart(room) : null"
      >
        <div class="room-name">
          <span>{{ roomService.getRoomName(room.room_id) }}</span>
        </div>

        <div class="status">
          <span>{{ room | bookingStatus : langService.lang }}</span>
        </div>

        <div class="tooltip">
          <span *ngIf="!room.arrived && !room.departed">{{
            langService.dictionary["not-checked-in"][langService.lang]
          }}</span>
          <span *ngIf="room.arrived && !room.paid && !room.departed">{{
            langService.dictionary["not-paid"][langService.lang]
          }}</span>
        </div>

        <div *ngIf="!room.departed" class="date-wrapper">
          <div class="date">
            <span>{{ room.arrival_date }}</span>
          </div>
          <span class="num-of-nights"
            >{{
              calendarService.calculateDaysDifference(
                room.arrival_date,
                room.departure_date
              )
            }}
            {{ langService.dictionary["night"][langService.lang] }}</span
          >

          <div class="date">
            <span>{{ room.departure_date }}</span>
          </div>
        </div>
        <span *ngIf="room.arrived" class="success-message">{{
          langService.dictionary["checked-out"][langService.lang]
        }}</span>
        <div class="details">
          <div class="occupancy-wrapper">
            <div class="occupancy-block">
              <div class="occupancy">
                <span>
                  {{ room.adults }}
                </span>
              </div>
              <div>
                <i class="adult-icon"></i>
              </div>
            </div>
            <div class="occupancy-block">
              <div class="occupancy">
                <span>
                  {{ room.children }}
                </span>
              </div>
              <div>
                <i class="child-icon"></i>
              </div>
            </div>
          </div>
          <div *ngIf="!room.departed" class="amount-block">
            <input
              disabled
              class="amount"
              type="number"
              [(ngModel)]="room.gross_price"
            />
            <input type="text" class="huf-label" value="HUF" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="button-wrapper clickable">
    <button
      class="primary-button"
      (click)="departSelectedRooms()"
      [disabled]="!roomsToDepart.length"
    >
      <span *ngIf="!isLoading">{{
        langService.dictionary["check-out"][langService.lang]
      }}</span>
      <app-loading-spinner
        [loading]="isLoading"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </button>
  </div>
</div>
