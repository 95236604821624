<div class="rooms-wrapper">
  <div
    class="main-content"
    [ngClass]="
      routerService.tertiaryMenu === roomMenu.Overview ? 'overview-mode' : ''
    "
  >
    <div class="selection-wrapper">
      <div class="header">
        <rooms-overview
          [selectedMenu]="routerService.tertiaryMenu"
        ></rooms-overview>
      </div>

      <!-- <p>Limit {{ roomLimit }}</p>
      <p *ngIf="roomService.rooms">
        Hozzáadható szobák {{ roomLimit - roomService.rooms.length }}
      </p> -->
    </div>
    <div class="action-wrapper">
      <div class="action-panel">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="button-wrapper">
      <button
        class="secondary-button"
        (click)="selectMenu(roomMenu.Overview)"
        [ngClass]="
          routerService.tertiaryMenu === roomMenu.Overview ? 'active' : ''
        "
      >
        {{ langService.dictionary["overview"][langService.lang] }}
      </button>
      <button
        class="secondary-button"
        (click)="selectMenu(roomMenu.Add)"
        [ngClass]="routerService.tertiaryMenu === roomMenu.Add ? 'active' : ''"
        *ngIf="
          hotelService.selectedHotel?.room_limit - roomService.rooms.length > 0
        "
      >
        {{ langService.dictionary["add"][langService.lang] }}
      </button>
      <button
        class="secondary-button"
        (click)="selectMenu(roomMenu.Edit)"
        [ngClass]="routerService.tertiaryMenu === roomMenu.Edit ? 'active' : ''"
      >
        {{ langService.dictionary["modify"][langService.lang] }}
      </button>
      <button
        class="secondary-button"
        (click)="selectMenu(roomMenu.OutOfOrder)"
        [ngClass]="
          routerService.tertiaryMenu === roomMenu.OutOfOrder ? 'active' : ''
        "
      >
        {{ langService.dictionary["ooo"][langService.lang] }}
      </button>
      <button
        class="secondary-button"
        (click)="selectMenu(roomMenu.OutOfService)"
        [ngClass]="
          routerService.tertiaryMenu === roomMenu.OutOfService ? 'active' : ''
        "
      >
        {{ langService.dictionary["oos"][langService.lang] }}
      </button>
    </div>
  </div>
</div>
