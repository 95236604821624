import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  CheckInService,
  GuestService,
  LanguageService,
  RouterService,
} from 'src/app/@services';

@Component({
    selector: 'check-in-code',
    templateUrl: './check-in-code.dialog.html',
    styleUrls: ['./check-in-code.dialog.scss'],
    standalone: false
})
export class CheckInCodeDialog {
  constructor(
    private routerService: RouterService,
    private matDialogRef: MatDialogRef<CheckInCodeDialog>,
    private checkInService: CheckInService,
    public langService: LanguageService,
    private guestService: GuestService
  ) {}

  protected code: string;
  protected error: boolean;

  protected checkCode(): void {
    this.matDialogRef.close();
    this.checkInService
      .getCheckInDetails(this.code)
      .then(() => {
        this.checkInService.checkInId = this.code;
        this.routerService.absoluteNavigation('self-check-in');
        this.guestService.isSelfCheckIn = true;
      })
      .catch(() => {
        this.error = true;
        console.log('no check in found');
      });
  }
}
