import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CheckInDetail,
  GuestInterface,
  IResponse,
  ScanInterface,
} from '../@Interfaces';
import { ConfigService } from './config.service';
import { HotelService } from './hotel.service';

@Injectable({
  providedIn: 'root',
})
export class CheckInService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private hotelService: HotelService
  ) {}

  public checkInId: string;
  public checkInDetail: CheckInDetail;

  public async getCheckInDetails(id: string): Promise<CheckInDetail> {
    return new Promise<CheckInDetail>((resolve, reject) => {
      this.http
        .get<IResponse<CheckInDetail>>(
          `${this.configService.server}api/online-check-in/${encodeURIComponent(
            id
          )}`
        )
        .subscribe({
          next: (res: IResponse<CheckInDetail>) => {
            this.checkInDetail = res.data;
            resolve(res.data);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async sendCheckInScan(
    room_id: number,
    scan: ScanInterface
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/online-check-in/${encodeURIComponent(
            this.checkInId
          )}/room/${room_id}`,
          scan
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async saveCheckInGuest(
    id: string,
    guest: GuestInterface,
    roomId: number
  ): Promise<void> {
    const body = structuredClone(guest);
    delete body.guest_id;
    delete body.room_id;
    delete body.guest_arrival_id;
    return new Promise((resolve, reject) => {
      this.http
        .post<IResponse>(
          `${this.configService.server}api/online-check-in/${encodeURIComponent(
            id
          )}`,
          {
            room_id: roomId,
            guests: [body],
          }
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async sendOnlineCheckIn(
    details: any,
    bookingId: number
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${bookingId}/send-online-check-in`,
          details
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }
}
