import { Injectable } from '@angular/core';
import { BookingInterface } from '../@Interfaces';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  /* <-- HELPER FUNCTIONS --> */

  public isBookedBetween(
    roomId: number,
    arrival: string,
    departure: string,
    bookings: BookingInterface[],
    bookingId?: number
  ): boolean {
    for (const booking of bookings) {
      if (
        booking.room_id == roomId &&
        (!bookingId || booking.booking_id !== bookingId)
      ) {
        const arrivalDate = new Date(booking.arrival_date).getTime();
        const departureDate = new Date(booking.departure_date).getTime();
        const questionedArrivalDate = new Date(arrival).getTime();
        const questionedDepartureDate = new Date(departure).getTime();

        if (
          (questionedArrivalDate < arrivalDate &&
            arrivalDate < questionedDepartureDate) ||
          (questionedArrivalDate < departureDate &&
            arrivalDate < questionedDepartureDate)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  public formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  public addDayToStringDate(stringDate: string, daysToAdd: number): string {
    return this.formatDate(
      new Date(
        new Date(stringDate).setDate(new Date(stringDate).getDate() + daysToAdd)
      )
    );
  }

  public isDateSooner(
    soonerDateString: string,
    laterDateString: string
  ): boolean {
    const soonerDate: number = new Date(soonerDateString).getTime();
    const latterDate: number = new Date(laterDateString).getTime();
    return soonerDate < latterDate;
  }

  public templateNewDate(stringDate: string): Date {
    return new Date(stringDate);
  }

  public isDateSameAsToday(d: Date): boolean {
    const currentDate = new Date();
    return (
      currentDate.getFullYear() === d.getFullYear() &&
      currentDate.getMonth() === d.getMonth() &&
      currentDate.getDate() === d.getDate()
    );
  }

  public isDateFuture(d: Date): boolean {
    const givenDate = new Date(d);
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);
    givenDate.setHours(0, 0, 0, 0);

    const diffInMills = givenDate.getTime() - currentDate.getTime();

    return diffInMills > 0;
  }

  public isWeekend(d: Date): boolean {
    return d.getDay() === 0 || d.getDay() === 6;
  }

  public isOver18(date: string): boolean {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Adjust the age if the birthday has not occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age >= 18;
  }

  public daysBetween(date1: string, date2: string): number {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    // Calculate the difference in time (milliseconds)
    const diffInMs = Math.abs(secondDate.getTime() - firstDate.getTime());

    // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 ms)
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    return diffInDays;
  }

  /* <-- HELPER FUNCTIONS --> */
}
