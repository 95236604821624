import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  HolidayService,
  HotelService,
  LanguageService,
  RouterService,
} from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { DateService } from 'src/app/@services/date.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { SidePanelTypeEnum } from 'src/app/enums';

@Component({
  selector: 'calendar-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class CalendarTableComponent implements AfterViewInit, OnDestroy {
  constructor(
    public calendarService: CalendarService,
    public bookingService: BookingService,
    public roomService: RoomService,
    private tableService: TableService,
    public dateService: DateService,
    public holidayService: HolidayService,
    private routerService: RouterService,
    private hotelService: HotelService,
    public langService: LanguageService
  ) {
    this.holidayService.getHolidays();
    const compactMode = localStorage.getItem('compactMode');
    this.compactMode = JSON.parse(compactMode);
  }

  public isRoomSelect: boolean;
  @Input() set sidePanelType(sidePanelType: SidePanelTypeEnum) {
    this.isRoomSelect = sidePanelType === 'room';
  }
  @Output() openEditRooms = new EventEmitter<Event>();
  @ViewChildren('container', { read: ViewContainerRef })
  cells: QueryList<ViewContainerRef>;
  public today = new Date();

  public compactMode = false;
  private cellSub: Subscription;

  ngAfterViewInit(): void {
    this.cellSub = this.calendarService.dateListSubject.subscribe(() => {
      this.tableService.cells = this.cells;
    });
  }

  public toggleCompactMode(): void {
    this.compactMode = !this.compactMode;
    localStorage.setItem('compactMode', JSON.stringify(this.compactMode));
  }

  public leftClickEvent(
    roomId: number | string,
    date: string,
    event: Event
  ): void {
    if (!this.isRoomSelect) {
      return;
    }

    event.stopPropagation();
  }

  public rightClickEvent(): void {}

  public mouseUp(event: Event): void {
    event.stopPropagation();
  }

  public getFormatedDate(date: Date): string {
    return this.dateService.formatDate(date);
  }

  public isDayOOO(day: Date, roomId: number): boolean {
    return this.roomService.isDateInOOO(day, roomId);
  }

  public isDayOOS(day: Date, roomId: number): boolean {
    return this.roomService.isDateInOOS(day, roomId);
  }

  public isDayHotelOOO(day: Date): boolean {
    return this.hotelService.isDayHotelOOO(day);
  }

  ngOnDestroy(): void {
    this.cellSub.unsubscribe();
  }
}
