import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Subscription } from 'rxjs';
import {
  BookingView,
  ExtendedBookingRoom,
  RoomInterface,
} from 'src/app/@Interfaces';
import {
  HotelService,
  LanguageService,
  RouterService,
} from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { DateService } from 'src/app/@services/date.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { SidePanelTypeEnum } from 'src/app/enums';

@Component({
  selector: 'booking-edit-rooms',
  templateUrl: './booking-edit-rooms.component.html',
  styleUrls: ['./booking-edit-rooms.component.scss'],
  standalone: false,
})
export class BookingEditRoomsComponent implements OnInit, OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    public tableService: TableService,
    public dateService: DateService,
    private routerService: RouterService,
    private hotelService: HotelService,
    public langService: LanguageService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  public _isEditing: boolean;
  public _sidePanelType: SidePanelTypeEnum;
  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public roomsToDepart: number[] = [];
  public selectedLanguage: string;
  private bookingSub: Subscription;
  public roomForms: FormGroup<any> = this.fb.group({
    room: this.fb.array<any>([]),
  });

  public rooms: ExtendedBookingRoom[] = [];

  ngOnInit(): void {
    this.bookingSub = this.bookingService.selectedBooking$.subscribe(() => {
      if (!this.bookingService.sidePanelRooms.length) return;
      this.roomForm.clear();
      this.bookingService.sidePanelRooms.forEach((room) => {
        this.addRoomForm(room);
      });

      this.rooms = this.bookingService.sidePanelRooms;
    });
  }

  get roomForm(): FormArray {
    return this.roomForms.get('room') as FormArray;
  }

  private addRoomForm(room: ExtendedBookingRoom): void {
    const roomGroup = this.fb.group({
      room_id: [room.room_id],
      arrival_date: [room.arrival_date, [Validators.required]],
      departure_date: [room.departure_date],
      adults: [room.adults],
      children: [room.children],
      gross_price: [{ value: room.gross_price }],
      plus_tax: [room.plus_tax],
      prepayment: [room.prepayment ?? 0],
    });
    this.roomForm.push(roomGroup);
  }

  public changeRoom(newId: number, index: number): void {
    const roomDetails = this.roomService.rooms.find(
      (room: RoomInterface) => room.room_id === newId
    );

    const room: ExtendedBookingRoom = {
      adults: this.rooms[index].adults || roomDetails.max_adults || 0,
      arrival_date: this.dateService.formatDate(new Date()),
      arrived: false,
      children:
        this.rooms[index].children || roomDetails.max_family_children || 0,
      contact_name: this.rooms[index].contact_name,
      departed: false,
      departure_date: this.dateService.addDayToStringDate(
        this.dateService.formatDate(new Date()),
        1
      ),
      gross_price:
        this.rooms[index].gross_price || roomDetails.default_price_huf || 0,
      plus_tax: false,
      paid: false,
      room_id: newId,
      old_room_id: this.rooms[index].room_id,
      newRoom: this.rooms[index].newRoom,
      booking_id: this.rooms[index].booking_id,
    };

    if (this.rooms[index].old_room_id)
      this.tableService.removeRoomById(
        this.bookingService.selectedBooking.booking_id,
        this.rooms[index].old_room_id
      );

    this.rooms[index] = room;
    const bookingView: BookingView = {
      adults: room.adults,
      arrDate: room.arrival_date,
      bookingId: room.booking_id,
      children: room.children,
      depDate: room.departure_date,
      name: room.contact_name,
      roomId: newId,
      reversed: false,
      newRoom: true,
    };

    this.tableService.addRoom(bookingView);
  }

  public getRoomGroup(index: number): FormGroup {
    return this.roomForm.at(index) as FormGroup;
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public closeBooking(): void {
    this.routerService.absoluteNavigationNoParamPreserve('calendar', {
      hotel: this.hotelService.selectedHotelId,
    });
    //timeout for animation to finish
    setTimeout(() => {
      this.bookingChanel = null;

      this.calendarService.removeAvailableDayClasses();
      this.selectedLanguage = null;
    }, 1000);
  }

  public editBookings(): void {
    this.isLoading = true;
    this.bookingService
      .putBookingRoom(
        this.bookingService.selectedBooking.booking_id,
        this.bookingService.sidePanelRooms
      )
      .then(async () => {
        this.isLoading = false;
        await this.bookingService.getBookings();
        this.bookingService.setSelectedBookingsRooms();
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public addRoom(): void {
    const room: ExtendedBookingRoom = {
      newRoom: true,
      adults: 0,
      arrival_date: this.dateService.formatDate(new Date()),
      arrived: false,
      children: 0,
      contact_name: this.bookingService.selectedBooking?.contact_name ?? null,
      departed: false,
      departure_date: this.dateService.addDayToStringDate(
        this.dateService.formatDate(new Date()),
        1
      ),
      gross_price: 0,
      plus_tax: false,
      paid: false,
      room_id: 0,
      booking_id: this.bookingService?.selectedBooking?.booking_id,
    };
    this.addEmptyRoomForm();
    this.rooms.push(room);

    //cdr needed for angular ExpressionChangedAfterItHasBeenCheckedError
    this.cdr.detectChanges();
  }

  protected removeRoom(roomId: any): void {
    this.rooms = this.rooms.filter((room) => room.room_id !== roomId);
  }

  private addEmptyRoomForm(): void {
    const roomGroup = this.fb.group({
      room_id: [''],
      arrival_date: [null, [Validators.required]],
      departure_date: [''],
      adults: [''],
      children: [''],
      gross_price: [{ value: '', disabled: true }],
      plus_tax: [''],
      prepayment: [0],
    });
    this.roomForm.push(roomGroup);
  }

  public getSelectableRoomIds(roomId: string): string[] {
    const roomIds = this.roomService.rooms.map((room) =>
      room.room_id.toString()
    );
    const bookedRoomIds = Object.keys(this.bookingService.sidePanelRooms);
    const roomIdsToLoop = roomIds.filter(
      (roomId) => !bookedRoomIds.includes(roomId.toString())
    );
    if (roomId.split('_')[0] !== 'newRoom') {
      roomIdsToLoop.push(roomId);
    }
    return roomIdsToLoop;
  }

  ngOnDestroy(): void {
    this.bookingSub.unsubscribe();
  }
}
