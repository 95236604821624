<div class="booking-details-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-name clickable">
    <h1>{{ langService.dictionary["booking-details"][langService.lang] }}</h1>
  </div>
  <div class="form-wrapper clickable">
    <form [formGroup]="form">
      <div class="input-wrapper">
        <custom-input
          [_value]="booking?.booking_number"
          [label]="langService.dictionary['booking-number'][langService.lang]"
          [disabled]="true"
        />
      </div>
      <div class="input-wrapper">
        <custom-input
          [_value]="booking?.uuid"
          [label]="langService.dictionary['check-in-id'][langService.lang]"
          [disabled]="true"
        />
      </div>
      <div class="input-wrapper alt">
        <custom-input
          [_value]="
            booking?.online_check_in_status | checkInStatus : langService.lang
          "
          [label]="
            langService.dictionary['online-check-in-status'][langService.lang]
          "
          [disabled]="true"
        />
        <button
          class="secondary-button"
          [disabled]="booking?.online_check_in_status !== 'NA'"
          (click)="openCheckInEmailDialog()"
        >
          {{ langService.dictionary["send-email"][langService.lang] }}
        </button>
      </div>
      <div class="input-wrapper">
        <custom-input
          formControlName="contact_name"
          [required]="true"
          [label]="langService.dictionary['name'][langService.lang] + '*'"
        />
      </div>
      <div class="input-wrapper">
        <custom-select
          formControlName="nationality"
          [required]="true"
          [options]="
            nationalityOptions
              | countryFilter : hotelService?.selectedHotel?.countries
          "
          (change)="setCommunicationLang($event)"
          [label]="
            langService.dictionary['nationality'][langService.lang] + '*'
          "
        />
      </div>

      <div class="input-wrapper">
        <custom-select
          formControlName="language"
          [required]="true"
          [options]="
            communicationLangOptions
              | languageFilter : hotelService?.selectedHotel?.languages
          "
          [label]="
            langService.dictionary['communication-language'][langService.lang] +
            '*'
          "
        />
      </div>

      <div class="input-wrapper">
        <custom-select
          #channel
          formControlName="channel"
          [required]="true"
          [options]="chanelOptions"
          [label]="langService.dictionary['channel'][langService.lang] + '*'"
          [disabled]="booking?.external_id !== null"
        />
      </div>

      <div class="input-wrapper">
        <custom-select
          *ngIf="channel.value === 'KOZVETITO_ONLINE'"
          formControlName="source"
          [options]="sourceOptions"
          [label]="langService.dictionary['platform'][langService.lang]"
          [disabled]="booking?.external_id !== null"
        />
      </div>

      <div class="input-wrapper">
        <custom-input
          formControlName="contact_email"
          [type]="'email'"
          [label]="langService.dictionary['email'][langService.lang]"
          [maxlength]="64"
        />
      </div>

      <div class="input-wrapper">
        <custom-input
          formControlName="contact_phone"
          [type]="'text'"
          [label]="langService.dictionary['phone'][langService.lang]"
          [maxlength]="12"
        />
      </div>

      <div class="input-wrapper">
        <div class="checkbox-wrapper">
          <custom-checkbox
            #work_related
            formControlName="work_related"
            [name]="'work_related'"
            [label]="langService.dictionary['work'][langService.lang]"
            (userInput)="resetCompanyDetails($event)"
          />
          <custom-checkbox
            formControlName="group_booking"
            [label]="langService.dictionary['group'][langService.lang]"
          />
        </div>
      </div>

      <div class="input-wrapper">
        <custom-input
          *ngIf="work_related.value === true"
          formControlName="company_name"
          [type]="'text'"
          [label]="langService.dictionary['company-name'][langService.lang]"
          [maxlength]="32"
        />
      </div>

      <div class="input-wrapper">
        <custom-input
          *ngIf="work_related.value === true"
          formControlName="tax_number"
          [type]="'text'"
          [label]="
            langService.dictionary['company-tax-number'][langService.lang]
          "
          [maxlength]="12"
        />
      </div>
      <div class="input-wrapper">
        <custom-select
          formControlName="tax_zone"
          [options]="taxZoneOptions"
          [label]="langService.dictionary['tax-zone'][langService.lang]"
          *ngIf="work_related.value"
        ></custom-select>
      </div>
    </form>
  </div>
  <div class="button-wrapper clickable">
    <button class="secondary-button" (click)="setBackOldForm()" type="button">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>

    <button
      class="primary-button"
      type="submit"
      [disabled]="
        form.invalid || areObjectsEqual(form.value, bookingService.bookingData)
      "
      (click)="editBookingDetails()"
    >
      <span *ngIf="!isLoading"
        >{{ langService.dictionary["modify"][langService.lang] }}
      </span>
      <app-loading-spinner
        [loading]="isLoading"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </button>
  </div>
</div>
