<div class="welcome-wrapper">
  <h1>kis portás</h1>
  <div class="logo-wrapper">
    <img src="assets/hero.png" />
  </div>
  <div class="buttons-wrapper">
    <button (click)="navToLogin()">
      {{ langService.dictionary["admin-login"][langService.lang] }}
    </button>
    <button (click)="navToCheckIn()">
      {{ langService.dictionary["online-check-in"][langService.lang] }}
    </button>
  </div>
  <!-- <div class="version"><span>v</span>TODO</div> -->
</div>
