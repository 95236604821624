import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { LanguageService } from 'src/app/@services';

@Component({
    selector: 'premium-required',
    templateUrl: 'premium-required.snackbar.html',
    styleUrls: ['premium-required.snackbar.scss'],
    standalone: false
})
export class PremiumRequiredSnackBar {
  constructor(
    public snackBarRef: MatSnackBarRef<PremiumRequiredSnackBar>,
    public langService: LanguageService
  ) {}

  public closeSnackBar(): void {
    this.snackBarRef.dismiss();
  }
}
