import { Component, OnInit } from '@angular/core';
import {
  CapacitorBarcodeScanner,
  CapacitorBarcodeScannerCameraDirection,
  CapacitorBarcodeScannerOptions,
  CapacitorBarcodeScannerScanResult,
  CapacitorBarcodeScannerTypeHint,
} from '@capacitor/barcode-scanner';
import { Capacitor } from '@capacitor/core';
import {
  GuestService,
  LanguageService,
  RouterService,
  ScannerService,
} from 'src/app/@services';
@Component({
  selector: 'qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
  standalone: false,
})
export class QrScannerComponent implements OnInit {
  constructor(
    private guestService: GuestService,
    private routerService: RouterService,
    public langService: LanguageService,
    private scannerService: ScannerService
  ) {}

  private readonly barcodeScannerOptions: CapacitorBarcodeScannerOptions = {
    hint: CapacitorBarcodeScannerTypeHint.QR_CODE,
    cameraDirection: CapacitorBarcodeScannerCameraDirection.BACK,
  };

  ngOnInit(): void {
    this.scannerService.checkPermission().then(() => {
      this.openBarcodeScanner();
    });
  }

  public openBarcodeScanner(): void {
    if (!Capacitor.isNativePlatform()) return;

    CapacitorBarcodeScanner.scanBarcode(this.barcodeScannerOptions).then(
      (barcodeRes: CapacitorBarcodeScannerScanResult) => {
        this.guestService.readQrCode = JSON.parse(barcodeRes.ScanResult);
        this.routerService.absoluteNavigation('scanner');
        this.routerService.addQueryParams({
          booking: this.guestService.readQrCode.bookingId,
          room: this.guestService.readQrCode.roomId,
        });
      }
    );
  }
}
