import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { paymentOptions } from 'src/app/@consts';
import { CloseDayDialog } from 'src/app/@dialogs';
import {
  ChargeReqInterface,
  DashboardArrivalInterface,
  DashboardDepartureInterface,
  DashboardPayingInterface,
  DashboardResInterface,
} from 'src/app/@Interfaces';
import {
  BookingService,
  ChargeService,
  DashboardService,
  DateService,
  HotelService,
  LanguageService,
  RoomService,
  RouterService,
  UserService,
} from 'src/app/@services';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: false,
})
export class HomeComponent implements OnInit {
  constructor(
    public langService: LanguageService,
    public dashboardService: DashboardService,
    public userService: UserService,
    public hotelService: HotelService,
    private matDialog: MatDialog,
    private dateService: DateService,
    private routerService: RouterService,
    private bookingService: BookingService,
    private chargeService: ChargeService,
    public roomService: RoomService
  ) {}

  public dashboard: DashboardResInterface;
  public date: string = this.dateService.formatDate(new Date());
  public paymentMethods = paymentOptions;

  ngOnInit(): void {
    this.dashboardService
      .getDashboard()
      .then((dashboard: DashboardResInterface) => {
        this.dashboard = dashboard;
      });
  }

  public concludeDay(): void {
    if (!this.userService.user.double_conclude) {
      this.hotelService.concludeDay().catch(() => {});
    } else {
      this.matDialog
        .open(CloseDayDialog)
        .afterClosed()
        .subscribe((confirmation) => {
          if (!confirmation) {
            return;
          }

          this.hotelService.concludeDay().catch(() => {});
        });
    }
  }

  public remainingDays(): number {
    if (!this.hotelService?.selectedHotel?.last_concluded_day) return null;
    return (
      this.dateService.daysBetween(
        new Date().toDateString(),
        this.hotelService.selectedHotel.last_concluded_day
      ) - 1
    );
  }

  public navToArrival(booking: DashboardArrivalInterface): void {
    this.routerService.addQueryParams({
      hotel: this.hotelService.selectedHotelId,
      booking: booking.booking_id,
      room: booking.room_id,
    });

    this.routerService.absoluteNavigation(`calendar/arrival`);
  }

  public payCharges(charge: DashboardPayingInterface): void {
    const body: ChargeReqInterface = { charges: [] };
    charge.charge_ids.forEach((chargeId: number) => {
      body.charges.push({
        charge_id: chargeId,
        payment_method: charge.payment_method,
        payment_date: this.dateService.formatDate(new Date()),
      });
    });

    this.chargeService
      .payCharge(body, charge.booking_id)
      .then(() => {
        const index = this.dashboard.paying.indexOf(charge);
        if (index !== -1) {
          this.dashboard.paying.splice(index, 1);
        }
        const departIndex = this.dashboard.departing.findIndex(
          (departing) => departing.booking_id === charge.booking_id
        );
        this.dashboard.departing[departIndex].unpaid = 0;
      })
      .catch((err) => {
        console.log('charge pay err ', err);
      });
  }

  public departRoom(booking: DashboardDepartureInterface): void {
    this.bookingService
      .departRooms(booking.booking_id, [booking.room_id])
      .then(() => {
        const index = this.dashboard.departing.indexOf(booking);
        if (index !== -1) {
          this.dashboard.departing.splice(index, 1);
          this.dashboardService.completedTasks++;
        }
      })
      .catch(() => {});
  }

  public departAllRooms(): void {
    const bookings = this.groupByBookingId(this.dashboard.departing);
    bookings.forEach((booking) => {
      this.bookingService
        .departRooms(booking.booking_id, booking.rooms)
        .then((res: any) => {
          if (!res?.rooms?.length) return;

          this.dashboard.departing = this.dashboard.departing.filter(
            (dashBooking) => dashBooking.booking_id !== booking.booking_id
          );
          this.dashboardService.completedTasks++;
        });
    });
  }

  private groupByBookingId(
    departing: DashboardDepartureInterface[]
  ): { booking_id: number; rooms: number[] }[] {
    const grouped = departing.reduce((acc, item) => {
      const existing = acc.find(
        (entry) => entry.booking_id === item.booking_id
      );
      if (existing && item.arrived && !item.unpaid) {
        existing.rooms.push(item.room_id);
      } else if (item.arrived && !item.unpaid) {
        acc.push({
          booking_id: item.booking_id,
          rooms: [item.room_id.toString()],
        });
      }
      return acc;
    }, [] as any[]);

    return grouped;
  }
}
