<div class="rooms-ooo-wrapper">
  <div class="header">
    <h1>{{ langService.dictionary["ooo"][langService.lang] }}</h1>
    <!-- <p>Szobák amiket nem lehet kiadni</p> -->
  </div>
  <div class="hint" *ngIf="!roomService.selectedRoom">
    <span>{{ langService.dictionary["select-room"][langService.lang] }}</span>
  </div>

  <div class="date-list-wrapper" *ngIf="roomService.selectedRoom">
    <div class="date-card" *ngFor="let ooo of roomService.roomOOO">
      <div class="date-range">
        <span class="date">{{ ooo.start_date }}.</span
        ><span class="hyphen">-</span
        ><span class="date">{{ ooo.end_date }}.</span>
      </div>
      <button (click)="deleteOOO(ooo.ooo_id)">
        <i class="delete-icon"></i>
      </button>
    </div>
    <div *ngIf="!roomService.roomOOO.length" class="hint-wrapper">
      <span>{{ langService.dictionary["ooo"][langService.lang] }}</span>
    </div>
  </div>
  <div class="add-wrapper" *ngIf="roomService.selectedRoom">
    <div class="date-range">
      <date-picker
        [(ngModel)]="startDate"
        name="inputStartDate"
        [_value]="startDate"
        label="{{ langService.dictionary['start-date'][langService.lang] }}"
        [disabled]="false"
        (change)="setEndDate()"
      ></date-picker>

      <date-picker
        [(ngModel)]="endDate"
        name="inputendDate"
        [_value]="endDate"
        label="{{ langService.dictionary['end-date'][langService.lang] }}"
      ></date-picker>
    </div>
    <button
      class="primary-button"
      (click)="addOOO()"
      [disabled]="
        !startDate || !endDate || endDate?.getTime() - startDate?.getTime() < 0
      "
    >
      + {{ langService.dictionary["add"][langService.lang] }}
    </button>
  </div>
</div>
