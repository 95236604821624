import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
  chanelOptions,
  communicationLangOptions,
  nationalityOptions,
  sourceOptions,
  taxObjectsOptions,
  taxZoneOptions,
} from 'src/app/@consts';
import { CheckInEmailDialog } from 'src/app/@dialogs';
import {
  BookingDetailsInterface,
  BookingInterface,
} from 'src/app/@Interfaces/booking.interface';
import { HotelService, LanguageService, TableService } from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';

@Component({
  selector: 'booking-edit-details',
  templateUrl: './booking-edit-details.component.html',
  styleUrls: ['./booking-edit-details.component.scss'],
  standalone: false,
})
export class BookingEditDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public roomService: RoomService,
    public hotelService: HotelService,
    public langService: LanguageService,
    private matDialog: MatDialog,
    private tableService: TableService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;

  private originalDetails: BookingDetailsInterface;
  private bookingSub: Subscription;

  public readonly chanelOptions = chanelOptions;
  public readonly sourceOptions = sourceOptions;
  public readonly communicationLangOptions = communicationLangOptions;
  public readonly nationalityOptions = nationalityOptions;
  protected readonly taxZoneOptions = taxZoneOptions;
  protected readonly taxObjectOptions = taxObjectsOptions;

  public form: FormGroup = new FormGroup({
    channel: new FormControl(null, [Validators.required]),
    company_name: new FormControl(null),
    contact_email: new FormControl(null),
    contact_name: new FormControl(null, [
      Validators.required,
      Validators.maxLength(32),
    ]),
    contact_phone: new FormControl(null),
    external_id: new FormControl(null),
    group_booking: new FormControl(false),
    language: new FormControl(null, [Validators.required]),
    nationality: new FormControl(null, [Validators.required]),
    source: new FormControl(null),
    tax_number: new FormControl(null),
    tax_zone: new FormControl(null),
    work_related: new FormControl(false),
  });

  protected booking: BookingInterface;

  ngOnInit(): void {
    this.bookingSub = this.bookingService.selectedBooking$.subscribe(() => {
      if (!this.bookingService.selectedBooking) return;
      this.booking = this.bookingService.selectedBooking;

      this.form.reset(this.booking);
      this.originalDetails = structuredClone(this.booking);
    });
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  getControl(controlName: string): FormControl {
    return this.form.get(controlName) as FormControl;
  }

  protected resetCompanyDetails(isNeeded: boolean): void {
    if (isNeeded) return;
    this.form.patchValue({ tax_number: null });
    this.form.patchValue({ tax_zone: null });
    this.form.patchValue({ company_name: null });
  }

  public editBookingDetails(): void {
    this.isLoading = true;
    this.bookingService
      .putBookingDetails(
        this.bookingService.selectedBooking.booking_id,
        this.form.getRawValue()
      )
      .then(() => {
        const hotelId = this.hotelService.selectedHotel.hotel_id.toString();
        const bookingId =
          this.bookingService.selectedBooking.booking_id.toString();
        const roomId = this.bookingService.selectedBooking.room_id.toString();
        this.isLoading = false;
        this.bookingService.saveBookingData(this.form.value);
        this.bookingService
          .getBooking(hotelId, bookingId, roomId)
          .then(() => {})
          .catch(() => {});
        this.tableService.modifyBookingName(
          this.form.get('contact_name').value
        );
        /* this.bookingService.updateBookingsData(
          this.calendarService.selectedBooking.booking_id
        ); */
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public setCommunicationLang(val: any): void {
    if (!this.hotelService.selectedHotel) return null;
    if (this.hotelService.selectedHotel.languages.includes(val)) {
      this.form.patchValue({ language: val });
    } else if (this.hotelService.selectedHotel.languages.includes('EN')) {
      this.form.patchValue({ language: 'EN' });
    } else {
      this.form.patchValue({ language: 'HU' });
    }
  }

  public setBackOldForm(): void {
    this.form.reset(this.originalDetails);
  }

  public areObjectsEqual(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if values for each key are the same
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    // If all checks pass, the objects are equal
    return true;
  }

  public openCheckInEmailDialog(): void {
    const data = {
      name: this.booking.contact_name,
      email: this.booking.contact_email,
      language: this.booking.language,
      bookingId: this.bookingService.selectedBooking.booking_id,
    };
    this.matDialog.open(CheckInEmailDialog, { data: data });
  }

  ngOnDestroy(): void {
    this.bookingSub.unsubscribe();
  }
}
