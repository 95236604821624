export * from './@cards/arrival-card/arrival-card.component';
export * from './@cards/booking-room-card/booking-room-card.component';
export * from './@pages/admin/admin.component';
export * from './@pages/admin/hotel/hotel.component';
export * from './@pages/admin/rooms/rooms-submenus/rooms-add/rooms-add.component';
export * from './@pages/admin/rooms/rooms-submenus/rooms-edit/rooms-edit.component';
export * from './@pages/admin/rooms/rooms-submenus/rooms-ooo/rooms-ooo.component';
export * from './@pages/admin/rooms/rooms-submenus/rooms-oos/rooms-oos.component';
export * from './@pages/admin/rooms/rooms-submenus/rooms-overview/rooms-overview.component';
export * from './@pages/admin/rooms/rooms.component';
export * from './@pages/admin/services/services-submenus/services-add/services-add.component';
export * from './@pages/admin/services/services-submenus/services-edit/services-edit.component';
export * from './@pages/admin/services/services-submenus/services-overview/services-overview.component';
export * from './@pages/admin/services/services.component';
export * from './@pages/calendar/booking-panels/booking-arrival/booking-arrival.component';
export * from './@pages/calendar/booking-panels/booking-edit-details/booking-edit-details.component';
export * from './@pages/calendar/booking-panels/booking-edit-rooms/booking-edit-rooms.component';
export * from './@pages/calendar/booking-panels/booking-new-details/booking-new-details.component';
export * from './@pages/calendar/booking-panels/booking-new-rooms/booking-new-rooms.component';
export * from './@pages/calendar/booking-panels/booking-rooms-depart/booking-rooms-depart.component';
export * from './@pages/calendar/booking-panels/booking-rooms-payment/booking-rooms-payment.component';
export * from './@pages/calendar/calendar.component';
export * from './@pages/calendar/table/booking/booking.component';
export * from './@pages/calendar/table/table.component';
export * from './@pages/check-in-login/check-in-login.component';
export * from './@pages/home/home.component';
export * from './@pages/login/login.component';
export * from './@pages/page-not-found/page-not-found.component';
export * from './@pages/profile/profile.component';
export * from './@pages/register/register.component';
export * from './@pages/self-check-in/self-check-in.component';
export * from './@pages/support/support.component';
export * from './@pages/welcome/welcome.component';
export * from './guest-nav-bar/guest-nav-bar.component';
export * from './loading-spinner/loading-spinner.component';
export * from './nav-bar/nav-bar.component';
export * from './qr-scanner/qr-scanner.component';
export * from './scan/scanner.component';
