<div class="app-wrapper">
  <div class="main-wrapper">
    <nav-bar
      *ngIf="
        authService.IsAuthenticated && routerService.mainMenu !== 'scanner'
      "
    ></nav-bar>
    <guest-nav-bar
      *ngIf="
        !authService.IsAuthenticated && routerService.mainMenu !== 'scanner'
      "
      [ngClass]="{
        themed:
          routerService.mainMenu === 'check-in-login' ||
          routerService.mainMenu === 'self-check-in'
      }"
    ></guest-nav-bar>
    <router-outlet></router-outlet>
  </div>
</div>
