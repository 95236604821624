import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/@services';

@Component({
    selector: 'confirmation',
    templateUrl: './confirmation.dialog.html',
    styleUrls: ['./confirmation.dialog.scss'],
    standalone: false
})
export class ConfirmationDialog {
  constructor(
    private matDialogRef: MatDialogRef<ConfirmationDialog>,
    public langService: LanguageService
  ) {}

  public delete(): void {
    // TODO: logic
    this.matDialogRef.close(true);
  }

  public cancel(): void {
    this.matDialogRef.close(false);
  }
}
