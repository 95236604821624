<div class="check-in-email-wrapper">
  <h1>
    {{ langService.dictionary["online-check-in-dialog"][langService.lang] }}
  </h1>
  <form #myForm="ngForm">
    <custom-input
      [type]="'text'"
      [(ngModel)]="name"
      [label]="langService.dictionary['name'][langService.lang]"
      [required]="true"
      name="name"
    ></custom-input>
    <custom-input
      [type]="'email'"
      [(ngModel)]="email"
      [label]="langService.dictionary['email'][langService.lang]"
      [required]="true"
      name="email"
    ></custom-input>
    <custom-select
      [(ngModel)]="language"
      [options]="langOptions"
      [label]="langService.dictionary['language'][langService.lang]"
      [required]="true"
      name="language"
    ></custom-select>
    <button
      (click)="closePanel()"
      class="secondary-button"
    >
      {{ langService.dictionary["skip"][langService.lang] }}
    </button>
    <button
      (click)="sendEmail()"
      class="primary-button"
      [disabled]="myForm.invalid"
    >
      {{ langService.dictionary["send-email"][langService.lang] }}
    </button>
  </form>
</div>
