<div class="room-edit-wrapper">
  <div class="header">
    <h1>{{ langService.dictionary["modify"][langService.lang] }}</h1>
    <!-- <p>Itt módosíthatja a szolgáltatásokat</p> -->
  </div>
  <div class="hint" *ngIf="!serviceService.selectedService">
    <span>{{
      langService.dictionary["select-service"][langService.lang]
    }}</span>
  </div>
  <div class="form-wrapper" [hidden]="!serviceService.selectedService">
    <form #serviceForm="ngForm">
      <ng-container *ngIf="serviceService.selectedService">
        <custom-input
          [type]="'text'"
          id="name"
          name="name"
          [required]="true"
          [(ngModel)]="serviceService.selectedService.name"
          [label]="
            langService.dictionary['service-name'][langService.lang] + '*'
          "
          [maxlength]="32"
        />
        <custom-input
          [type]="'number'"
          id="price_huf"
          name="price_huf"
          [required]="true"
          [(ngModel)]="serviceService.selectedService.price_huf"
          [label]="
            langService.dictionary['gross-price'][langService.lang] + ' HUF*'
          "
        />
        <custom-select
          id="category"
          name="category"
          [required]="true"
          [(ngModel)]="serviceService.selectedService.category"
          [label]="langService.dictionary['category'][langService.lang] + '*'"
          [options]="serviceCategoryOptions"
        />
        <custom-select
          id="vat_rate"
          name="vat_rate"
          [required]="true"
          [(ngModel)]="serviceService.selectedService.vat_rate"
          [label]="langService.dictionary['vat'][langService.lang] + '*'"
          [options]="vatRateOptions"
        />
      </ng-container>
    </form>
  </div>

  <div class="button-wrapper" *ngIf="serviceService.selectedService">
    <button class="secondary-button" type="button" (click)="deselectService()">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>
    <button class="primary-button" type="submit" (click)="editService()">
      {{ langService.dictionary["modify"][langService.lang] }}
    </button>
  </div>
</div>
