import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgModel,
} from '@angular/forms';
import { FocusService } from 'src/app/@services';

@Component({
  selector: 'custom-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements ControlValueAccessor {
  constructor(
    public focusService: FocusService,
    private cdr: ChangeDetectorRef
  ) {}
  @Input() _value: any;
  @Input() disabled: boolean;
  @Input() highlighted: boolean;
  @Input() label: string;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() pattern: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() type: string;
  @Output() clicked = new EventEmitter<void>();
  @ViewChild('customInput', { static: false }) customInput: NgModel;

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.getPipedValue(this._value);
  }

  set value(val: any) {
    this._value = this.getPipedValue(val);
    if (this.value === '') this.value = null;
    this.onChange(this._value);
    this.onTouched();
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    this._value = this.getPipedValue(value);

    if (value === null) this.customInput?.control?.markAsUntouched();

    this.cdr.detectChanges();
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public sendChange(): void {
    this._value = this.getPipedValue(this._value);

    this.onChange(this._value);
    this.onTouched();
  }

  public emitClick(): void {
    this.clicked.emit();
  }

  private isConvertibleToNumber(value: string | number): boolean {
    return !isNaN(Number(value));
  }

  private getPipedValue(num: number | string): number | string {
    if (this.type === 'number' && this.isConvertibleToNumber(num)) {
      const number: number = Number(num);

      if (number % 1 === 0) return number;

      return Math.trunc(number * 100) / 100;
    } else {
      return num;
    }
  }
}
