import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'documentType',
    standalone: false
})
export class DocumentTypePipe implements PipeTransform {
  transform(value: string, lang: string): string {
    const translations: { [key: string]: any } = {
      en: {
        id: 'Identity Card',
        passport: 'Passport',
      },
      hu: {
        id: 'Személyi igazolvány',
        passport: 'Útlevél',
      },
    };

    const documentType = translations[lang]?.[value.toLowerCase()];

    return documentType ? documentType : value;
  }
}
