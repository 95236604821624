<div
  class="custom-select-component"
  [ngClass]="{
    highlighted: highlighted && !customInput.touched && !_value,
    transparent: transparent
  }"
>
  <select
    #customInput="ngModel"
    type="dropdown"
    id="channel"
    name="channel"
    [required]="required"
    class="form-dropdown-select"
    [(ngModel)]="_value"
    (ngModelChange)="sendChange()"
    [disabled]="disabled"
  >
    <option
      *ngFor="let option of options"
      [selected]="option.selected"
      [disabled]="option.disabled"
      [ngValue]="option.value"
    >
      {{ option.label[langService.lang] }}
    </option>
  </select>
  <span class="form-dropdown-chevron">&#8744;</span>
  <span class="form-dropdown-label">{{ label }}</span>
</div>
