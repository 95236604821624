import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { LanguageService, UserService } from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';

@Component({
  selector: 'guest-nav-bar',
  templateUrl: './guest-nav-bar.component.html',
  styleUrls: ['./guest-nav-bar.component.scss'],
  standalone: false,
})
export class GuestNavBarComponent {
  constructor(
    public hotelService: HotelService,
    public focusService: FocusService,
    public langService: LanguageService,
    private userService: UserService
  ) {
    this.isNative = Capacitor.isNativePlatform();
  }

  protected isNative: boolean;

  protected switchLang(): void {
    this.langService.lang = this.langService.lang === 'hu' ? 'en' : 'hu';
  }

  protected switchTheme(): void {
    this.userService.changeTheme(!this.userService.isDarkMode);
  }
}
