import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { LanguageService } from 'src/app/@services';

@Component({
    selector: 'unsuccessful',
    templateUrl: 'unsuccessful.snackbar.html',
    styleUrls: ['unsuccessful.snackbar.scss'],
    standalone: false
})
export class UnsuccessfulSnackBar {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
    public snackBarRef: MatSnackBarRef<UnsuccessfulSnackBar>,
    public langService: LanguageService
  ) {}

  public closeSnackBar(): void {
    this.snackBarRef.dismiss();
  }
}
