import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/@services';

@Component({
  selector: 'successful-scan-dialog',
  templateUrl: './successful-scan.dialog.html',
  styleUrls: ['./successful-scan.dialog.scss'],
  standalone: false,
})
export class SuccessfulScanDialog {
  constructor(
    private dialogRef: MatDialogRef<SuccessfulScanDialog>,
    public langService: LanguageService
  ) {}

  public closeDialog(nextScan: boolean): void {
    this.dialogRef.close(nextScan);
  }
}
