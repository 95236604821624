import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/@services';

@Component({
  selector: 'unsuccessful-scan-dialog',
  templateUrl: './unsuccessful-scan.dialog.html',
  styleUrls: ['./unsuccessful-scan.dialog.scss'],
  standalone: false,
})
export class UnsuccessfulScanDialog {
  constructor(
    private dialogRef: MatDialogRef<UnsuccessfulScanDialog>,
    public langService: LanguageService
  ) {}

  public closeDialog(manualScan: boolean): void {
    this.dialogRef.close(manualScan);
  }
}
