import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'error',
    templateUrl: './error.dialog.html',
    styleUrls: ['./error.dialog.scss'],
    standalone: false
})
export class ErrorDialog {
  constructor(
    private matDialogRef: MatDialogRef<ErrorDialog>,
    @Inject(MAT_DIALOG_DATA) public errorMessage: string
  ) {}
}
