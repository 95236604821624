<div class="scanner-wrapper">
  <div *ngIf="documentType === 'id'" class="tooltip">
    {{ langService.dictionary["scanner-read-id"][langService.lang] }}
  </div>
  <div *ngIf="documentType == 'passport'" class="tooltip">
    {{ langService.dictionary["scanner-read-passport"][langService.lang] }}
  </div>
  <button class="doc-type-button" (click)="switchIdType()">
    {{ documentType | documentType : langService.lang }}
  </button>
  <div class="progress">{{ peopleScanned }} / {{ total }}</div>

  <canvas #canvas></canvas>

  <div
    class="image-wrapper"
    [style.backgroundImage]="'url(' + divBackgroundSource + ')'"
    *ngIf="divBackgroundSource"
  ></div>

  <div class="camera-toolbar">
    <button class="camera-switch-button" (click)="switchNextVideo()">
      <i class="camera-switch-icon"></i>
    </button>
    <button (click)="captureFrame()" class="capture-button">
      <div class="filler"></div>
    </button>
    <button class="back-button" (click)="backToBooking()">
      <i class="back-icon"></i>
    </button>
  </div>
  <div class="loading-screen" *ngIf="isLoading">
    <div class="scanner-animation">
      <div class="scan-bar"></div>
    </div>
  </div>
</div>
