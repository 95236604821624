import {
  ComponentRef,
  Injectable,
  QueryList,
  ViewContainerRef,
} from '@angular/core';
import { BookingComponent } from '../@components';
import { BookingInterface, BookingView } from '../@Interfaces';
import { BookingService } from './booking.service';
import { CalendarService } from './calendar.service';
import { DateService } from './date.service';
import { HotelService } from './hotel.service';
import { RoomService } from './room.service';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  constructor(
    private bookingService: BookingService,
    private dateService: DateService,
    private roomService: RoomService,
    private hotelService: HotelService,
    private calendarService: CalendarService
  ) {}

  public roomIdForCalendar: number;
  public cells: QueryList<ViewContainerRef>;
  private compRefs: ComponentRef<BookingComponent>[] = [];

  public insertBookings(): void {
    this.compRefs.forEach((ref) => {
      ref.destroy();
    });

    this.compRefs = [];

    this.bookingService.bookings.forEach((booking: BookingInterface) => {
      const bookingView: BookingView = {
        adults: booking.adults,
        arrDate: booking.arrival_date,
        bookingId: booking.booking_id,
        children: booking.children,
        depDate: booking.departure_date,
        name: booking.contact_name,
        roomId: booking.room_id,
        reversed: null,
      };
      this.addRoom(bookingView);
    });
  }

  public handleDatePicker(
    arrivalDate: string,
    departureDate: string,
    roomId: number
  ): void {
    const compRef = this.compRefs.find(
      (ref) =>
        ref.instance.bookingView.roomId === roomId &&
        (!ref.instance.bookingView.bookingId ||
          ref.instance.bookingView.bookingId ===
            this.bookingService.selectedBooking?.booking_id)
    );
    if (!compRef) return;

    const bookingView = compRef.instance.bookingView;

    if (
      (!compRef.instance.bookingView.reversed &&
        compRef.instance.bookingView.arrDate !== arrivalDate) ||
      (compRef.instance.bookingView.reversed &&
        compRef.instance.bookingView.depDate !== departureDate)
    ) {
      this.removeRoomByRef(compRef);
      bookingView.arrDate = arrivalDate;
      bookingView.depDate = departureDate;
      this.addRoom(bookingView);
      return;
    }

    compRef.instance.bookingView.arrDate = arrivalDate;
    compRef.instance.bookingView.depDate = departureDate;
    compRef.instance.setBookingWidth();
  }

  public addRoom(bookingView: BookingView): void {
    const roomIndex = this.roomService.rooms.findIndex(
      (room) => room.room_id === bookingView.roomId
    );
    const columns = this.calendarService.dateList.length;
    const rowStart = roomIndex * columns;
    let dateIndex = this.calendarService.dateList.findIndex(
      (date) => this.dateService.formatDate(date) === bookingView.arrDate
    );
    if (dateIndex === -1) {
      dateIndex = this.calendarService.dateList.findIndex(
        (date) => this.dateService.formatDate(date) === bookingView.depDate
      );
      bookingView.reversed = true;
    }
    const ref = this.cells
      .toArray()
      [dateIndex + rowStart].createComponent(BookingComponent);

    ref.instance.bookingView = bookingView;
    this.compRefs.push(ref);
  }

  public updateGuestsNumber(
    bookingId: number,
    roomId: number,
    adults: number,
    children: number
  ) {
    const ref = this.compRefs.find(
      (ref) =>
        ref.instance.bookingView.bookingId === bookingId &&
        ref.instance.bookingView.roomId === roomId
    );

    if (!ref) return;

    ref.instance.bookingView.adults = adults;
    ref.instance.bookingView.children = children;
  }

  public updateDepartureDate(
    date: string,
    bookingId: number = null,
    roomId: number = null
  ): void {
    const booking_id =
      this.bookingService?.selectedBooking?.booking_id ?? bookingId;
    const room_id = this.bookingService?.selectedBooking?.room_id ?? roomId;
    const ref = this.compRefs.find(
      (ref) =>
        ref.instance.bookingView.bookingId === booking_id &&
        ref.instance.bookingView.roomId === room_id
    );
    if (!ref) return;

    ref.instance.bookingView.depDate = date;
    ref.instance.setBookingWidth();
  }

  public modifyBookingName(name: string): void {
    const ref = this.compRefs.find(
      (ref) =>
        ref.instance.bookingView.bookingId ===
          this.bookingService.selectedBooking.booking_id &&
        ref.instance.bookingView.roomId ===
          this.bookingService.selectedBooking.room_id
    );
    if (!ref) return;

    ref.instance.bookingView.name = name;
  }

  public removeBooking(bookingId: number): void {
    this.compRefs = this.compRefs.filter((ref) => {
      if (ref.instance.bookingView.bookingId === bookingId) {
        ref.destroy();
        return false;
      }
      return true;
    });
  }

  public removeRoomById(bookingId: number, roomId: number): void {
    this.compRefs = this.compRefs.filter((ref) => {
      if (
        ref.instance.bookingView.roomId === roomId &&
        ref.instance.bookingView.bookingId === bookingId
      ) {
        ref.destroy();
        return false;
      }
      return true;
    });
  }

  public removeRoomByRef(ref: ComponentRef<BookingComponent>): void {
    const index = this.compRefs.findIndex(
      (compRef) => compRef.instance === ref.instance
    );
    if (index === -1) return;
    ref.destroy();
    this.compRefs.splice(index, 1);
  }

  public removeNewRooms(): void {
    this.compRefs = this.compRefs.filter((ref) => {
      if (
        !ref.instance.bookingView.bookingId ||
        ref.instance.bookingView.newRoom
      ) {
        ref.destroy();
        return false;
      }
      return true;
    });
  }

  public makeNewRoomsPermanent(bookingId: number): void {
    this.compRefs.forEach((ref) => {
      if (
        !ref.instance.bookingView.bookingId ||
        ref.instance.bookingView.newRoom
      ) {
        ref.instance.bookingView.newRoom = false;
        ref.instance.bookingView.bookingId = bookingId;
      }
    });
  }

  public restoreBooking(bookingId: number, roomId: number): void {
    const index = this.compRefs.findIndex(
      (ref) =>
        ref.instance.bookingView.bookingId === bookingId &&
        ref.instance.bookingView.roomId === roomId
    );
    if (this.compRefs[index]) {
      this.compRefs[index].destroy();
      this.compRefs.splice(index, 1);
    }

    const booking = this.bookingService.bookings.find(
      (booking) =>
        booking.booking_id === bookingId && booking.room_id === roomId
    );
    //TODO: avoid calling this function when not necessary
    if (!booking) return;
    const bookingView: BookingView = {
      adults: booking.adults,
      arrDate: booking.arrival_date,
      bookingId: booking.booking_id,
      children: booking.children,
      depDate: booking.departure_date,
      name: booking.contact_name,
      roomId: booking.room_id,
      reversed: null,
    };
    this.addRoom(bookingView);
  }

  public clearRoomBookings(): void {
    this.removeNewRooms();
    this.bookingService.resetSelectedRooms();
  }

  getBookedDayFilter = (date: Date): boolean => {
    if (!date) {
      return false;
    }
    const modifiedDate = new Date(date);
    const bookingId = this.bookingService?.selectedBooking?.booking_id ?? 0;
    return this.roomService.isRoomAvailable(
      new Date(modifiedDate.setDate(modifiedDate.getDate() + 1)),
      this.roomIdForCalendar,
      this.bookingService.bookings,
      bookingId
    );
  };

  getBookedDayDepFilter = (date: Date): boolean => {
    if (!date) {
      return false;
    }
    const modifiedDate = new Date(date);
    const bookingId = this.bookingService?.selectedBooking?.booking_id ?? 0;
    return this.roomService.isRoomAvailable(
      new Date(modifiedDate.setDate(modifiedDate.getDate())),
      this.roomIdForCalendar,
      this.bookingService.bookings,
      bookingId
    );
  };

  getBookedDayFilterDeparture = (date: Date): boolean => {
    //won't allow to pick sooner date than start date
    if (!date) {
      return false;
    }
    if (
      this.bookingService.sidePanelRooms[this.roomIdForCalendar]?.arrival_date
    ) {
      const arrDate = new Date(
        this.bookingService.sidePanelRooms[this.roomIdForCalendar].arrival_date
      ).getTime();
      if (arrDate > date.getTime()) {
        return false;
      }
    }
    const modifiedDate = new Date(date);
    return this.roomService.isRoomAvailable(
      new Date(modifiedDate.setDate(modifiedDate.getDate())),
      this.roomIdForCalendar,
      this.bookingService.bookings,
      this.bookingService.selectedBooking.booking_id
    );
  };

  groupDateFilter = (date: Date): boolean => {
    if (!date) {
      return false;
    }
    const rooms = Object.keys(this.bookingService.sidePanelRooms);
    const modifiedDate = new Date(date);
    return this.roomService.areRoomsAvailable(
      new Date(modifiedDate.setDate(modifiedDate.getDate() + 1)),
      rooms,
      this.bookingService.bookings
    );
  };

  public validateBookings(): boolean {
    let isValid: boolean = true;

    if (!Object.keys(this.bookingService.sidePanelRooms).length) return false;
    Object.keys(this.bookingService.sidePanelRooms).forEach((key) => {
      const booking = this.bookingService.sidePanelRooms[key];
      if (booking.adults + booking.children <= 0) {
        isValid = false;
      }
      if (booking.gross_price <= 0) {
        isValid = false;
      }
      if (!booking.arrival_date || !booking.departure_date) {
        isValid = false;
      }
      if (booking.gross_price <= booking.prepayment) {
        isValid = false;
      }
      if (
        this.dateService.isBookedBetween(
          booking.room_id,
          booking.arrival_date,
          booking.departure_date,
          this.bookingService.bookings,
          booking.booking_id
        )
      ) {
        isValid = false;
      }
    });

    return isValid;
  }

  private checkSelectedDate(
    roomId: number,
    arrivalDate: string,
    departureDate: string,
    isStartDate?: boolean
  ): void {
    if (this.dateService.isDateSooner(arrivalDate, departureDate)) {
      return;
    }
    if (isStartDate) {
      this.bookingService.sidePanelRooms[roomId].departure_date =
        this.dateService.addDayToStringDate(arrivalDate, 1);
    } else {
      this.bookingService.sidePanelRooms[roomId].arrival_date =
        this.dateService.addDayToStringDate(departureDate, -1);
    }
  }

  public calculateRoomPrice(
    roomId: string | number,
    arrivalDate: string,
    departureDate: string
  ): number {
    const selectedRoom = this.roomService.rooms.find(
      (room) => room.room_id == Number(roomId)
    );

    if (!selectedRoom || !arrivalDate || !departureDate) return null;

    const d1 = new Date(arrivalDate);
    const d2 = new Date(departureDate);

    const diffInTime = Math.abs(d2.getTime() - d1.getTime());
    const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

    return diffInDays * selectedRoom.default_price_huf;
  }

  private getPrePaymentAmount(defaultPrice: number): number {
    const flat = this.hotelService.selectedHotel.prepayment_flat;
    if (flat) return flat;

    const mult = this.hotelService.selectedHotel.prepayment_percent / 100;

    const price = defaultPrice * mult;
    return price;
  }
}
