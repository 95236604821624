import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { CheckInRoom, GuestInterface } from 'src/app/@Interfaces';
import {
  BookingService,
  CalendarService,
  CheckInService,
  DateService,
  FocusService,
  GuestService,
  HotelService,
  LanguageService,
  RoomService,
  RouterService,
} from 'src/app/@services';
import { ArrivalCardComponent } from '../../@cards/arrival-card/arrival-card.component';

@Component({
  selector: 'self-check-in',
  templateUrl: './self-check-in.component.html',
  styleUrls: ['./self-check-in.component.scss'],
  standalone: false,
})
export class SelfCheckInComponent implements OnInit, OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    private guestService: GuestService,
    private dateService: DateService,
    public checkInService: CheckInService,
    private routerService: RouterService,
    public langService: LanguageService,
    private hotelService: HotelService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren('guestForms') guestForms: QueryList<ArrivalCardComponent>;
  public _isEditing: boolean;
  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public roomsToDepart: number[] = [];
  public selectedLanguage: string;
  public selectedRoom: CheckInRoom;
  public scannedProgress: object;
  public checkedInProgress: object;
  protected rooms: CheckInRoom[] = [];
  protected isScannerOpen: boolean = false;

  public guests: GuestInterface[] = [];
  public areFormsValid: boolean;

  ngOnInit(): void {
    if (!this.checkInService.checkInId) {
      this.routerService.absoluteNavigation('login');
      return;
    }
    this.rooms = this.checkInService.checkInDetail.rooms;
    console.log('guests ', this.checkInService.checkInDetail.guests);

    this.selectRoom(this.rooms[0]);
    this.hotelService.selfCheckInHotelName = this.rooms[0].hotel_name;
    this.setProgress();
  }

  public addSingleGuest(guest: GuestInterface): void {
    this.checkInService
      .saveCheckInGuest(
        this.checkInService.checkInId,
        guest,
        this.selectedRoom.room_id
      )
      .then(() => {
        guest.guest_id = 'arrived';
        this.checkInService
          .getCheckInDetails(this.checkInService.checkInId)
          .then(() => {
            this.rooms = this.checkInService.checkInDetail.rooms;
            this.selectRoom(this.rooms[0]);
            this.setProgress();
          });
      });
  }

  public checkValidations(): void {
    /* this.areFormsValid = this.guestForms.toArray().every((guestForm) => {
      return guestForm.guestForm.valid;
    }); */
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public selectRoom(room: CheckInRoom): void {
    this.selectedRoom = room;
    console.log('selected room ', this.selectedRoom);

    this.createCards();
    this.fillCards(this.checkInService.checkInDetail.guests);
    this.guestService.totalGuestsToScan =
      this.selectedRoom.adults + this.selectedRoom.children;
    let guestsScanned = 0;
    this.guests.forEach((guest) => {
      if (
        guest.room_id === this.selectedRoom.room_id &&
        guest.document_number_og
      ) {
        guestsScanned++;
      }
    });
    this.guestService.guestsScanned = guestsScanned;
  }

  protected openScanner(): void {
    this.isScannerOpen = true;
  }

  protected closeScanner(): void {
    this.isScannerOpen = false;
    this.checkInService
      .getCheckInDetails(this.checkInService.checkInId)
      .then(() => {
        this.rooms = this.checkInService.checkInDetail.rooms;
        this.selectRoom(this.rooms[0]);
        this.setProgress();
      });
  }

  private createCards(): void {
    const neededGuests = this.selectedRoom.adults + this.selectedRoom.children;
    const adults = this.selectedRoom.adults;

    this.guests = [];
    let adultCounter = 1;
    let childCounter = 1;

    for (let i = 0; i < neededGuests; i++) {
      const type = i < adults ? 'Felnőtt' : 'Gyerek';
      const number = type === 'Felnőtt' ? adultCounter++ : childCounter++;
      this.guests.push({
        guest_id: null,
        birth_place: null,
        birth_date: null,
        tax_exempt: false,
        tax_exemption_reason: null,
        room_id: this.selectedRoom.room_id,
        gender: null,
        country_of_residence: null,
        postcode_of_residence: null,
        nationality: null,
        birth_first_name: null,
        birth_last_name: null,
        mothers_first_name: null,
        mothers_last_name: null,
        visa_arrival_date: null,
        visa_arrival_place: null,
        visa_number: null,
        guest_arrival_id: null,
        default_name: `${type} ${number}`,
      });
    }
  }

  private fillCards(guests: GuestInterface[]): void {
    let i = 0;
    let otherGuests: number = 0;
    guests.forEach((guest) => {
      if (guest.room_id === this.selectedRoom.room_id) {
        if (guest.birth_date)
          guest.tax_exempt = !this.dateService.isOver18(guest.birth_date);
        this.guests[i] = guest;
        i++;
      } else {
        otherGuests++;
      }
    });
    this.guestService.totalGuestsToScan =
      this.guests.length - (guests.length - otherGuests);
  }

  private setProgress(): void {
    let totalNeeded = 0;
    let scanned = 0;
    let checkedIn = 0;
    this.guests.forEach((guest) => {
      if (guest.room_id === this.selectedRoom.room_id) {
        totalNeeded++;
      }
      if (guest.guest_id) {
        checkedIn++;
      }
      if (guest.document_number_og) {
        scanned++;
      }
      this.scannedProgress = {
        width: `${(scanned / totalNeeded) * 100}%`,
      };
      this.checkedInProgress = {
        width: `${(checkedIn / totalNeeded) * 100}%`,
      };
    });
  }

  ngOnDestroy(): void {
    this.roomsToDepart = [];
  }
}
