<div class="service-select-wrapper" *ngIf="!charge">
  <div class="service-card" (click)="choosePrepayment()">
    <div class="service-category">
      <i class="prepayment-icon"></i>
    </div>
    <div class="service-name">
      <span>{{ langService.dictionary["prepayment"][langService.lang] }}</span>
    </div>
    <div class="service-price">
      <span
        >{{ langService.dictionary["custom-price"][langService.lang] }}</span
      >
    </div>
  </div>
  <div
    class="service-card"
    *ngFor="let service of services"
    (click)="chooseService(service)"
  >
    <div class="service-category">
      <i *ngIf="service.category === 'FOOD'" class="food-icon"></i>
      <i *ngIf="service.category === 'DRINK'" class="drink-icon"></i>
      <i *ngIf="service.category === 'WELLNESS'" class="wellness-icon"></i>
      <i *ngIf="service.category === 'OTHER'" class="other-service-icon"></i>
    </div>
    <div class="service-name">
      <span>{{ service.name }}</span>
    </div>
    <div class="service-price">
      <span>{{ service.price_huf }} Ft</span>
    </div>
  </div>
</div>
<charge-card
  *ngIf="charge"
  [charge]="charge"
  (refreshData)="closeAndRefresh()"
></charge-card>
