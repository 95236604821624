<div class="room-add-wrapper">
  <div class="header">
    <h1>{{ langService.dictionary["add"][langService.lang] }}</h1>
    <!-- <p>
      Még
      <span>{{
        hotelService.selectedHotel.room_limit - roomService.rooms.length
      }}</span>
      szoba hiányzik
    </p> -->
  </div>
  <div class="form-wrapper">
    <form #roomForm="ngForm">
      <custom-input
        [type]="'text'"
        name="name"
        [required]="true"
        [(ngModel)]="room.name"
        [label]="langService.dictionary['room-name'][langService.lang] + '*'"
        [maxlength]="32"
      />
      <custom-input
        [type]="'number'"
        name="room_number"
        [required]="true"
        [(ngModel)]="room.room_number"
        [label]="langService.dictionary['room-number'][langService.lang] + '*'"
      />
      <custom-select
        id="type"
        name="type"
        [required]="true"
        [(ngModel)]="room.type"
        [options]="roomTypeOptions"
        [label]="langService.dictionary['room-type'][langService.lang] + '*'"
      />
      <custom-input
        [type]="'text'"
        name="building"
        [required]="true"
        [(ngModel)]="room.building"
        [label]="
          langService.dictionary['room-building'][langService.lang] +
          '* ' +
          langService.dictionary['room-building-example'][langService.lang]
        "
        [maxlength]="64"
      />
      <custom-select
        id="floor"
        name="floor"
        [required]="true"
        [(ngModel)]="room.floor"
        [options]="floorOptions"
        [label]="langService.dictionary['floor'][langService.lang] + '*'"
      />
      <custom-input
        [type]="'number'"
        name="single_beds"
        [required]="true"
        [(ngModel)]="room.single_beds"
        [label]="langService.dictionary['single-beds'][langService.lang] + '*'"
      />
      <custom-input
        [type]="'number'"
        name="double_beds"
        [required]="true"
        [(ngModel)]="room.double_beds"
        [label]="langService.dictionary['double-beds'][langService.lang] + '*'"
      />
      <custom-input
        [type]="'number'"
        name="extra_beds"
        [required]="true"
        [(ngModel)]="room.extra_beds"
        [label]="langService.dictionary['extra-beds'][langService.lang] + '*'"
      />
      <custom-input
        [type]="'number'"
        name="couch_beds"
        [required]="true"
        [(ngModel)]="room.couch_beds"
        [label]="langService.dictionary['couch-beds'][langService.lang] + '*'"
      />
      <custom-input
        [type]="'number'"
        name="max_adults"
        [required]="true"
        [(ngModel)]="room.max_adults"
        [label]="langService.dictionary['max-adults'][langService.lang] + '*'"
      />
      <custom-input
        [type]="'number'"
        id="max_family_adults"
        name="max_family_adults"
        [required]="true"
        [(ngModel)]="room.max_family_adults"
        [label]="
          langService.dictionary['max-family-adults'][langService.lang] + '*'
        "
      />
      <custom-input
        [type]="'number'"
        name="max_family_children"
        [required]="true"
        [(ngModel)]="room.max_family_children"
        [label]="
          langService.dictionary['max-family-children'][langService.lang] + '*'
        "
      />
      <div class="row-wrapper">
        <custom-input
          [type]="'number'"
          name="default_price_huf"
          [required]="true"
          [(ngModel)]="room.default_price_huf"
          [label]="
            langService.dictionary['room-price'][langService.lang] + ' HUF*'
          "
        />
        <custom-checkbox
          id="plus_tax"
          name="plus_tax"
          [name]="'plus_tax'"
          [label]="langService.dictionary['plus-tax'][langService.lang]"
          [(ngModel)]="room.plus_tax"
        />
      </div>
      <custom-checkbox
        id="air_conditioning"
        name="air_conditioning"
        [name]="'air_conditioning'"
        [label]="langService.dictionary['air-conditioning'][langService.lang]"
        [(ngModel)]="room.air_conditioning"
      />
      <custom-checkbox
        id="wheelchair_accessible"
        name="wheelchair_accessible"
        [name]="'wheelchair_accessible'"
        [label]="
          langService.dictionary['wheelchair-accessible'][langService.lang]
        "
        [(ngModel)]="room.wheelchair_accessible"
      />
      <custom-input
        [type]="'text'"
        name="airbnb_calendar"
        [required]="false"
        [(ngModel)]="room.airbnb_calendar"
        [label]="langService.dictionary['airbnb-calendar'][langService.lang]"
        [maxlength]="128"
        *ngIf="hotelService.selectedHotel?.calendar_sync"
      />
      <custom-input
        [type]="'text'"
        name="booking_calendar"
        [required]="false"
        [(ngModel)]="room.booking_calendar"
        [label]="langService.dictionary['booking-calendar'][langService.lang]"
        [maxlength]="128"
        *ngIf="hotelService.selectedHotel?.calendar_sync"
      />
      <custom-input
        [type]="'text'"
        name="szallas_calendar"
        [required]="false"
        [(ngModel)]="room.szallas_calendar"
        [label]="langService.dictionary['szallas-calendar'][langService.lang]"
        [maxlength]="128"
        *ngIf="hotelService.selectedHotel?.calendar_sync"
      />
    </form>
  </div>
  <div class="button-wrapper">
    <button
      class="primary-button"
      type="submit"
      [disabled]="roomForm.invalid"
      (click)="newRoom()"
    >
      + {{ langService.dictionary["add"][langService.lang] }}
    </button>
  </div>
</div>
