import { Component } from '@angular/core';
import { App } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { LanguageService } from 'src/app/@services';

@Component({
    selector: 'check-in-finish',
    templateUrl: './check-in-finish.dialog.html',
    styleUrls: ['./check-in-finish.dialog.scss'],
    standalone: false
})
export class CheckInFinishDialog {
  constructor(public langService: LanguageService) {}

  public async toStorePage(): Promise<void> {
    if (!Capacitor.isNativePlatform()) return;
    const deviceInfo = await Device.getInfo();
    const platform = deviceInfo.platform;
    //https://apps.apple.com/us/app/kis-port%C3%A1s/id6739452200
    //https://play.google.com/store/apps/details?id=hu.kisportas.app
    if (platform === 'ios') {
      const appStoreUrl =
        'itms-apps://itunes.apple.com/app/id<6739452200>?action=write-review';

      const { value } = await AppLauncher.canOpenUrl({ url: appStoreUrl });
      if (value) {
        await AppLauncher.openUrl({ url: appStoreUrl });
      } else {
        console.log('Cannot open App Store URL');
      }
    } else if (platform === 'android') {
      const playStoreUrl = 'market://details?id=<hu.kisportas.app>';

      const { value } = await AppLauncher.canOpenUrl({ url: playStoreUrl });
      if (value) {
        await AppLauncher.openUrl({ url: playStoreUrl });
      } else {
        console.log('Cannot open Play Store URL');
      }
    }
  }
}
