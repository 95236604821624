import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { IResponse, UserInterface } from '../@Interfaces';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  private originalName: string;
  public isDarkMode: boolean;
  public user: UserInterface;

  public async getUser(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<IResponse<UserInterface>>(`${this.configService.server}api/user`)
        .subscribe({
          next: (res: IResponse<UserInterface>) => {
            this.user = res.data;
            this.originalName = this.user.name;
            resolve();
          },
          error: () => {
            console.log('get user error');
            reject();
          },
        });
    });
  }

  public async turnOnDoubleConclude(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/user/double-conclude`,
          {}
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            console.log('turnOnDoubleConclude error');
            this.user.double_conclude = false;
            reject();
          },
        });
    });
  }

  public async turnOffDoubleConclude(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete<IResponse>(
          `${this.configService.server}api/user/double-conclude`,
          {}
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            console.log('turnOffDoubleConclude error');
            this.user.double_conclude = true;
            reject();
          },
        });
    });
  }

  public async setUserLang(lang: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<IResponse>(`${this.configService.server}api/user/language`, {
          language: lang.toUpperCase(),
        })
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            console.log('language error');
            reject();
          },
        });
    });
  }

  public updateUserName(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put<IResponse>(`${this.configService.server}api/user/name`, {
          name: this.user.name,
        })
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            console.log('name update error');
            this.user.name = this.originalName;
            reject();
          },
        });
    });
  }

  public async setTheme(): Promise<void> {
    const storedTheme = Capacitor.isNativePlatform()
      ? (await Preferences.get({ key: 'theme' })).value
      : localStorage.getItem('theme');

    this.isDarkMode = storedTheme
      ? storedTheme === 'dark'
      : window.matchMedia('(prefers-color-scheme: dark)').matches;

    document.body.setAttribute(
      'data-theme',
      this.isDarkMode ? 'dark' : 'light'
    );
  }

  public changeTheme(isDarkMode: boolean): void {
    if (Capacitor.isNativePlatform()) {
      Preferences.set({ key: 'theme', value: isDarkMode ? 'dark' : 'light' });
    } else {
      localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    }

    document.body.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
    this.isDarkMode = isDarkMode;
  }
}
