<div class="invoice-wrapper">
  <form class="form" [formGroup]="form">
    <div class="invoice-header">
      <custom-select
        name="invoice_type"
        [options]="invoiceTypeOptions"
        [label]="'Számla típusa'"
        [(ngModel)]="isWorkRelated"
        (ngModelChanged)="cdrDetection()"
      />
      <button
        class="primary-button"
        (click)="createInvoice(form.value)"
        [disabled]="form.invalid || !anyChargeSelected()"
      >
        Számlázás
      </button>
      <custom-input
        name="total_amount"
        [type]="'number'"
        [label]="'Összeg'"
        [disabled]="true"
        [_value]="totalPrice"
      />
    </div>
    <div class="invoice-body" formGroupName="buyer">
      <div class="invoice-details-wrapper">
        <custom-input
          name="name"
          [type]="'string'"
          [label]="'Név'"
          [(ngModel)]="invoice.buyer.name"
        />
        <custom-select
          name="country"
          [(ngModel)]="invoice.buyer.tax_subject"
          [options]="taxObjectOptions"
          [required]="isWorkRelated"
          [label]="'Adóalany'"
          *ngIf="isWorkRelated"
        ></custom-select>
        <custom-select
          name="country"
          [(ngModel)]="invoice.buyer.tax_zone"
          [options]="taxZoneOptions"
          [required]="isWorkRelated"
          [label]="'Adózási zóna'"
          *ngIf="isWorkRelated"
        ></custom-select>
        <custom-input
          name="tax_number"
          [type]="'string'"
          [label]="'Adószám'"
          [required]="isWorkRelated"
          [(ngModel)]="invoice.buyer.tax_number"
          *ngIf="isWorkRelated"
        />

        <custom-select
          name="country"
          [(ngModel)]="invoice.buyer.country"
          [options]="nationalityOptions"
          [label]="'Ország'"
        ></custom-select>
        <custom-input
          name="postcode"
          [type]="'string'"
          [label]="'Irányítószám'"
          [(ngModel)]="invoice.buyer.postcode"
        />
        <custom-input
          name="city"
          [type]="'string'"
          [label]="'Város'"
          [(ngModel)]="invoice.buyer.city"
        />
        <custom-input
          name="address"
          [type]="'string'"
          [label]="'Utca házszám'"
          [(ngModel)]="invoice.buyer.address"
        />

        <custom-checkbox
          type="checkbox"
          (focus)="focusService.focusButton($event)"
          (focusout)="focusService.focusOutButton($event)"
          [(ngModel)]="invoice.e_invoice"
          name="e_invoice"
          [label]="'E számla'"
        />
        <custom-checkbox
          type="checkbox"
          (focus)="focusService.focusButton($event)"
          (focusout)="focusService.focusOutButton($event)"
          [(ngModel)]="invoice.prepayment_invoice"
          name="prepayment_invoice"
          [label]="'Előleg számla'"
        />
        <!-- <custom-select
        name="invoice_language"
        [(ngModel)]="invoice.invoice_language"
        [options]="nationalityOptions"
      ></custom-select>


      <custom-select
        name="tax_subject"
        [(ngModel)]="invoice.buyer.tax_subject"
        [options]="nationalityOptions"
      ></custom-select> -->
      </div>

      <div class="invoice-charges-wrapper">
        <div class="selectable-charges-wrapper">
          <div class="charges-header">Terhelések</div>
          <div class="charges-list">
            <ng-container *ngFor="let room of selectableCharges | keyvalue">
              <div class="room-panel">
                <div class="room-name"><span>{{room.key}}</span></div>
                <mat-icon (click)="selectRoom(room.key)"
                  >add_circle_outline</mat-icon
                >
                <div class="charges-wrapper">
                  <ng-container *ngFor="let charge of room.value"
                    ><div class="charge">
                      <span class="charge-name">{{charge.name}}</span>
                      <div class="right-section">
                        <div class="charge-cost">
                          <span>{{charge.gross_price}}</span><span>Ft</span>
                        </div>
                        <mat-icon (click)="selectCharge(charge)"
                          >add_circle_outline</mat-icon
                        >
                      </div>
                    </div></ng-container
                  >
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="selected-charges-wrapper">
          <div class="charges-header">Számlázandó</div>
          <div class="charges-list">
            <ng-container *ngFor="let room of selectedCharges | keyvalue">
              <div class="room-panel">
                <div class="room-name"><span>{{room.key}}</span></div>
                <mat-icon (click)="removeRoom(room.key)"
                  >remove_circle_outline</mat-icon
                >
                <div class="charges-wrapper">
                  <ng-container *ngFor="let charge of room.value"
                    ><div class="charge">
                      <span class="charge-name">{{charge.name}}</span>
                      <div class="right-section">
                        <div class="charge-cost">
                          <span>{{charge.gross_price}}</span><span>Ft</span>
                        </div>
                        <mat-icon (click)="removeCharge(charge)"
                          >remove_circle_outline</mat-icon
                        >
                      </div>
                    </div></ng-container
                  >
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
