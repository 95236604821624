import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { floorOptions, roomTypeOptions } from 'src/app/@consts';
import { LanguageService, RouterService } from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { RoomMenu } from 'src/app/enums';
@Component({
  selector: 'rooms-edit',
  templateUrl: './rooms-edit.component.html',
  styleUrls: ['./rooms-edit.component.scss'],
  standalone: false,
})
export class RoomsEditComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService,
    public langService: LanguageService,
    private routerService: RouterService
  ) {}

  public form: FormGroup = new FormGroup({
    name: new FormControl(null, [
      Validators.required,
      Validators.maxLength(32),
    ]),
    room_number: new FormControl([Validators.required]),
    type: new FormControl([Validators.required]),
    building: new FormControl([Validators.required]),
    floor: new FormControl([Validators.required]),
    single_beds: new FormControl([Validators.required]),
    double_beds: new FormControl([Validators.required]),
    extra_beds: new FormControl([Validators.required]),
    couch_beds: new FormControl([Validators.required]),
    max_adults: new FormControl([Validators.required]),
    max_family_adults: new FormControl([Validators.required]),
    max_family_children: new FormControl([Validators.required]),
    default_price_huf: new FormControl([Validators.required]),
    plus_tax: new FormControl(),
    air_conditioning: new FormControl(),
    wheelchair_accessible: new FormControl(),
    airbnb_calendar: new FormControl(),
    booking_calendar: new FormControl(),
    szallas_calendar: new FormControl(),
  });
  public dynamicThe: string = 'a(z)';

  public readonly roomTypeOptions = roomTypeOptions;
  public readonly floorOptions = floorOptions;

  public floors = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public selectedMenu: string = RoomMenu.Overview;
  public roomMenu = RoomMenu;
  public startDate: Date;
  public endDate: Date;

  ngOnInit(): void {
    if (this.hotelService?.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
    this.roomService.selectedRoom$.subscribe(() => {
      const room = this.roomService.selectedRoom;
      if (!room) return;
      this.form.reset(room);
    });
  }

  public deselectRoom(): void {
    this.roomService.selectedRoom = null;
    this.routerService.removeQueryParam(['room']);
  }

  public editRoom(): void {
    this.roomService
      .editRoom(
        this.form.value,
        this.roomService.selectedRoom.room_id.toString()
      )
      .then(() => {
        this.deselectRoom();
      })
      .catch(() => {});
  }
}
