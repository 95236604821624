<div
  class="room-panel"
  [ngClass]="{
    extended: (isEditing && isBookingModified()) || room.old_room_id
  }"
  [formGroup]="fG"
>
  <div class="room-panel-header">
    <div class="status">
      <span>{{ room | bookingStatus : langService.lang }}</span>
    </div>

    <div class="room-name">
      <custom-select
        class="form-dropdown-select"
        (change)="selectRoom($event)"
        [options]="roomSelectOptions"
        [transparent]="true"
        [_value]="room.room_id"
      />
    </div>

    <div class="delete">
      <button type="button" (click)="deleteRoom()" [disabled]="room.arrived">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>
  </div>

  <div class="left-col">
    <div class="date-wrapper">
      <date-picker
        [formControl]="getControl('arrival_date')"
        (change)="handleDateChange(true)"
        [isDateString]="true"
        [disabled]="!room.room_id || room.arrived"
        [filter]="tableService.getBookedDayFilter"
        (clicked)="setRoomIdForCalendar(room.room_id)"
      ></date-picker>
      <div class="nights-wrapper">
        <button (click)="subtractNight()" [disabled]="room.departed">-</button>
        <span class="num-of-nights"
          >{{
            calendarService.calculateDaysDifference(
              getControl("arrival_date").value,
              getControl("departure_date").value
            )
          }}
          {{ langService.dictionary["night"][langService.lang] }}</span
        >
        <button (click)="addNight()" [disabled]="room.departed">+</button>
      </div>

      <date-picker
        [formControl]="getControl('departure_date')"
        (change)="handleDateChange(false)"
        [isDateString]="true"
        [disabled]="!room.room_id || room.departed"
        [filter]="tableService.getBookedDayDepFilter"
        (clicked)="setRoomIdForCalendar(room.room_id)"
      ></date-picker>
    </div>

    <div class="occupancy-wrapper">
      <div class="occupancy-block">
        <input
          [disabled]="!room.room_id || room.arrived"
          type="number"
          [formControl]="getControl('adults')"
          (input)="setTotalPrice(); updateGuestsNumber()"
        />
        <div>
          <i class="adult-icon"></i>
        </div>
      </div>
      <div class="occupancy-block">
        <input
          [disabled]="!room.room_id || room.arrived"
          type="number"
          [formControl]="getControl('children')"
          (input)="updateGuestsNumber()"
        />
        <div>
          <i class="child-icon"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="right-col">
    <div class="amount-block cost-row">
      <input
        [disabled]="!room.room_id || room.paid"
        class="amount"
        type="number"
        [formControl]="getControl('gross_price')"
        (input)="setTotalPrice()"
      />
      <input type="text" class="huf-label" value="HUF" disabled />
    </div>

    <div class="ifa-wrapper cost-row">
      <custom-checkbox
        type="checkbox"
        name="ifa"
        [label]="'+ IFA'"
        [formControl]="getControl('plus_tax')"
        (change)="setTotalPrice()"
        *ngIf="hotelService?.selectedHotel?.flat_IFA"
      />
      <span *ngIf="!hotelService?.selectedHotel?.flat_IFA">IFA</span>
      <div class="ifa-amount-block">
        <input
          [disabled]="true"
          class="amount"
          type="number"
          [value]="
            flat_ifa
              ? ifa *
                calendarService.calculateDaysDifference(
                  fG.get('arrival_date').value,
                  fG.get('departure_date').value
                ) *
                fG.get('adults')?.value
              : fG.get('adults')?.value === 0
              ? 0
              : ((ifa / 100) * fG.get('gross_price')?.value) /
                (1 + ifa / 100 / 1.05) /
                1.05
          "
        />
        <input type="text" class="huf-label" value="HUF" disabled />
      </div>
    </div>

    <div
      class="pre-payment-wrapper cost-row"
      *ngIf="!isEditing && isPrepaymentEnabled"
    >
      <span>{{ langService.dictionary["prepayment"][langService.lang] }}</span>
      <input
        class="amount"
        [type]="'number'"
        [formControl]="getControl('prepayment')"
      />
      <input type="text" class="huf-label" value="HUF" disabled />
    </div>

    <div class="total-wrapper cost-row">
      <span>{{ langService.dictionary["total"][langService.lang] }}</span>
      <input
        class="amount"
        [type]="'number'"
        [(ngModel)]="totalPrice"
        [ngModelOptions]="{ standalone: true }"
        disabled
      />
      <input type="text" class="huf-label" value="HUF" disabled />
    </div>
  </div>

  <div class="modify-wrapper">
    <button class="secondary-button" (click)="cancelModification()">
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>
    <button
      class="secondary-button"
      *ngIf="isEditing && !room?.newRoom"
      (click)="editRoom()"
      [disabled]="room?.departed"
    >
      {{ langService.dictionary["modify"][langService.lang] }}
    </button>
    <button
      class="secondary-button"
      *ngIf="isEditing && room?.newRoom"
      (click)="saveNewRoom()"
      [disabled]="room?.departed"
    >
      {{ langService.dictionary["save"][langService.lang] }}
    </button>
  </div>
</div>
