import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Capacitor } from '@capacitor/core';
import { Subscription } from 'rxjs';
import {
  countryOfResidenceOptions,
  documentTypeOptions,
  genderOptions,
  nationalityOptions,
  taxExemptionReasonOptions,
} from 'src/app/@consts';
import { QRCodeDialog } from 'src/app/@dialogs';
import { GuestInterface } from 'src/app/@Interfaces/guest.interface';
import {
  BookingService,
  CalendarService,
  CheckInService,
  DateService,
  FocusService,
  GuestService,
  HotelService,
  LanguageService,
  RoomService,
  RouterService,
} from 'src/app/@services';

@Component({
  selector: 'arrival-card',
  templateUrl: './arrival-card.component.html',
  styleUrls: ['./arrival-card.component.scss'],
  standalone: false,
})
export class ArrivalCardComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private cdr: ChangeDetectorRef,
    private checkInService: CheckInService,
    private dateAdapter: DateAdapter<Date>,
    private dateService: DateService,
    public guestService: GuestService,
    private hotelService: HotelService,
    private routerService: RouterService,
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public focusService: FocusService,
    public roomService: RoomService,
    public langService: LanguageService,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
    this.isMobile = Capacitor.isNativePlatform();
  }

  @Input() guest: GuestInterface;
  @Input() totalGuests: number;
  @Input() roomId: string | number;
  @Input() set checkToOpen(check: any) {
    if (check) {
      if (
        !this.guest.document_number_og &&
        this.guestService.enableManualScan
      ) {
        this.guestService.enableManualScan = false;
        this.openDetails();
      }
    }
  }
  @ViewChild('last_name', { static: false }) asd: NgForm;
  @Output('isValid') isValid = new EventEmitter<void>();
  @Output('openCheckInScanner') openCheckInScanner = new EventEmitter<void>();
  @Output('addGuest') addGuestEmitter = new EventEmitter<GuestInterface>();
  @Output('reloadGuests') reloadGuests = new EventEmitter<GuestInterface>();
  public isLoading: boolean;
  public documentType: 'id' | 'passport' = 'id';
  public readonly genderOptions = genderOptions;
  public readonly documentTypeOptions = documentTypeOptions;
  public readonly countryOfResidenceOptions = countryOfResidenceOptions;
  public readonly nationalityOptions = nationalityOptions;
  public readonly taxExemptionReasonOptions = taxExemptionReasonOptions;

  private originalFormVal: string;
  private paramSub: Subscription;
  public qrData: any;
  protected guestForm: FormGroup;
  protected isNative: boolean;
  protected opened: boolean;
  protected progressStyle = {};
  protected status: string;

  public detailedView = false;

  public isMobile: boolean;

  ngOnInit(): void {
    this.isNative = Capacitor.isNativePlatform();
    this.paramSub = this.routerService.queryParams$.subscribe((params) => {
      this.qrData = JSON.stringify({
        hotelId: this.hotelService.selectedHotelId,
        bookingId:
          params.booking ?? this.bookingService?.selectedBooking?.booking_id,
        roomId: this.roomId,
        totalGuests: this.totalGuests,
      });
    });
    this.guestForm = this.fb.group({
      birth_place: [this.guest.birth_place],
      birth_date: [this.guest.birth_date],
      tax_exempt: [this.guest.tax_exempt],
      gender: [this.guest.gender],
      country_of_residence: [this.guest.country_of_residence],
      postcode_of_residence: [this.guest.postcode_of_residence],
      nationality: [this.guest.nationality],
      tax_exemption_reason: [this.guest.tax_exemption_reason],
      last_name: [this.guest.last_name],
      first_name: [this.guest.first_name],
      birth_last_name: [this.guest.birth_last_name],
      birth_first_name: [this.guest.birth_first_name],
      mothers_last_name: [this.guest.mothers_last_name],
      mothers_first_name: [this.guest.mothers_first_name],
      document_type: [this.guest.document_type],
      document_number: [this.guest.document_number],
      birth_date_og: [this.guest.birth_date_og],
      document_number_og: [this.guest.document_number_og],
      document_type_og: [this.guest.document_type_og],
      gender_og: [this.guest.gender_og],
      last_name_og: [this.guest.last_name_og],
      first_name_og: [this.guest.first_name_og],
      nationality_og: [this.guest.nationality_og],
      visa_number: [this.guest.visa_number],
      visa_arrival_date: [this.guest.visa_arrival_date],
      visa_arrival_place: [this.guest.visa_arrival_place],
    });

    this.progressStyle = {
      width: !this.guest.document_number_og
        ? '15%'
        : !this.guest.guest_id
        ? '60%'
        : '100%',
      backgroundColor: !this.guest.document_number_og
        ? '#d33f3f'
        : !this.guest.guest_id
        ? '#e29337'
        : '#4ccf2b',
    };

    this.setStatus();
    const date = this.getControl('birth_date').value;
    if (date) this.setTaxExempt(date);
  }

  ngAfterViewInit(): void {
    this.guestForm.valueChanges.subscribe(() => {
      this.isValid.emit();
    });
    this.getControl('birth_date').valueChanges.subscribe(() => {
      const date = this.getControl('birth_date').value;
      console.log('birth date', date);

      if (date) this.setTaxExempt(date);
    });

    this.originalFormVal = this.guestForm.getRawValue();

    this.cdr.detectChanges();
  }

  getControl(controlName: string): FormControl {
    return this.guestForm.get(controlName) as FormControl;
  }

  public openScan(): void {
    if (Capacitor.isNativePlatform()) {
      this.openScanner();
    } else {
      this.matDialog
        .open(QRCodeDialog, { data: this.qrData })
        .afterClosed()
        .subscribe((refresh: boolean) => {
          if (refresh) this.reloadGuests.emit();
        });
    }
  }

  public addGuest(): void {
    let guest = this.guestForm.getRawValue();
    if (!this.guest.first_name_og) {
      guest = { ...guest, ...this.copyDataToOgFields(guest) };
    }

    if (this.guest.document_type === 'U14') this.attachEmptyOgFields();
    this.addGuestEmitter.emit(guest);
    this.setStatus();
  }

  public editGuest(): void {
    const guest = this.guestForm.getRawValue();
    guest.guest_id = this.guest.guest_id;
    this.guestService
      .editGuest(guest)
      .then((success: any) => {
        //if (!success) return;
        this.originalFormVal = JSON.stringify(this.guestForm.getRawValue());
        this.guestForm.markAsPristine();
        this.opened = false;
        this.setStatus();
      })
      .catch(() => {});
  }

  protected openDetails(): void {
    this.opened = true;
  }

  public resetGuest(): void {
    this.guestForm.reset(this.originalFormVal);
    this.guestForm.markAsPristine();
    this.opened = false;
  }

  public formatDate(charge: GuestInterface, date: Date): void {
    charge.birth_date = this.dateService.formatDate(date);
  }

  public toggleDetailedView(): void {
    this.detailedView = !this.detailedView;
  }

  protected setTaxExempt(date: string): void {
    const isOver18 = this.dateService.isOver18(date);
    if (isOver18) return;
    this.guestForm.patchValue({ tax_exempt: !isOver18 });
    this.guestForm.patchValue({ tax_exemption_reason: 'im1' });
  }

  protected resetTaxReason(bool: Boolean): void {
    if (bool) return;
    this.guest.tax_exemption_reason = null;
  }
  protected openScanner(): void {
    if (this.checkInService.checkInId) {
      this.openCheckInScanner.emit();
    } else {
      this.routerService.absoluteNavigation('scanner');
    }
  }

  protected checkU14(): void {
    if (this.guest.document_type !== 'U14') return;
    this.guest.document_number = '-';
    this.guest.tax_exemption_reason = 'im1';
    this.guest.tax_exempt = true;
    this.guestForm.patchValue({ document_number: '-' });
    this.guestForm.patchValue({ tax_exemption_reason: 'im1' });
    this.guestForm.patchValue({ tax_exempt: true });
  }

  private attachEmptyOgFields(): void {
    this.guest.birth_date_og = '';
    this.guest.document_number_og = '';
    this.guest.document_type_og = '';
    this.guest.first_name_og = '';
    this.guest.gender_og = '';
    this.guest.last_name_og = '';
    this.guest.nationality_og = '';
  }

  private copyDataToOgFields(guestData: GuestInterface): any {
    let guest: any = {};
    guest.birth_date_og = guestData.birth_date;
    guest.document_number_og = guestData.document_number;
    guest.document_type_og = guestData.document_type;
    guest.first_name_og = guestData.first_name;
    guest.gender_og = guestData.gender;
    guest.last_name_og = guestData.last_name;
    guest.nationality_og = guestData.nationality;
    console.log('guest ', guest);

    return guest;
  }

  private setStatus(): void {
    this.status = !this.guest.document_number_og
      ? this.langService.dictionary['scan-needed'][this.langService.lang]
      : !this.guest.guest_id
      ? this.langService.dictionary['check-in-needed'][this.langService.lang]
      : this.langService.dictionary['done'][this.langService.lang];
  }

  ngOnDestroy(): void {
    this.paramSub.unsubscribe();
  }
}
