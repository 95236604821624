import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/@services';

@Component({
  selector: 'qr-code-dialog',
  templateUrl: './qr-code.dialog.html',
  styleUrls: ['./qr-code.dialog.scss'],
  standalone: false,
})
export class QRCodeDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public qrData: string,
    public langService: LanguageService,
    private dialogRef: MatDialogRef<QRCodeDialog>
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }
  public closeDialogAndUpdate(): void {
    this.dialogRef.close(true);
  }
}
