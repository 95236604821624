<div class="unsuccessful-scan-wrapper">
  <h1>{{ langService.dictionary["scan-failed"][langService.lang] }}</h1>
  <i class="error-icon"></i>
  <div>
    <button class="secondary-button" (click)="closeDialog(true)">
      {{ langService.dictionary["manual-entry"][langService.lang] }}
    </button>
    <button class="primary-button" (click)="closeDialog(false)">
      {{ langService.dictionary["retry"][langService.lang] }}
    </button>
  </div>
</div>
