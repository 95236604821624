import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'optionToText',
    standalone: false
})
export class OptionToTextPipe implements PipeTransform {
  transform(value: string, lang: string): string {
    const options: any = {
      APARTMAN: { hu: 'Apartman', en: 'Apartment' },
      ECONOMY: { hu: 'Economy szoba', en: 'Economy room' },
      STANDARD: { hu: 'Standard szoba', en: 'Standard room' },
      SUPERIOR: { hu: 'Superior szoba', en: 'Superior room' },
      JUNIOR_SUITE: { hu: 'Junior Suite (Kislakosztály)', en: 'Junior suite' },
      SUITE: { hu: 'Suite (Lakosztály)', en: 'Suite' },
      MOBILHAZ: { hu: 'Mobilház', en: 'Mobilehome' },
      UDULOHAZ: { hu: 'Üdülőház', en: 'Resort' },
      PARCELLA: { hu: 'Parcella', en: 'Plot' },
      SATORHELY_KEMPINGHELY: {
        hu: 'Sátorhely/ Kempinghely',
        en: 'Campsite',
      },
      EGYEDI: { hu: 'Egyedi (pl: jurta)', en: 'Custom' },
      PRIVAT_SZOBA_KOZOS_FURDOVEL: {
        hu: 'Privát szoba közös fürdővel (ifjúsági szálláshely)',
        en: 'Private room with shared bathroom (hostel)',
      },
      PRIVAT_SZOBA_SAJAT_FURDOVEL: {
        hu: 'Privát szoba saját fürdővel (ifjúsági szálláshely)',
        en: 'Private room with private bathroom (hostel)',
      },
      HALOTERMI_AGY: { hu: 'Hálótermi ágy', en: 'Dorm room' },
      EGYEB: { hu: 'Egyéb', en: 'Other' },
    };

    return options[value][lang] || value;
  }
}
