<div class="selection-wrapper">
  <div class="header">
    <h1>{{ langService.dictionary["rooms"][langService.lang] }}</h1>
    <p *ngIf="hotelService.selectedHotel"></p>
  </div>
  <div
    *ngFor="let group of roomService.rooms | groupBy : 'building'"
    class="room-group"
  >
    <h2>{{ group.key }}</h2>
    <div class="room-list" *ngIf="roomService.rooms">
      <div
        class="room-card"
        *ngFor="let room of group.value"
        [ngClass]="[
          selectedMenu !== roomMenu.Add ? 'selectable' : '',
          room === roomService.selectedRoom ? 'active' : ''
        ]"
        (click)="selectRoom(room)"
      >
        <div class="room-name">
          <span>{{ room.name }}</span>
        </div>
        <div class="room-type">
          <span> {{ room.type | optionToText : langService.lang }}</span>
        </div>
        <div class="amenities">
          <i
            *ngIf="room.air_conditioning"
            class="air-conditioner-icon"
            alt="air conditioned"
          ></i>
          <i
            *ngIf="room.wheelchair_accessible"
            class="wheelchair-icon"
            alt="wheelchair accessible"
          ></i>
        </div>
        <div class="prices">
          <div class="price">{{ room.default_price_huf }} <span>HUF</span></div>
          <!-- <div class="price">{{ room.default_price_eur }} <span>EUR</span></div> -->
        </div>
        <div class="beds">
          <div *ngIf="room.single_beds > 0" class="bed">
            <i class="single-bed-icon"></i>
            <span>{{ room.single_beds }}</span>
          </div>
          <div *ngIf="room.double_beds > 0" class="bed">
            <i class="double-bed-icon"></i>
            <span>{{ room.double_beds }}</span>
          </div>
          <div *ngIf="room.couch_beds > 0" class="bed">
            <i class="couch-bed-icon"></i>
            <span>{{ room.couch_beds }}</span>
          </div>
          <div *ngIf="room.extra_beds > 0" class="bed">
            <i class="extra-bed-icon"></i>
            <span>{{ room.extra_beds }}</span>
          </div>
        </div>
        <div class="occupancy-wrapper">
          <div class="default">
            <div class="occupancy">
              <i class="adult-icon"></i>
              <span>{{ room.max_adults }}</span>
            </div>
          </div>
          <div class="divider"></div>
          <div class="family">
            <div class="occupancy">
              <i class="adult-icon"></i>
              <span>{{ room.max_family_adults }}</span>
            </div>
            <div class="occupancy">
              <i class="child-icon"></i>
              <span>{{ room.max_family_children }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
