<div class="booking-arrival-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-header">
    <div class="button-wrapper">
      <button type="button" class="secondary-button" (click)="loadData()">
        <span *ngIf="!isLoading">{{
          langService.dictionary["scanned"][langService.lang]
        }}</span>
        <app-loading-spinner
          [loading]="isLoading"
          *ngIf="isLoading"
        ></app-loading-spinner>
      </button>
    </div>
  </div>
  <div class="action-panel">
    <div class="arrival-wrapper">
      <ng-container *ngFor="let guest of guests">
        <arrival-card
          #guestForms
          [guest]="guest"
          [roomId]="guest.room_id"
          [totalGuests]="guests.length"
          [checkToOpen]="checkToOpen"
          (isValid)="checkValidations()"
          (addGuest)="addSingleGuest($event)"
          (reloadGuests)="loadData()"
          *ngIf="selectedRoom?.room_id === guest.room_id || !guest.guest_id"
        ></arrival-card>
      </ng-container>
    </div>
  </div>
  <div class="action-footer">
    <div class="button-wrapper">
      <button
        type="submit"
        [disabled]="!isArrivable()"
        class="primary-button"
        (click)="arrive()"
      >
        <span *ngIf="!isLoading">{{
          langService.dictionary["check-in"][langService.lang]
        }}</span>
        <app-loading-spinner
          [loading]="isLoading"
          *ngIf="isLoading"
        ></app-loading-spinner>
      </button>
    </div>
  </div>

  <div class="selector-header"></div>
  <div class="selector-wrapper">
    <div class="room-panel-wrapper">
      <div
        *ngFor="let room of rooms"
        class="room-panel"
        [ngClass]="{
          selected: room.room_id === selectedRoom?.room_id,
          success: room.arrived
        }"
        (click)="selectRoom(room)"
      >
        <div class="room-name">
          <span>{{ roomService.getRoomName(room.room_id) }}</span>
        </div>

        <div class="status">
          <span>{{ room | bookingStatus : langService.lang }}</span>
        </div>

        <div *ngIf="!room.arrived" class="date-wrapper">
          <div class="date">
            <span>{{ room.arrival_date }}</span>
          </div>
          <span class="num-of-nights"
            >{{
              calendarService.calculateDaysDifference(
                room.arrival_date,
                room.departure_date
              )
            }}
            {{ langService.dictionary["night"][langService.lang] }}</span
          >

          <div class="date">
            <span>{{ room.departure_date }}</span>
          </div>
        </div>

        <span *ngIf="room.arrived" class="success-message">{{
          langService.dictionary["checked-in"][langService.lang]
        }}</span>

        <div class="details">
          <div class="occupancy-wrapper">
            <div class="occupancy-block">
              <div class="occupancy">
                <span>{{ room.adults }}</span>
              </div>
              <div>
                <i class="adult-icon"></i>
              </div>
            </div>
            <div class="occupancy-block">
              <div class="occupancy">
                <span>{{ room.children }}</span>
              </div>
              <div>
                <i class="child-icon"></i>
              </div>
            </div>
          </div>
          <div *ngIf="!room.arrived" class="amount-block">
            <input
              disabled
              class="amount"
              type="number"
              [(ngModel)]="room.gross_price"
            />
            <input type="text" class="huf-label" value="HUF" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="button-wrapper"></div>
</div>
