<div class="home-wrapper">
  <div class="home-header">
    <div class="name">
      {{ langService.dictionary["hello"][langService.lang] }}
      {{ userService?.user?.name }}!
    </div>
    <div class="date">{{ date }}</div>
  </div>

  <div class="conclude-wrapper">
    <button class="primary-button" (click)="concludeDay()">
      <span class="description">{{
        langService.dictionary["calendar-conclude-day"][langService.lang]
      }}</span
      ><br /><span class="date">{{
        hotelService?.selectedHotel?.last_concluded_day
      }}</span>
    </button>
    <div>
      <span>{{ remainingDays() }}</span>
      {{ langService.dictionary["days-left"][langService.lang] }}
    </div>
  </div>

  <div class="todo-wrapper">
    <div class="todo-header">
      <div class="todo-counter">
        {{ langService.dictionary["tasks"][langService.lang] }}
      </div>
      <div class="progress-bar">
        <div
          class="progress-fill"
          [ngStyle]="{
            width: !dashboardService.totalTasks
              ? 100
              : (dashboardService.completedTasks /
                  dashboardService.totalTasks) *
                  100 +
                '%'
          }"
        ></div>
      </div>
    </div>

    <div class="todo-body">
      <div class="todo-panel" [ngClass]="{ done: !dashboard?.arriving.length }">
        <div class="todo-panel-header">
          {{ langService.dictionary["check-in"][langService.lang] }}
          <i class="tick-icon" *ngIf="!dashboard?.arriving.length"></i>
        </div>
        <i class="tick-icon" *ngIf="!dashboard?.arriving.length"></i>
        <div class="todo-panel-body">
          <div
            class="booking-card selectable"
            *ngFor="let booking of dashboard?.arriving"
            (click)="navToArrival(booking)"
          >
            <div class="room-name">
              <span>{{ roomService.getRoomName(booking.room_id) }}</span>
            </div>
            <div class="room-details-wrapper">
              <div class="date">{{ booking.arrival_date }}</div>
              <div class="name-wrapper">
                {{ booking.contact_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="todo-panel" [ngClass]="{ done: !dashboard?.paying.length }">
        <div class="todo-panel-header">
          {{ langService.dictionary["payments"][langService.lang] }}
          <i class="tick-icon" *ngIf="!dashboard?.paying.length"></i>
        </div>
        <div class="todo-panel-body">
          <div
            class="booking-card extended"
            *ngFor="let booking of dashboard?.paying"
          >
            <div class="room-name">
              <span>100</span>
            </div>

            <div class="payment-details">
              <span class="tooltip">{{
                langService.dictionary["remaining"][langService.lang]
              }}</span>
              <div class="amount-block">
                <span>{{ booking.price }}</span
                ><span> Ft</span>
              </div>
            </div>
            <div class="payment-actions">
              <custom-select
                type="dropdown"
                class="form-dropdown-select"
                name="document_type"
                [required]="true"
                [label]="
                  langService.dictionary['payment-method'][langService.lang]
                "
                [options]="paymentMethods"
                [(ngModel)]="booking.payment_method"
              ></custom-select>
              <button
                class="primary-button"
                [disabled]="!booking.payment_method"
                (click)="payCharges(booking)"
              >
                {{ langService.dictionary["pay"][langService.lang] }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="todo-panel"
        [ngClass]="{ done: !dashboard?.departing.length }"
      >
        <div class="todo-panel-header">
          {{ langService.dictionary["check-out"][langService.lang] }}
          <i class="tick-icon" *ngIf="!dashboard?.departing.length"></i>
          <button
            *ngIf="dashboard?.departing.length"
            class="primary-button"
            (click)="departAllRooms()"
          >
            {{ langService.dictionary["check-out"][langService.lang] }}
          </button>
        </div>
        <div class="todo-panel-body">
          <div
            class="booking-card"
            *ngFor="let booking of dashboard?.departing"
            [ngClass]="{ disabled: booking.unpaid || !booking.arrived }"
          >
            <div class="room-name">
              <span>{{ booking.room_id }}</span>
            </div>
            <div class="room-details-wrapper">
              <div class="date">{{ booking.departure_date }}</div>
              <button
                [disabled]="booking.unpaid || !booking.arrived"
                class="secondary-button"
                (click)="departRoom(booking)"
              >
                {{ langService.dictionary["check-out"][langService.lang] }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="info-banner">
    {{ langService.dictionary["info-banner"][langService.lang] }}
  </div>
</div>
