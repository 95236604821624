<div
  class="custom-input-wrapper"
  [ngClass]="{
    focused: value || value === 0,
    highlighted: highlighted && !customInput.touched && !_value
  }"
>
  <input
    #customInput="ngModel"
    [disabled]="disabled"
    matInput
    name="customInput"
    [required]="required"
    [maxlength]="maxlength"
    [minlength]="minlength"
    [placeholder]="placeholder"
    [pattern]="pattern"
    [type]="type"
    [(ngModel)]="value"
    (ngModelChange)="sendChange()"
    (focus)="focusService.focusButton($event)"
    (focusout)="focusService.focusOutButton($event)"
  />
  <span class="input-label">{{ label }}</span>
</div>
