<div
  class="room-booking"
  [ngStyle]="{
    width: width,
    left: offSet
  }"
  [ngClass]="{
    faded:
      bookingService?.selectedBooking &&
      bookingService?.selectedBooking?.booking_id !== bookingView.bookingId
  }"
  (click)="selectBooking($event)"
>
  <div class="name">
    {{ bookingView.name }}
  </div>
  <div class="meta-wrapper">
    <div class="source-img">
      <img *ngIf="getBookingSource() === 'airbnb.com'" src="assets/airbnb-com.png">
      <img *ngIf="getBookingSource() === 'booking.com'" src="assets/booking-com.png">
      <img *ngIf="getBookingSource() === null" src="assets/kisportas-hu.png">
      <img *ngIf="getBookingSource() === 'szallas.hu'" src="assets/szallas-hu.png">
    </div>
    <div class="status-wrapper">
      <div class="status" [ngClass]="getBookingStatus()"></div>
    </div>
  </div>
  <div class="occupancy">
    <span class="emph">{{ bookingView.adults }}</span
    ><span class="sub">{{
      langService.dictionary["adult-short"][langService.lang]
    }}</span
    ><span class="emph">{{ bookingView.children }}</span>
    <span class="sub">{{
      langService.dictionary["child-short"][langService.lang]
    }}</span>
  </div>
</div>
