import { Component } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { ConfigService } from 'src/app/@services';

@Component({
    selector: 'server-selector',
    templateUrl: './server-select.dialog.html',
    styleUrls: ['./server-select.dialog.scss'],
    standalone: false
})
export class ServerSelectorDialog {
  constructor(private configService: ConfigService) {}

  protected readonly serverOptions = [
    { value: '/', label: { hu: 'local', en: 'local' } },
    { value: 'https://dev.app.kisportas.hu/', label: { hu: 'dev', en: 'dev' } },
    { value: 'https://app.kisportas.hu/', label: { hu: 'prod', en: 'prod' } },
  ];

  protected selectedServer: string;

  protected setSelectedServer(): void {
    this.configService.server = this.selectedServer;
    Device.getInfo().then((info: DeviceInfo) => {
      /*if (info.operatingSystem === 'ios' && this.selectedServer === 'prod')
        window.location.href = 'https://app.kisportas.hu';

      if (info.operatingSystem === 'ios' && this.selectedServer === 'dev')
        window.location.href = 'https://dev.app.kisportas.hu';*/
    });
  }
}
