import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { LanguageService } from 'src/app/@services';

@Component({
    selector: 'successful',
    templateUrl: 'successful.snackbar.html',
    styleUrls: ['successful.snackbar.scss'],
    standalone: false
})
export class SuccessfulSnackBar {
  constructor(
    public snackBarRef: MatSnackBarRef<SuccessfulSnackBar>,
    public langService: LanguageService
  ) {}

  public closeSnackBar(): void {
    this.snackBarRef.dismiss();
  }
}
