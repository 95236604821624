<div class="booking-rooms-payment-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-header">
    <button
      class="secondary-button"
      type="button"
      (click)="openInvoiceDialog()"
    >
      + {{ langService.dictionary["new-invoice"][langService.lang] }}
    </button>
  </div>

  <div class="action-panel">
    <div class="panel-wrapper">
      <div class="action-panel-header">
        <div class="paid-cost">
          <span class="description">{{
            langService.dictionary["paid"][langService.lang]
          }}</span
          ><span class="cost">{{ paidAmount }} Ft</span>
        </div>
        <div class="room" *ngIf="!areAllRoomsSelected && selectedRoom?.room_id">
          {{ roomService.getRoomName(selectedRoom?.room_id) }}
        </div>
        <div class="room" *ngIf="areAllRoomsSelected">
          {{ langService.dictionary["all-rooms"][langService.lang] }}
        </div>
        <div class="remaining-cost">
          <span class="description">{{
            langService.dictionary["remaining"][langService.lang]
          }}</span
          ><span class="cost">{{ remainingAmount }} Ft</span>
        </div>
        <div class="total-cost">
          <span class="description">{{
            langService.dictionary["total"][langService.lang]
          }}</span
          ><span class="cost">{{ totalPrice }} Ft</span>
        </div>
        <div class="group-action-panel">
          <span>{{
            langService.dictionary["group-actions"][langService.lang]
          }}</span>
          <form
            class="group-actions"
            #groupForm="ngForm"
            (ngSubmit)="payAllCharges()"
          >
            <custom-select
              id="group_payment_method"
              name="group_payment_method"
              [required]="true"
              [(ngModel)]="groupPayment.payment_method"
              [options]="paymentOptions"
              (ngModelChange)="newReference()"
            ></custom-select>
            <button
              class="secondary-button"
              [disabled]="groupForm.invalid"
              type="submit"
            >
              {{ langService.dictionary["pay-all"][langService.lang] }}
            </button>
            <div class="form-textbox">
              <date-picker
                [(ngModel)]="groupPayment.payment_date"
                (ngModelChange)="newReference()"
                name="group_payment_date"
                [required]="true"
                [label]="
                  langService.dictionary['payment-date'][langService.lang] + '*'
                "
                [isDateString]="true"
              ></date-picker>
            </div>
          </form>
        </div>
      </div>

      <div class="charges-wrapper">
        <ng-container *ngFor="let charge of charges">
          <charge-card
            *ngIf="
              !charge.charge_id || charge.room_id === selectedRoom?.room_id
            "
            [charge]="charge"
            [groupPayment]="groupPayment"
            (refreshData)="refreshData()"
          ></charge-card>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="action-footer">
    <button class="secondary-button" (click)="addCharge()">
      + {{ langService.dictionary["new-charge"][langService.lang] }}
    </button>
  </div>

  <div class="selector-header">
    <button
      class="secondary-button"
      (click)="selectAllRooms()"
      *ngIf="!areAllRoomsSelected"
    >
      {{ langService.dictionary["select-all"][langService.lang] }}
    </button>
    <button
      class="secondary-button"
      (click)="deselectAllRooms()"
      *ngIf="areAllRoomsSelected"
    >
      {{ langService.dictionary["cancel"][langService.lang] }}
    </button>
  </div>

  <div class="selector-wrapper">
    <div class="room-panel-wrapper">
      <div
        *ngFor="let room of rooms"
        class="room-panel"
        [ngClass]="{
          success: room.paid,
          selected: room.room_id === selectedRoom?.room_id || areAllRoomsSelected,
        }"
        (click)="selectRoom(room)"
      >
        <div class="room-name">
          <span>{{ roomService.getRoomName(room.room_id) }}</span>
        </div>

        <div class="details">
          <span class="tooltip" *ngIf="room.paid">{{
            langService.dictionary["paid"][langService.lang]
          }}</span>
          <span class="tooltip" *ngIf="!room.paid">{{
            langService.dictionary["remaining"][langService.lang]
          }}</span>
          <div class="amount-block" *ngIf="!room.paid">
            <span>{{ room.remainingAmount }}</span
            ><span> Ft</span>
          </div>
        </div>
        <div class="occupancy-wrapper">
          <div class="occupancy-block">
            <div class="occupancy">
              <span>
                {{ room.adults }}
              </span>
            </div>
            <div>
              <i class="adult-icon"></i>
            </div>
          </div>
          <div class="occupancy-block">
            <div class="occupancy">
              <span>
                {{ room.children }}
              </span>
            </div>
            <div>
              <i class="child-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="selector-footer"></div>
</div>
