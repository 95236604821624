import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeHu from '@angular/common/locales/hu';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QRCodeComponent } from 'angularx-qrcode';
import { componentCards } from './@components/@cards/components';
import { componentsCommon } from './@components/@common/components';
import { componentPages } from './@components/@pages/components';
import { components } from './@components/components';
import { componentDialogs } from './@dialogs/components';
import { directives } from './@directives/directives';
import { pipes } from './@pipes/pipes';
import {
  AuthInterceptor,
  AuthService,
  BookingService,
  CalendarService,
  ChargeService,
  CheckInService,
  ConfigLoaderService,
  ConfigService,
  DashboardService,
  DateService,
  FocusService,
  HolidayService,
  HotelService,
  LanguageService,
  RoomService,
  RouterService,
  ScannerService,
  ServiceService,
  SnackbarService,
  TableService,
  UserService,
} from './@services';
import { snackBars } from './@snackbars/snackbars';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeHu);

@NgModule({
  declarations: [
    AppComponent,
    ...componentCards,
    ...componentDialogs,
    ...componentPages,
    ...components,
    ...componentsCommon,
    ...directives,
    ...pipes,
    ...snackBars,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    QRCodeComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    { provide: LOCALE_ID, useValue: 'hu' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ConfigLoaderService,
    {
      provide: APP_INITIALIZER,
      deps: [ConfigLoaderService],
      multi: true,
      useFactory: (
        config: ConfigLoaderService<{
          [key: string]: any;
        }>
      ) => {
        return () => config.initialize();
      },
    },
    AuthService,
    BookingService,
    CalendarService,
    ChargeService,
    CheckInService,
    ConfigService,
    DateService,
    DashboardService,
    FocusService,
    HolidayService,
    HotelService,
    RoomService,
    RouterService,
    ScannerService,
    ServiceService,
    SnackbarService,
    TableService,
    UserService,
    LanguageService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
