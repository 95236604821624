import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckInService, LanguageService } from 'src/app/@services';

@Component({
  selector: 'check-in-email',
  templateUrl: './check-in-email.dialog.html',
  styleUrls: ['./check-in-email.dialog.scss'],
  standalone: false,
})
export class CheckInEmailDialog implements OnInit {
  constructor(
    private matDialogRef: MatDialogRef<CheckInEmailDialog>,
    private checkInService: CheckInService,
    public langService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public prefill: any
  ) {}

  public name: string;
  public email: string;
  public language: string;
  protected readonly langOptions = [
    {
      value: 'HU',
      label: {
        hu: 'Magyar',
        en: 'Hungarian',
      },
    },
    {
      value: 'EN',
      label: {
        hu: 'Angol',
        en: 'English',
      },
    },
  ];

  ngOnInit(): void {
    this.name = this.prefill.name;
    this.email = this.prefill.email;
    this.language = this.prefill.language;
  }

  public sendEmail(): void {
    const details = {
      name: this.name,
      email: this.email,
      language: this.language,
    };
    this.checkInService
      .sendOnlineCheckIn(details, this.prefill.bookingId)
      .then(() => {
        this.matDialogRef.close();
      })
      .catch(() => {
        console.log('send online check-in err');
      });
  }
  public closePanel(): void {
    this.matDialogRef.close();
  }
}
