import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CheckInService,
  GuestService,
  LanguageService,
  RouterService,
} from 'src/app/@services';

@Component({
    selector: 'check-in-login',
    templateUrl: './check-in-login.component.html',
    styleUrls: ['./check-in-login.component.scss'],
    standalone: false
})
export class CheckInLoginComponent implements OnInit {
  constructor(
    private routerService: RouterService,
    private checkInService: CheckInService,
    public langService: LanguageService,
    private guestService: GuestService,
    private route: ActivatedRoute
  ) {}

  protected code: string;
  protected error: boolean;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!params['uuid']) return;

      this.code = params['uuid'];
      this.checkCode();
    });
  }

  protected checkCode(): void {
    this.checkInService
      .getCheckInDetails(this.code)
      .then(() => {
        this.checkInService.checkInId = this.code;
        this.routerService.absoluteNavigation('self-check-in');
        this.guestService.isSelfCheckIn = true;
      })
      .catch(() => {
        this.error = true;
        console.log('no check in found');
      });
  }
}
