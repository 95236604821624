import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponse } from '../@Interfaces';
import { GuestInterface } from '../@Interfaces/guest.interface';
import { BookingService } from './booking.service';
import { ConfigService } from './config.service';
import { HotelService } from './hotel.service';
import { RoomService } from './room.service';

@Injectable({
  providedIn: 'root',
})
export class GuestService {
  constructor(
    private http: HttpClient,
    private hotelService: HotelService,
    private bookingService: BookingService,
    private configService: ConfigService,
    private roomService: RoomService
  ) {}

  public readQrCode: any;
  public totalGuestsToScan: number;
  public guestsScanned: number;
  public isSelfCheckIn: boolean;
  public enableManualScan: boolean;

  /* <-- API CALLS --> */

  public async getGuests(
    hotelId: string | number = null,
    bookingId: string | number = null
  ): Promise<GuestInterface[]> {
    const hotel_id = hotelId ?? this.hotelService.selectedHotelId;
    const booking_id =
      bookingId ?? this.bookingService?.selectedBooking?.booking_id;

    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse<GuestInterface[]>>(
          `${this.configService.server}api/hotels/${hotel_id}/bookings/${booking_id}/guests`
        )
        .subscribe({
          next: (res: IResponse<GuestInterface[]>) => {
            resolve(res.data);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async addGuests(
    guests: GuestInterface[],
    roomId: number
  ): Promise<GuestInterface[]> {
    const reqBody = {
      room_id: roomId,
      guests: guests,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post<IResponse<GuestInterface[]>>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/guests`,
          reqBody
        )
        .subscribe({
          next: (res: IResponse<GuestInterface[]>) => {
            resolve(res.data);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async arrive(roomId: number): Promise<GuestInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post<IResponse<GuestInterface[]>>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/rooms/${roomId}/arrive`,
          {}
        )
        .subscribe({
          next: (res: IResponse<GuestInterface[]>) => {
            resolve(res.data);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async editGuest(guest: GuestInterface): Promise<GuestInterface> {
    const requestBody = structuredClone(guest);
    delete requestBody.birth_date_og;
    delete requestBody.gender_og;
    delete requestBody.last_name_og;
    delete requestBody.first_name_og;
    delete requestBody.nationality_og;
    delete requestBody.document_type_og;
    delete requestBody.document_number_og;
    delete requestBody.room_id;
    delete requestBody.guest_arrival_id;
    return new Promise((resolve, reject) => {
      this.http
        .put<IResponse<GuestInterface>>(
          `${this.configService.server}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/guests`,
          requestBody
        )
        .subscribe({
          next: (res: IResponse<GuestInterface>) => {
            resolve(res.data);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async sendScan(ids: any, scan: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const bookingId =
        ids?.bookingId ?? this.bookingService?.selectedBooking?.booking_id;
      const hotelId = ids?.hotelId ?? this.hotelService?.selectedHotelId;
      const roomId = ids?.roomId ?? this.roomService?.selectedRoom?.room_id;
      this.http
        .post<IResponse>(
          `${this.configService.server}api/hotels/${hotelId}/bookings/${bookingId}/rooms/${roomId}/scan`,
          scan
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async getScansNeeded(
    hotelId: number = null,
    bookingId: number = null,
    roomId: number = null
  ): Promise<any> {
    const booking_Id =
      bookingId ?? this.bookingService?.selectedBooking?.booking_id;
    const hotel_Id = hotelId ?? this.hotelService?.selectedHotelId;
    const room_Id = roomId ?? this.roomService?.selectedRoom?.room_id;
    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse>(
          `${this.configService.server}api/hotels/${hotel_Id}/bookings/${booking_Id}/rooms/${room_Id}/scan`
        )
        .subscribe({
          next: (res) => {
            resolve(res?.data);
          },
          error: () => {
            reject();
          },
        });
    });
  }
  /* <-- API CALLS --> */
}
