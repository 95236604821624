import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { CheckInEmailDialog } from 'src/app/@dialogs';
import {
  BookingInterface,
  BookingView,
  ExtendedBookingRoom,
  RoomInterface,
} from 'src/app/@Interfaces';
import {
  HotelService,
  LanguageService,
  RouterService,
} from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { DateService } from 'src/app/@services/date.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { SidePanelTypeEnum } from 'src/app/enums';

@Component({
  selector: 'booking-new-rooms',
  templateUrl: './booking-new-rooms.component.html',
  styleUrls: ['./booking-new-rooms.component.scss'],
  standalone: false,
})
export class BookingNewRoomsComponent implements OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    public tableService: TableService,
    public dateService: DateService,
    private routerService: RouterService,
    private hotelService: HotelService,
    public langService: LanguageService,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  public selectedLanguage: string;
  public bookingChanel: string;
  public _sidePanelType: SidePanelTypeEnum;
  public _isEditing: boolean;
  public isLoading: boolean;
  public roomForms: FormGroup<any> = this.fb.group({
    room: this.fb.array([], Validators.required),
  });
  protected groupArrivalDate: string;
  protected groupDepartureDate: string;
  public rooms: ExtendedBookingRoom[] = [];

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public inputStartDate: any;
  public inputEndDate: any;
  public contactName: string;
  public people: number;

  get roomForm(): FormArray {
    return this.roomForms.get('room') as FormArray;
  }

  private addRoomForm(): void {
    const roomGroup = this.fb.group({
      room_id: ['', [Validators.required, this.noFalsyValidator()]],
      arrival_date: [null, [Validators.required]],
      departure_date: ['', [Validators.required]],
      adults: ['', [Validators.required]],
      children: ['', [Validators.required]],
      gross_price: [{ value: '' }],
      plus_tax: [''],
      prepayment: [''],
    });
    this.roomForm.push(roomGroup);
  }

  private noFalsyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? null : { falsy: true };
    };
  }

  protected removeRoom(roomId: any, index: number): void {
    this.rooms = this.rooms.filter((room) => room.room_id !== roomId);
    this.removeRoomForm(index);
  }

  public getRoomGroup(index: number) {
    return this.roomForm.at(index) as FormGroup;
  }

  public removeRoomForm(index: number) {
    this.roomForm.removeAt(index);
  }

  public closeBooking(): void {
    this.routerService.absoluteNavigationNoParamPreserve('calendar', {
      hotel: this.hotelService.selectedHotelId,
    });
    //timeout for animation to finish
    setTimeout(() => {
      this.bookingChanel = null;

      this.calendarService.removeAvailableDayClasses();
      this.selectedLanguage = null;
    }, 1000);
  }

  public postBooking(): void {
    this.isLoading = true;

    this.bookingService
      .postNewBooking(this.roomForms.getRawValue()?.room)
      .then((bookingId: number) => {
        this.calendarService.removeAvailableDayClasses();
        this.tableService.makeNewRoomsPermanent(bookingId);
        this.bookingService
          .getBookings()
          .then(() => {
            this.isLoading = false;
            const booking: BookingInterface = this.bookingService.bookings.find(
              (booking) => booking.booking_id === bookingId
            );

            if (this.hotelService.selectedHotel.online_check_in) {
              const data = {
                name: this.bookingService.bookingData.contact_name,
                email: this.bookingService.bookingData.contact_email,
                language: this.bookingService.bookingData.language,
                bookingId: bookingId,
              };
              this.matDialog.open(CheckInEmailDialog, { data: data });
            }

            this.routerService.addQueryParams({
              booking: bookingId,
              room: booking.room_id,
            });

            this.routerService.absoluteNavigation('calendar/payment');
          })
          .catch(() => {});
      })
      .catch(() => {
        console.log('catch');

        this.isLoading = false;
      });
  }

  public addRoom(): void {
    const room: ExtendedBookingRoom = {
      adults: 0,
      arrival_date: this.dateService.formatDate(new Date()),
      arrived: false,
      children: 0,
      contact_name: this.bookingService.bookingData?.contact_name ?? null,
      departed: false,
      departure_date: this.dateService.addDayToStringDate(
        this.dateService.formatDate(new Date()),
        1
      ),
      gross_price: 0,
      plus_tax: false,
      paid: false,
      room_id: 0,
      newRoom: true,
    };
    this.addRoomForm();
    this.rooms.push(room);

    //cdr needed for angular ExpressionChangedAfterItHasBeenCheckedError
    this.cdr.detectChanges();
  }

  public changeRoom(newId: number, index: number): void {
    const roomDetails = this.roomService.rooms.find(
      (room: RoomInterface) => room.room_id === newId
    );
    console.log('old room data ', this.rooms[index]);

    const room: ExtendedBookingRoom = {
      adults: this.rooms[index].adults || roomDetails.max_adults || 0,
      arrival_date: this.dateService.formatDate(new Date()),
      arrived: false,
      children: this.rooms[index].children || 0,
      contact_name: this.rooms[index].contact_name,
      departed: false,
      departure_date: this.dateService.addDayToStringDate(
        this.dateService.formatDate(new Date()),
        1
      ),
      gross_price:
        this.rooms[index].gross_price || roomDetails.default_price_huf || 0,
      plus_tax: false,
      paid: false,
      room_id: newId,
      newRoom: this.rooms[index].newRoom,
      booking_id: null,
    };
    this.rooms[index] = room;
    const bookingView: BookingView = {
      adults: room.adults,
      arrDate: room.arrival_date,
      bookingId: room.booking_id,
      children: room.children,
      depDate: room.departure_date,
      name: room.contact_name,
      roomId: newId,
      reversed: false,
      newRoom: true,
    };

    this.tableService.addRoom(bookingView);
    this.cdr.detectChanges(); //NG0100
  }

  public getSelectableRoomIds(roomId: string): string[] {
    const roomIds = this.roomService.rooms.map((room) =>
      room.room_id.toString()
    );
    const bookedRoomIds = Object.keys(this.bookingService.sidePanelRooms);
    const roomIdsToLoop = roomIds.filter(
      (roomId) => !bookedRoomIds.includes(roomId.toString())
    );
    if (roomId !== null) {
      roomIdsToLoop.push(roomId);
    }
    return roomIdsToLoop;
  }

  public setGroupArrivalDate(date: string): void {
    for (let key in this.bookingService.sidePanelRooms) {
      if (!this.bookingService.sidePanelRooms[key].room_id) return;

      this.bookingService.sidePanelRooms[key].arrival_date = date;
      if (!this.bookingService.sidePanelRooms[key].departure_date) {
        this.bookingService.sidePanelRooms[key].departure_date =
          this.dateService.addDayToStringDate(date, 1);

        this.groupDepartureDate =
          this.bookingService.sidePanelRooms[key].departure_date;
      }
    }
  }

  public setGroupDepartureDate(date: string): void {
    for (let key in this.bookingService.sidePanelRooms) {
      if (!this.bookingService.sidePanelRooms[key].room_id) return;
      this.bookingService.sidePanelRooms[key].departure_date = date;
      if (!this.bookingService.sidePanelRooms[key].arrival_date) {
        this.bookingService.sidePanelRooms[key].arrival_date =
          this.dateService.addDayToStringDate(date, -1);
        this.groupArrivalDate =
          this.bookingService.sidePanelRooms[key].arrival_date;
      }
    }
  }

  ngOnDestroy(): void {}
}
