import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BookingInterface, IResponse, RoomInterface } from '../@Interfaces';
import { ConfigService } from './config.service';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  constructor(
    private http: HttpClient,
    private dateService: DateService,
    private configService: ConfigService
  ) {}

  public rooms: RoomInterface[] = [];
  public selectedRoom: RoomInterface;
  public selectedRoom$: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  public roomOOO: any = [];
  public roomOOS: any = [];
  public bookingOOO: any = [];
  public bookingOOS: any = [];
  public loadingRooms: boolean;

  private selectedHotelId: string;
  private preselectRoom: string;

  /* <--API CALLS--> */

  public getRooms(hotelId: string = null): void {
    this.loadingRooms = true;
    const hotel_id = hotelId ?? this.selectedHotelId;
    this.selectedHotelId = hotelId;
    this.http
      .get<IResponse<RoomInterface[]>>(
        `${this.configService.server}api/hotels/${hotel_id}/rooms`
      )
      .subscribe({
        next: (res: IResponse<RoomInterface[]>) => {
          this.rooms = res.data;
          if (this.preselectRoom) {
            this.selectRoom(this.preselectRoom);
            this.preselectRoom = null;
          }
          this.loadingRooms = false;
        },
        error: () => {
          this.loadingRooms = false;
          //todo handle error
        },
      });
  }

  public newRoom(form: any): Promise<void> {
    if (form.hotel_id) delete form.hotel_id;
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<IResponse<RoomInterface[]>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/rooms`,
          form
        )
        .subscribe({
          next: (res: IResponse<RoomInterface[]>) => {
            this.rooms = res.data;
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public editRoom(form: any, roomId: string): Promise<void> {
    if (form.hotel_id) delete form.hotel_id;
    return new Promise<void>((resolve, reject) => {
      this.localUpdateRoom(form, roomId);
      this.http
        .put<IResponse<RoomInterface[]>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/rooms/${roomId}`,
          form
        )
        .subscribe({
          next: (res: IResponse<RoomInterface[]>) => {
            this.rooms = res.data;
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public getRoomOOO(roomId: number | string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse<any>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/ooo?room_id=${roomId}`
        )
        .subscribe({
          next: (res: IResponse<any>) => {
            this.roomOOO = res.data;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public getRoomOOS(roomId: number | string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse<any>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/oos?room_id=${roomId}`
        )
        .subscribe({
          next: (res: IResponse<any>) => {
            this.roomOOS = res.data;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public createRoomOOO(body: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<IResponse<any>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/ooo`,
          body
        )
        .subscribe({
          next: (res: IResponse<any>) => {
            this.roomOOO = res.data;
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  public createRoomOOS(body: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<IResponse<any>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/oos`,
          body
        )
        .subscribe({
          next: (res: IResponse<any>) => {
            this.roomOOS = res.data;
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  public deleteRoomOOO(oooId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete<IResponse<any>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/ooo/${oooId}`
        )
        .subscribe({
          next: (res: IResponse<any>) => {
            this.roomOOO = res.data;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public deleteRoomOOS(oosId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete<IResponse<any>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/oos/${oosId}`
        )
        .subscribe({
          next: (res: IResponse<any>) => {
            this.roomOOS = res.data;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public getBookingOOO(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse<any>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/ooo`
        )
        .subscribe({
          next: (res: IResponse<any>) => {
            this.bookingOOO = res.data;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public getBookingOOS(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get<IResponse<any>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/oos`
        )
        .subscribe({
          next: (res: IResponse<any>) => {
            this.bookingOOS = res.data;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  /* <--API CALLS--> */

  /* <--HELPER FUNCTIONS--> */

  public selectRoom(roomId: string): void {
    if (!this.rooms.length) {
      this.preselectRoom = roomId;
      return;
    }
    this.selectedRoom = this.rooms.find(
      (room: RoomInterface) => room.room_id === Number(roomId)
    );
    this.selectedRoom$.next();
  }

  public isRoomAvailable(
    date: Date,
    roomId: number,
    bookings: BookingInterface[],
    bookingId: number
  ): boolean {
    for (const booking of bookings) {
      if (booking.room_id === roomId && booking.booking_id !== bookingId) {
        const arrivalDate = new Date(booking.arrival_date).getTime();
        const departureDate = new Date(booking.departure_date).getTime();
        const questionedDate = date.getTime();

        if (arrivalDate <= questionedDate && questionedDate <= departureDate) {
          return false;
        }
      }
    }
    if (this.isDateInOOO(date, roomId)) {
      return false;
    }
    return true;
  }

  public areRoomsAvailable(
    date: Date,
    roomId: string[],
    bookings: BookingInterface[]
  ): boolean {
    for (const booking of bookings) {
      if (roomId.includes(booking.room_id.toString())) {
        const arrivalDate = new Date(booking.arrival_date).getTime();
        const departureDate = new Date(booking.departure_date).getTime();
        const questionedDate = date.getTime();

        if (arrivalDate <= questionedDate && questionedDate <= departureDate) {
          return false;
        }
      }
    }
    if (this.roomsIsDateInOOO(date, roomId)) {
      return false;
    }
    return true;
  }

  public getRoomName(roomId: string | number): string {
    if (!roomId) return 'Room Not Found';
    const roomIdNumber = parseInt(roomId.toString(), 10);
    const room = this.rooms.find((room) => room.room_id === roomIdNumber);

    return room ? room.name : 'Room Not Found';
  }

  public isDateInOOO(date: Date, roomId: number): boolean {
    const searchDate = this.dateService.formatDate(date);

    const isDateOOO: boolean = this.bookingOOO.some(
      (ooo: any) =>
        new Date(ooo.start_date).getTime() <= new Date(searchDate).getTime() &&
        new Date(searchDate).getTime() <= new Date(ooo.end_date).getTime() &&
        roomId === ooo.room_id
    );
    return isDateOOO;
  }

  public roomsIsDateInOOO(date: Date, roomId: string[]): boolean {
    const searchDate = this.dateService.formatDate(date);

    const isDateOOO: boolean = this.bookingOOO.some(
      (ooo: any) =>
        new Date(ooo.start_date).getTime() <= new Date(searchDate).getTime() &&
        new Date(searchDate).getTime() <= new Date(ooo.end_date).getTime() &&
        roomId.includes(ooo.room_id.toString())
    );
    return isDateOOO;
  }

  public isDateInOOS(date: Date, roomId: number): boolean {
    const searchDate = this.dateService.formatDate(date);
    const isDateOOS: boolean = this.bookingOOS.some(
      (oos: any) =>
        new Date(oos.start_date).getTime() <= new Date(searchDate).getTime() &&
        new Date(searchDate).getTime() <= new Date(oos.end_date).getTime() &&
        roomId === oos.room_id
    );
    return isDateOOS;
  }

  private localUpdateRoom(updatedRoom: RoomInterface, roomId: string): void {
    const roomIndex = this.rooms.findIndex(
      (room) => room.room_id === Number(roomId)
    );
    if (roomIndex !== -1) {
      this.rooms[roomIndex] = Object.assign(
        {},
        this.rooms[roomIndex],
        updatedRoom
      );
      console.log('Room updated successfully.');
    } else {
      console.log('Room with the provided room_id not found.');
    }
  }

  /* <--HELPER FUNCTIONS--> */
}
