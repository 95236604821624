<div class="arrival-card-wrapper">
  <!-- <div *ngIf="needsScanning" class="scan-overlay">
    <h2 *ngIf="!isMobile">
      {{ langService.dictionary["scan-instructions"][langService.lang] }}
    </h2>
    <qrcode
      *ngIf="!isMobile"
      [qrdata]="qrData"
      [errorCorrectionLevel]="'M'"
    ></qrcode>
    <button *ngIf="isMobile" class="primary-button" (click)="openScanner()">
      Scan
    </button>
    <p>{{ langService.dictionary["or"][langService.lang] }}</p>
    <button class="primary-button" (click)="skipScanning()">
    </button>
  </div> -->
  <div class="arrival-card">
    <div class="arrival-card-overview">
      <div class="name-wrapper">
        <div class="name" *ngIf="!guest.first_name">
          {{ guest.default_name }}
        </div>
        <div class="name" *ngIf="guest.first_name">
          {{ getControl("last_name").value }}
          {{ getControl("first_name").value }}
        </div>
        <div class="progress-wrapper">
          <span> {{ status }}</span>
          <!-- <span>
            {{
              langService.dictionary["check-in-needed"][langService.lang]
            }}</span
          >
          <span> {{ langService.dictionary["done"][langService.lang] }}</span> -->
          <div class="progress-bar">
            <div class="progress-bar-fill" [ngStyle]="progressStyle"></div>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button
          class="primary-button"
          *ngIf="guest.document_number_og && !guest.guest_id"
          (click)="openDetails()"
        >
          <i class="arrive-icon"></i>
          {{ langService.dictionary["check-in"][langService.lang] }}
        </button>
        <button
          class="primary-button"
          (click)="openScan()"
          *ngIf="!guest.document_number_og"
        >
          <i class="scan-icon"></i>
          {{ langService.dictionary["scan"][langService.lang] }}
        </button>
        <button
          class="primary-button"
          (click)="openDetails()"
          *ngIf="!isNative && !guest.document_number_og"
        >
          <i class="scan-icon"></i>
          {{ langService.dictionary["manual-scan"][langService.lang] }}
        </button>
        <button
          class="primary-button"
          *ngIf="guest.document_number_og && guest.guest_id"
          (click)="openDetails()"
        >
          <i class="modify-icon"></i>
          {{ langService.dictionary["modify"][langService.lang] }}
        </button>
      </div>
    </div>
    <form
      (ngSubmit)="addGuest()"
      [formGroup]="guestForm"
      [ngClass]="{ opened: opened }"
    >
      <div class="guest-card">
        <div class="row-wrapper">
          <custom-input
            #last_name
            [type]="'text'"
            [required]="true"
            [formControl]="getControl('last_name')"
            [label]="
              langService.dictionary['last-name'][langService.lang] + '*'
            "
            [highlighted]="true"
          />
          <custom-input
            [type]="'text'"
            [required]="true"
            [highlighted]="true"
            [formControl]="getControl('first_name')"
            [label]="
              langService.dictionary['first-name'][langService.lang] + '*'
            "
          />
          <custom-select
            type="dropdown"
            class="form-dropdown-select"
            name="document_type"
            [required]="true"
            [highlighted]="true"
            [formControl]="getControl('document_type')"
            (change)="checkU14()"
            [label]="
              langService.dictionary['identification-document'][
                langService.lang
              ] + '*'
            "
            [options]="documentTypeOptions"
          />
          <custom-input
            [type]="'text'"
            [formControl]="getControl('document_number')"
            [disabled]="guest.document_type === 'U14'"
            [required]="true"
            [highlighted]="true"
            [label]="
              langService.dictionary['identification-number'][
                langService.lang
              ] + '*'
            "
          ></custom-input>
        </div>

        <div class="row-wrapper">
          <custom-select
            type="dropdown"
            class="form-dropdown-select"
            [required]="true"
            [highlighted]="true"
            [formControl]="getControl('country_of_residence')"
            [label]="
              langService.dictionary['country-of-residence'][langService.lang] +
              '*'
            "
            [options]="countryOfResidenceOptions"
          />
          <custom-input
            [type]="'text'"
            [formControl]="getControl('postcode_of_residence')"
            [required]="true"
            [highlighted]="true"
            [label]="
              langService.dictionary['postcode-of-residence'][
                langService.lang
              ] + '*'
            "
          />
          <custom-select
            type="dropdown"
            class="form-dropdown-select"
            [required]="true"
            [highlighted]="true"
            [formControl]="getControl('nationality')"
            [label]="
              langService.dictionary['nationality'][langService.lang] + '*'
            "
            [options]="nationalityOptions"
          />

          <custom-select
            type="dropdown"
            class="form-dropdown-select"
            [required]="true"
            [highlighted]="true"
            [formControl]="getControl('gender')"
            [label]="langService.dictionary['gender'][langService.lang] + '*'"
            [options]="genderOptions"
          />
        </div>
        <div class="row-wrapper">
          <custom-input
            [type]="'text'"
            [required]="true"
            [highlighted]="true"
            [formControl]="getControl('birth_place')"
            [label]="
              langService.dictionary['birthplace'][langService.lang] + '*'
            "
          />
          <date-picker
            [formControl]="getControl('birth_date')"
            (ngModelChange)="setTaxExempt($event)"
            [isDateString]="true"
            [required]="true"
            [highlighted]="true"
            [label]="
              langService.dictionary['birthdate'][langService.lang] + '*'
            "
          />
          <custom-checkbox
            *ngIf="!guestService.isSelfCheckIn"
            type="checkbox"
            (focus)="focusService.focusButton($event)"
            (focusout)="focusService.focusOutButton($event)"
            [formControl]="getControl('tax_exempt')"
            [label]="langService.dictionary['tax-exempt'][langService.lang]"
            (ngModelChange)="resetTaxReason($event)"
          />
          <custom-select
            type="dropdown"
            class="form-dropdown-select"
            [required]="true"
            [highlighted]="true"
            *ngIf="
              getControl('tax_exempt').value && !guestService.isSelfCheckIn
            "
            [label]="
              langService.dictionary['tax-exemption-reason'][langService.lang] +
              '*'
            "
            [formControl]="getControl('tax_exemption_reason')"
            [options]="taxExemptionReasonOptions"
          />
          <div *ngIf="!guest.tax_exempt" class="filler"></div>
        </div>

        <div class="row-wrapper" *ngIf="detailedView">
          <custom-input
            [type]="'text'"
            [required]="false"
            [formControl]="getControl('birth_last_name')"
            [label]="
              langService.dictionary['birth-last-name'][langService.lang]
            "
          />
          <custom-input
            [type]="'text'"
            [required]="false"
            [formControl]="getControl('birth_first_name')"
            name="birth_first_name"
            [maxlength]="32"
            [label]="
              langService.dictionary['birth-first-name'][langService.lang]
            "
          />
          <custom-input
            [type]="'text'"
            [required]="false"
            [formControl]="getControl('mothers_last_name')"
            name="mothers_last_name"
            [maxlength]="32"
            [label]="
              langService.dictionary['mothers-last-name'][langService.lang]
            "
          />
          <custom-input
            [type]="'text'"
            [required]="false"
            [formControl]="getControl('mothers_first_name')"
            [label]="
              langService.dictionary['mothers-first-name'][langService.lang]
            "
          />
        </div>

        <div class="row-wrapper" *ngIf="detailedView">
          <custom-input
            [type]="'text'"
            [formControl]="getControl('visa_number')"
            [label]="langService.dictionary['visa-number'][langService.lang]"
          />
          <custom-input
            [type]="'text'"
            [formControl]="getControl('visa_arrival_place')"
            [label]="
              langService.dictionary['visa-arrival-place'][langService.lang]
            "
            [maxlength]="32"
          />
          <date-picker
            [formControl]="getControl('visa_arrival_date')"
            [isDateString]="true"
            [label]="
              langService.dictionary['visa-arrival-date'][langService.lang]
            "
          />
        </div>
        <div class="extra-button-wrapper">
          <button (click)="toggleDetailedView()" type="button">
            <span *ngIf="detailedView"> &#8963;</span
            >{{ langService.dictionary["extra-details"][langService.lang] }}
            <span *ngIf="!detailedView"> &#8964;</span>
          </button>
        </div>
        <div class="button-wrapper">
          <button class="secondary-button" (click)="resetGuest()" type="button">
            {{ langService.dictionary["cancel"][langService.lang] }}
          </button>
          <button
            class="primary-button"
            type="submit"
            *ngIf="!guest.guest_id"
            [disabled]="guestForm.invalid"
          >
            {{ langService.dictionary["check-in"][langService.lang] }}
          </button>
          <button
            type="submit"
            *ngIf="guest.guest_id && !guestForm.dirty"
            disabled
          >
            {{ langService.dictionary["checked-in"][langService.lang] }}
          </button>
          <button
            class="secondary-button"
            (click)="editGuest()"
            type="button"
            [disabled]="guestForm.invalid"
            *ngIf="guestForm.dirty && guest.guest_id"
          >
            {{ langService.dictionary["modify"][langService.lang] }}
          </button>
        </div>
        <!-- <div class="button-wrapper" *ngIf="roomId">
          <button
            type="submit"
            *ngIf="!guest.guest_id"
            [disabled]="guestForm.invalid"
          >
            {{ langService.dictionary["check-in"][langService.lang] }}
          </button>
          <button type="submit" *ngIf="guest.guest_id" disabled>
            {{ langService.dictionary["done"][langService.lang] }}
          </button>
        </div> -->
      </div>
    </form>
  </div>
</div>
