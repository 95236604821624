<div class="profile-wrapper">
  <div class="profile" *ngIf="userService.user">
    <div class="name-wrapper">
      <custom-input
        [type]="'text'"
        [required]="true"
        [(ngModel)]="userService.user.name"
        name="name"
        [maxlength]="32"
        [label]="langService.dictionary['name'][langService.lang]"
      ></custom-input>
      <button
        class="secondary-button save"
        (click)="updateName()"
        [disabled]="!userService.user.name"
      >
        {{ langService.dictionary["save"][langService.lang] }}
      </button>
    </div>

    <custom-select
      class="form-dropdown-select"
      name="lang-selector"
      [(ngModel)]="langService.lang"
      (change)="langService.changeLang()"
      [options]="langService.languages"
      [label]="langService.dictionary['language'][langService.lang]"
    />

    <custom-checkbox
      [(ngModel)]="userService.user.double_conclude"
      [name]="'double_conclude'"
      [label]="langService.dictionary['doubleConclude'][langService.lang]"
      (userInput)="changeDoubleConclude($event)"
    />

    <custom-checkbox
      [(ngModel)]="userService.isDarkMode"
      [name]="'dark_theme'"
      [label]="langService.dictionary['dark-theme'][langService.lang]"
      (userInput)="changeTheme($event)"
    />

    <button class="secondary-button sign-out" (click)="signOut()">
      {{ langService.dictionary["logout"][langService.lang] }}
    </button>
  </div>
</div>
