<table
  class="calendar-table"
  [ngClass]="{
    compact: compactMode
  }"
>
  <thead>
    <tr>
      <th>
        <button (click)="toggleCompactMode()">
          <i class="plus-minus-icon"></i>
        </button>
      </th>
      <th
        *ngFor="let day of calendarService.dateList; let i = index"
        [ngClass]="{
          today: dateService.isDateSameAsToday(day),
          holiday: holidayService.isHoliday(day),
          weekend: dateService.isWeekend(day)
        }"
        (click)="holidayService.toggleHoliday(day)"
      >
        <div class="date-wrapper">
          <span class="month">{{
            day.toLocaleDateString("hu-HU", { month: "short" })
          }}</span>
          <span class="day">{{ day | date : "dd" }}</span>
          <span class="day-name">{{
            day.toLocaleDateString("hu-HU", { weekday: "short" })
          }}</span>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let room of roomService.rooms" (mouseup)="mouseUp($event)">
      <th [id]="room.room_id">
        <span class="room-name">{{ room.name }}</span
        ><br /><span class="room-type"
          >{{ room.type }} - {{ room.single_beds + 2 * room.double_beds }}</span
        >
      </th>

      <td
        *ngFor="let day of calendarService.dateList; let i = index"
        #cells
        [id]="room.room_id!.toString() + '_' + getFormatedDate(day)"
        (click)="leftClickEvent(room.room_id!, getFormatedDate(day), $event)"
        (contextmenu)="rightClickEvent()"
        [ngClass]="{
          today: dateService.isDateSameAsToday(day),
          ooo: isDayOOO(day, room.room_id!) || isDayHotelOOO(day),
          oos: isDayOOS(day, room.room_id!),
          holiday: holidayService.isHoliday(day),
          weekend: dateService.isWeekend(day)
        }"
      >
        <i
          *ngIf="isDayOOO(day, room.room_id!) || isDayHotelOOO(day)"
          class="ooo-icon"
        ></i>
        <i *ngIf="isDayOOS(day, room.room_id!)" class="oos-icon"></i>
        <ng-template #container></ng-template>
      </td>
    </tr>
  </tbody>
  <div *ngIf="!roomService.rooms.length">
    <span>
      {{ langService.dictionary["add-rooms-tooltip"][langService.lang] }}</span
    >
  </div>
</table>
