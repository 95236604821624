import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  BookingArrivalComponent,
  BookingEditDetailsComponent,
  BookingEditRoomsComponent,
  BookingNewDetailsComponent,
  BookingNewRoomsComponent,
  BookingRoomsDepartComponent,
  BookingRoomsPaymentComponent,
  CheckInLoginComponent,
  HotelComponent,
  RoomsAddComponent,
  RoomsEditComponent,
  RoomsOooComponent,
  RoomsOosComponent,
  SelfCheckInComponent,
  ServicesAddComponent,
  ServicesComponent,
  ServicesEditComponent,
  WelcomeComponent,
} from './@components';
import { AdminComponent } from './@components/@pages/admin/admin.component';
import { RoomsComponent } from './@components/@pages/admin/rooms/rooms.component';
import { CalendarComponent } from './@components/@pages/calendar/calendar.component';
import { HomeComponent } from './@components/@pages/home/home.component';
import { LoginComponent } from './@components/@pages/login/login.component';
import { PageNotFoundComponent } from './@components/@pages/page-not-found/page-not-found.component';
import { ProfileComponent } from './@components/@pages/profile/profile.component';
import { RegisterComponent } from './@components/@pages/register/register.component';
import { SupportComponent } from './@components/@pages/support/support.component';
import { QrScannerComponent } from './@components/qr-scanner/qr-scanner.component';
import { ScannerComponent } from './@components/scan/scanner.component';
import { authGuard } from './@guards/auth.guard';

const routes: Routes = [
  { path: 'welcome', component: WelcomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard],
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'arrival',
        component: BookingArrivalComponent,
      },
      {
        path: 'booking',
        component: BookingNewDetailsComponent,
      },
      {
        path: 'departure',
        component: BookingRoomsDepartComponent,
      },
      {
        path: 'room',
        component: BookingNewRoomsComponent,
      },
      {
        path: 'modify-booking',
        component: BookingEditDetailsComponent,
      },
      {
        path: 'modify-rooms',
        component: BookingEditRoomsComponent,
      },
      {
        path: 'payment',
        component: BookingRoomsPaymentComponent,
      },
    ],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'rooms',
        component: RoomsComponent,
        children: [
          {
            path: 'add',
            component: RoomsAddComponent,
          },
          {
            path: 'edit',
            component: RoomsEditComponent,
          },
          {
            path: 'ooo',
            component: RoomsOooComponent,
          },
          {
            path: 'oos',
            component: RoomsOosComponent,
          },
        ],
      },
      {
        path: 'services',
        component: ServicesComponent,
        children: [
          {
            path: 'add',
            component: ServicesAddComponent,
          },
          {
            path: 'edit',
            component: ServicesEditComponent,
          },
        ],
      },
      { path: 'hotel', component: HotelComponent },
      { path: '**', redirectTo: 'hotel', pathMatch: 'full' },
    ],
  },
  {
    path: 'qr-scanner',
    component: QrScannerComponent,
    canActivate: [authGuard],
  },
  {
    path: 'scanner',
    component: ScannerComponent,
    canActivate: [authGuard],
  },
  { path: 'profil', component: ProfileComponent, canActivate: [authGuard] },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [authGuard],
  },
  {
    path: 'online-check-in',
    component: CheckInLoginComponent,
  },
  {
    path: 'self-check-in',
    component: SelfCheckInComponent,
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled', // Enables scrolling to the anchor
      scrollPositionRestoration: 'enabled', // Restores scroll position when navigating back
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
