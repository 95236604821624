<div class="self-check-in-wrapper" *ngIf="!isScannerOpen">
  <div class="details-wrapper">
    <div class="progress-wrapper">
      <div class="progress">
        <span>{{ langService.dictionary["scan"][langService.lang] }}</span>
        <div class="progress-bar">
          <div class="progress-bar-fill" [ngStyle]="scannedProgress"></div>
        </div>
      </div>
      <div class="progress">
        <span>{{ langService.dictionary["check-in"][langService.lang] }}</span>
        <div class="progress-bar">
          <div class="progress-bar-fill" [ngStyle]="checkedInProgress"></div>
        </div>
      </div>
    </div>
    <div class="room-details-wrapper">
      <span
        >{{ selectedRoom?.arrival_date }} -
        {{ selectedRoom?.departure_date }}</span
      >
      <span>{{ selectedRoom?.hotel_name }}</span>
    </div>
  </div>
  <div class="action-panel">
    <div class="arrival-wrapper">
      <ng-container *ngFor="let guest of guests">
        <arrival-card
          #guestForms
          [guest]="guest"
          [totalGuests]="guests.length"
          [roomId]="selectedRoom.room_id"
          (isValid)="checkValidations()"
          (addGuest)="addSingleGuest($event)"
          (openCheckInScanner)="openScanner()"
          *ngIf="selectedRoom.room_id === guest.room_id"
        ></arrival-card>
      </ng-container>
    </div>
  </div>
  <div class="selector-wrapper">
    <div class="room-panel-wrapper">
      <button
        *ngFor="let room of rooms"
        class="secondary-button"
        [ngClass]="{
          selected: room.room_id === selectedRoom.room_id
        }"
        (click)="selectRoom(room)"
      >
        {{ room.room_name }}
      </button>
    </div>
  </div>
</div>
<scanner
  class="scanner"
  *ngIf="isScannerOpen"
  [roomId]="selectedRoom?.room_id"
  [isCheckIn]="true"
  (closeScanner)="closeScanner()"
></scanner>
