import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  BookingDetailsInterface,
  BookingInterface,
  IDepartRes,
  IResponse,
  ISaveGuestRes,
} from '../@Interfaces';
import { ExtendedBookingRoom } from '../@Interfaces/room.interface';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  public bookings: BookingInterface[] = [];
  //public removedBookings: ExtendedBookingRoom[] = [];
  public lastLoadedBookings: BookingInterface[] = [];
  public selectedBooking: BookingInterface;
  public selectedBooking$: BehaviorSubject<void> = new BehaviorSubject<void>(
    null
  );
  public isBookingOpen: boolean;
  public sidePanelRooms: ExtendedBookingRoom[] = [];
  public queryDates: any = {
    start_date: null,
    end_date: null,
  };
  public isStepOne: boolean;
  public isValidBooking: boolean;
  public bookingData: BookingDetailsInterface = {
    channel: null,
    contact_email: null,
    contact_name: null,
    contact_phone: null,
    group_booking: false,
    language: null,
    nationality: null,
    source: null,
    work_related: false,
    company_name: null,
    tax_number: null,
    tax_zone: null,
    booking_number: null,
    uuid: null,
    online_check_in_status: null,
  };

  private selectedHotelId: string;
  private selectedRoomId: string;

  /* <-- API CALLS --> */

  public async getBookings(hotelId: string = null): Promise<void> {
    const hotel_id = hotelId ?? this.selectedHotelId;
    this.selectedHotelId = hotel_id;
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<IResponse<BookingInterface[]>>(
          `${
            this.configService.server
          }api/hotels/${hotel_id}/bookings?${this.getQueryParams()}`
        )
        .subscribe({
          next: (res: IResponse<BookingInterface[]>) => {
            this.bookings = res.data ?? [];

            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public async getBooking(
    hotelId: string,
    bookingId: string,
    roomId: string
  ): Promise<void> {
    const hotel_id = hotelId ?? this.selectedHotelId;
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<IResponse<BookingInterface[]>>(
          `${this.configService.server}api/hotels/${hotel_id}/bookings?booking_id=${bookingId}`
        )
        .subscribe({
          next: (res: IResponse<BookingInterface[]>) => {
            const bookings: BookingInterface[] = res.data;

            const selectedBooking = bookings.find(
              (booking: BookingInterface) =>
                booking.room_id.toString() === roomId
            );
            console.log('get booking next ', selectedBooking);

            this.selectedBooking = selectedBooking;

            this.setSelectedBookingsRooms(bookings);

            this.selectedBooking$.next();

            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  public postNewBooking(selectedRooms: ExtendedBookingRoom[]): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      /* const rooms = Object.entries(selectedRooms).map(([key, value]) => ({
        room_id: Number(key),
        arrival_date: value.arrival_date,
        departure_date: value.departure_date,
        adults: value.adults,
        children: value.children,
        gross_price: value.gross_price,
        ifa_included: value.ifa_included,
        prepayment: value.prepayment ?? 0,
      })); */
      const booking: BookingDetailsInterface = this.bookingData;
      delete booking.booking_number;
      delete booking.uuid;

      booking.rooms = selectedRooms;
      booking.strict = true;

      this.http
        .post<IResponse<BookingInterface[]>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/bookings`,
          booking
        )
        .subscribe({
          next: (res: any) => {
            this.getBookings().catch(() => {});
            resolve(res.data);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public putBookingDetails(
    bookingId: number,
    body: BookingDetailsInterface
  ): Promise<void> {
    delete body.booking_number;
    delete body.uuid;
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/bookings/${bookingId}`,
          body
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async addBookingRoom(bookingId: number, room: any): Promise<void> {
    const body = {
      rooms: [room],
    };

    return new Promise<void>((resolve, reject) => {
      this.http
        .post<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/rooms`,
          body
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async putBookingRoom(bookingId: number, room: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<IResponse>(
          `${this.configService.server}api/hotels/${
            this.selectedHotelId
          }/bookings/${bookingId}/rooms/${room.old_room_id ?? room.room_id}`,
          room
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async deleteBookingRooms(
    bookingId: number,
    selectedRoom: number
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/rooms/${selectedRoom}`
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async deleteBooking(bookingId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/bookings/${bookingId}`
        )
        .subscribe({
          next: async () => {
            await this.getBookings();
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async arriveGuestsInRoom(
    bookingId: number,
    roomId: number,
    guests: any[]
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<IResponse<ISaveGuestRes>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/guests`,
          guests
        )
        .subscribe({
          next: (res: IResponse<ISaveGuestRes>) => {
            if (!res.success) {
              reject();
            }
            this.updateArrivedStatus(bookingId, roomId);
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public async payRoom(bookingId: number, rooms: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<IResponse>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/charge`,
          rooms
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public async departRooms(
    bookingId: number,
    roomIdsStr: number[]
  ): Promise<IResponse<IDepartRes>> {
    return new Promise<IResponse<IDepartRes>>((resolve, reject) => {
      const roomIds = roomIdsStr.map((roomId) => Number(roomId));

      this.http
        .post<IResponse<IDepartRes>>(
          `${this.configService.server}api/hotels/${this.selectedHotelId}/bookings/${bookingId}/depart`,
          { rooms: roomIds }
        )
        .subscribe({
          next: (res: IResponse<IDepartRes>) => {
            resolve(res);
          },
          error: (error: HttpErrorResponse) => {
            reject();
          },
        });
    });
  }

  /* <-- API CALLS --> */

  /* <-- HELPER FUNCTIONS --> */

  public resetSelectedRooms(): void {
    console.log('remove');

    this.isValidBooking = false;
    this.sidePanelRooms = [];
  }

  public saveBookingData(booking: BookingDetailsInterface): void {
    this.bookingData = booking;
  }

  public setSelectedBookingsRooms(bookings: BookingInterface[] = null): void {
    if (!this.selectedBooking) return null;
    const selectedBookings =
      bookings ??
      this.bookings.filter(
        (booking) => booking.booking_id === this.selectedBooking.booking_id
      );

    let roomArray: ExtendedBookingRoom[] = [];

    for (let booking of selectedBookings) {
      roomArray.push({
        adults: booking.adults,
        children: booking.children,
        gross_price: booking.gross_price,
        arrival_date: booking.arrival_date,
        departure_date: booking.departure_date,
        arrived: booking.arrived,
        paid: booking.paid,
        departed: booking.departed,
        room_id: booking.room_id,
        contact_name: booking.contact_name,
        booking_id: booking.booking_id,
        plus_tax: booking.plus_tax,
      });
    }
    if (!roomArray.length) return;
    this.sidePanelRooms = roomArray;
  }

  public updateBookingRoomStatuses(
    bookingId: number,
    roomIds: number[],
    key: string,
    status: boolean
  ): void {
    this.bookings = this.bookings.map((booking) => {
      if (
        booking.booking_id === bookingId &&
        roomIds.includes(booking.room_id)
      ) {
        return { ...booking, [key]: status };
      }
      //TODO check if booking status needs to be updated or not
      return booking;
    });
  }

  public checkBookingStatus(statusToCheck: string, bookingId: number): boolean {
    if (!bookingId) return false;
    const status = this.bookings.some(
      (booking) =>
        Object.keys(booking).includes(statusToCheck) &&
        booking.booking_id === bookingId &&
        !(booking as any)[statusToCheck]
    );
    return status;
  }

  public canBookingBeDeleted(bookingId: number): boolean {
    const anyRoomArrived = this.bookings.some(
      (booking: BookingInterface) =>
        booking.booking_id === bookingId && booking.arrived
    );
    return !anyRoomArrived;
  }

  public checkBookingCondStatuses(
    statusToCheck: string,
    falseStatus: string,
    bookingId: number
  ): boolean {
    const status = this.bookings.some(
      (booking) =>
        Object.keys(booking).includes(statusToCheck) &&
        booking.booking_id === bookingId &&
        (booking as any)[statusToCheck] &&
        !(booking as any)[falseStatus]
    );
    return status;
  }

  private updateArrivedStatus(bookingId: number, roomId: number): void {
    const index = this.bookings.findIndex(
      (booking) =>
        booking.booking_id === bookingId && booking.room_id === roomId
    );
    this.bookings[index].arrived = true;
  }

  private getQueryParams(): string {
    return new HttpParams()
      .set('start_date', this.queryDates.start_date)
      .set('end_date', this.queryDates.end_date)
      .toString();
  }

  public selectBooking(bookingId: string, roomId: string): void {
    const booking = this.bookings.find(
      (booking: BookingInterface) =>
        booking.booking_id === Number(bookingId) &&
        booking.room_id === Number(roomId)
    );
    console.log('select booking next', booking);

    if (!booking) return;
    this.selectedBooking = booking;
    this.selectedBooking$.next();
  }

  /* <-- HELPER FUNCTIONS --> */
}
