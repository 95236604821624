export const chanelOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válassza ki az értékesítési csatornát',
      en: 'Select reservation channel',
    },
  },
  {
    value: 'DIREKT_ONLINE',
    label: {
      hu: 'Direkt-online',
      en: 'Direct online',
    },
  },
  {
    value: 'DIREKT_HAGYOMANYOS',
    label: {
      hu: 'Direkt-hagyományos',
      en: 'Direct',
    },
  },
  {
    value: 'KOZVETITO_ONLINE',
    label: {
      hu: 'Közvetítő-online',
      en: 'Online agency',
    },
  },
  {
    value: 'KOZVETITO_HAGYOMANYOS',
    label: {
      hu: 'Direkt-hagyományos',
      en: 'Agency',
    },
  },
];

export const paymentOptions: any = [
  {
    selected: true,
    label: {
      hu: 'Fizetési mód',
      en: 'Payment method',
    },
    value: null,
  },
  {
    value: 'ATUTALAS',
    label: {
      hu: 'Átutalás',
      en: 'Wire transfer',
    },
  },
  {
    value: 'BANKKARTYA',
    label: {
      hu: 'Bankkártya',
      en: 'Card',
    },
  },
  {
    value: 'KESZPENZ',
    label: {
      hu: 'Készpénz',
      en: 'Cash',
    },
  },
  {
    value: 'SZEP_KARTYA',
    label: {
      hu: 'Szépkártya',
      en: 'Szép-card',
    },
  },
  {
    value: 'UTALVANY',
    label: {
      hu: 'Utalvány',
      en: 'Voucher',
    },
  },
  {
    value: 'EGYEB',
    label: {
      hu: 'Egyéb',
      en: 'Oher',
    },
  },
];

export const invoiceTypeOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Számla típusa',
      en: 'Invoice type',
    },
  },
  {
    value: true,
    label: {
      hu: 'Céges',
      en: 'Company',
    },
  },
  {
    value: false,
    label: {
      hu: 'Magánszemély',
      en: 'Individual',
    },
  },
];

export const taxObjectsOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Adóalany',
      en: 'Tax subject',
    },
  },
  {
    value: 'INDIVIDUAL',
    label: {
      hu: 'Magánszemély',
      en: 'Individual',
    },
  },
  {
    value: 'HU',
    label: {
      hu: 'Magyar',
      en: 'Hungarian',
    },
  },
  {
    value: 'EU',
    label: {
      hu: 'EU',
      en: 'EU',
    },
  },
  {
    value: 'INT',
    label: {
      hu: 'Nemzetközi',
      en: 'International',
    },
  },
];

export const taxZoneOptions: any = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Adózási zóna',
      en: 'Tax zone',
    },
    value: null,
  },
  {
    value: 'HU',
    label: {
      hu: 'Magyar',
      en: 'Hungarian',
    },
  },
  {
    value: 'EU',
    label: {
      hu: 'EU',
      en: 'EU',
    },
  },
  {
    value: 'INT',
    label: {
      hu: 'Nemzetközi',
      en: 'International',
    },
  },
];

export const sourceOptions: any = [
  {
    selected: true,
    disabled: true,
    value: null,
    label: {
      hu: 'Válassza ki a platformot',
      en: 'Select platform',
    },
  },
  {
    value: 'airbnb.com',
    label: {
      hu: 'Airbnb',
      en: 'Airbnb',
    },
  },
  {
    value: 'booking.com',
    label: {
      hu: 'booking.com',
      en: 'booking.com',
    },
  },
  {
    value: 'hotels.com',
    label: {
      hu: 'hotels.com',
      en: 'hotels.com',
    },
  },
  {
    value: 'szallas.hu',
    label: {
      hu: 'szallas.hu',
      en: 'szallas.hu',
    },
  },
  {
    value: 'other',
    label: {
      hu: 'Egyéb',
      en: 'Other',
    },
  },
];

export const helpSubjectOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válassza ki a tárgyat',
      en: 'Select subject',
    },
  },
  {
    value: 'bug',
    label: {
      hu: 'Hibabejelentés',
      en: 'Bug report',
    },
  },
  {
    value: 'support',
    label: {
      hu: 'Kérdés',
      en: 'Help',
    },
  },
  {
    value: 'feature',
    label: {
      hu: 'Javaslat',
      en: 'Feature request',
    },
  },
];

export const helpPriorityOptions = [
  {
    selected: true,
    disabled: true,
    label: { hu: 'Válasszon prioritást', en: 'Select priority' },
  },
  {
    value: 'high',
    label: {
      hu: 'Sürgős',
      en: 'High',
    },
  },
  {
    value: 'medium',
    label: {
      hu: 'Normál',
      en: 'Medium',
    },
  },
  {
    value: 'low',
    label: {
      hu: 'Alacsony',
      en: 'Low',
    },
  },
];

export const freeHelpPriorityOptions = [
  { label: { hu: 'Alacsony', en: 'Low' }, selected: true, disabled: true },
];

export const contactLangOptions: any = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válassza ki a preferált kommunikációs nyelvet',
      en: 'Select contact language',
    },
  },
  {
    value: 'EN',
    label: {
      hu: 'Angol',
      en: 'English',
    },
  },
  {
    value: 'HU',
    label: {
      hu: 'Magyar',
      en: 'Hungarian',
    },
  },
];
export const communicationLangOptions: any = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válassza ki a preferált Kommunikációs nyelvet',
      en: 'Select communication language',
    },
  },
  {
    value: 'EN',
    label: {
      hu: 'Angol',
      en: 'English',
    },
  },
  {
    value: 'FR',
    label: {
      hu: 'Francia',
      en: 'French',
    },
  },
  {
    value: 'CN',
    label: {
      hu: 'Kínai',
      en: 'Chinese',
    },
  },
  {
    value: 'PL',
    label: {
      hu: 'Lengyel',
      en: 'Polish',
    },
  },
  {
    value: 'HU',
    label: {
      hu: 'Magyar',
      en: 'Hungarian',
    },
  },
  {
    value: 'DE',
    label: {
      hu: 'Német',
      en: 'German',
    },
  },
  {
    value: 'RU',
    label: {
      hu: 'Orosz',
      en: 'Russian',
    },
  },
  {
    value: 'ES',
    label: {
      hu: 'Spanyol',
      en: 'Spanish',
    },
  },
];

export const nationalityOptions: any = [
  {
    selected: true,
    disabled: true,
    label: { hu: 'Válasszon nemzetiséget', en: 'Select nationality' },
  },
  { value: 'HU', label: { hu: 'Magyarország', en: 'Hungary' } },
  // Europe
  { value: 'AL', label: { hu: 'Albánia', en: 'Albania' } },
  { value: 'AD', label: { hu: 'Andorra', en: 'Andorra' } },
  { value: 'AT', label: { hu: 'Ausztria', en: 'Austria' } },
  { value: 'BE', label: { hu: 'Belgium', en: 'Belgium' } },
  {
    value: 'BA',
    label: { hu: 'Bosznia-Hercegovina', en: 'Bosnia and Herzegovina' },
  },
  { value: 'BG', label: { hu: 'Bulgária', en: 'Bulgaria' } },
  { value: 'CY', label: { hu: 'Ciprus', en: 'Cyprus' } },
  { value: 'CZ', label: { hu: 'Csehország', en: 'Czech Republic' } },
  { value: 'DK', label: { hu: 'Dánia', en: 'Denmark' } },
  { value: 'GB', label: { hu: 'Egyesült Királyság', en: 'United Kingdom' } },
  { value: 'EE', label: { hu: 'Észtország', en: 'Estonia' } },
  { value: 'BY', label: { hu: 'Fehéroroszország', en: 'Belarus' } },
  { value: 'FI', label: { hu: 'Finnország', en: 'Finland' } },
  { value: 'FR', label: { hu: 'Franciaország', en: 'France' } },
  { value: 'GR', label: { hu: 'Görögország', en: 'Greece' } },
  { value: 'GL', label: { hu: 'Grönland', en: 'Greenland' } },
  { value: 'NL', label: { hu: 'Hollandia', en: 'Netherlands' } },
  { value: 'HR', label: { hu: 'Horvátország', en: 'Croatia' } },
  { value: 'IE', label: { hu: 'Írország', en: 'Ireland' } },
  { value: 'IS', label: { hu: 'Izland', en: 'Iceland' } },
  { value: 'PL', label: { hu: 'Lengyelország', en: 'Poland' } },
  { value: 'LV', label: { hu: 'Lettország', en: 'Latvia' } },
  { value: 'LI', label: { hu: 'Liechtenstein', en: 'Liechtenstein' } },
  { value: 'LT', label: { hu: 'Litvánia', en: 'Lithuania' } },
  { value: 'LU', label: { hu: 'Luxemburg', en: 'Luxembourg' } },
  { value: 'MK', label: { hu: 'Macedónia', en: 'North Macedonia' } },
  { value: 'MT', label: { hu: 'Málta', en: 'Malta' } },
  { value: 'MD', label: { hu: 'Moldova', en: 'Moldova' } },
  { value: 'MC', label: { hu: 'Monaco', en: 'Monaco' } },
  { value: 'DE', label: { hu: 'Németország', en: 'Germany' } },
  { value: 'NO', label: { hu: 'Norvégia', en: 'Norway' } },
  { value: 'IT', label: { hu: 'Olaszország', en: 'Italy' } },
  { value: 'RU', label: { hu: 'Oroszország', en: 'Russia' } },
  { value: 'AM', label: { hu: 'Örményország', en: 'Armenia' } },
  { value: 'PT', label: { hu: 'Portugália', en: 'Portugal' } },
  { value: 'RO', label: { hu: 'Románia', en: 'Romania' } },
  { value: 'ES', label: { hu: 'Spanyolország', en: 'Spain' } },
  { value: 'CH', label: { hu: 'Svájc', en: 'Switzerland' } },
  { value: 'SE', label: { hu: 'Svédország', en: 'Sweden' } },
  { value: 'SK', label: { hu: 'Szlovákia', en: 'Slovakia' } },
  { value: 'SI', label: { hu: 'Szlovénia', en: 'Slovenia' } },
  { value: 'UA', label: { hu: 'Ukrajna', en: 'Ukraine' } },
  { value: 'VA', label: { hu: 'Vatikán', en: 'Vatican City' } },

  // Rest
  { value: 'AF', label: { hu: 'Afganisztán', en: 'Afghanistan' } },
  { value: 'AX', label: { hu: 'Aland', en: 'Aland' } },
  { value: 'DZ', label: { hu: 'Algéria', en: 'Algeria' } },
  {
    value: 'UM',
    label: {
      hu: 'USA Amerikai Csendes-óceáni szigetek',
      en: 'USA Minor Outlying Islands',
    },
  },
  {
    value: 'US',
    label: { hu: 'Amerikai Egyesült Államok', en: 'United States' },
  },
  { value: 'AS', label: { hu: 'Amerikai Szamoa', en: 'American Samoa' } },
  {
    value: 'VI',
    label: { hu: 'Amerikai Virgin-szigetek', en: 'U.S. Virgin Islands' },
  },
  { value: 'AO', label: { hu: 'Angola', en: 'Angola' } },
  { value: 'AI', label: { hu: 'Anguilla', en: 'Anguilla' } },
  { value: 'AQ', label: { hu: 'Antarktisz', en: 'Antarctica' } },
  {
    value: 'AG',
    label: { hu: 'Antigua és Barbuda', en: 'Antigua and Barbuda' },
  },
  { value: 'AR', label: { hu: 'Argentína', en: 'Argentina' } },
  { value: 'AW', label: { hu: 'Aruba', en: 'Aruba' } },
  { value: 'AU', label: { hu: 'Ausztrália', en: 'Australia' } },
  { value: 'AZ', label: { hu: 'Azerbajdzsán', en: 'Azerbaijan' } },
  { value: 'BS', label: { hu: 'Bahama-szigetek', en: 'Bahamas' } },
  { value: 'BH', label: { hu: 'Bahrein', en: 'Bahrain' } },
  { value: 'BD', label: { hu: 'Banglades', en: 'Bangladesh' } },
  { value: 'BB', label: { hu: 'Barbados', en: 'Barbados' } },
  { value: 'BZ', label: { hu: 'Belize', en: 'Belize' } },
  { value: 'BJ', label: { hu: 'Benin', en: 'Benin' } },
  { value: 'BM', label: { hu: 'Bermuda', en: 'Bermuda' } },
  { value: 'BT', label: { hu: 'Bhután', en: 'Bhutan' } },
  { value: 'GW', label: { hu: 'Bissau-Guinea', en: 'Guinea-Bissau' } },
  { value: 'BO', label: { hu: 'Bolívia', en: 'Bolivia' } },
  { value: 'BW', label: { hu: 'Botswana', en: 'Botswana' } },
  { value: 'BV', label: { hu: 'Norvégia Bouvet-sziget', en: 'Bouvet Island' } },
  { value: 'BR', label: { hu: 'Brazília', en: 'Brazil' } },
  {
    value: 'IO',
    label: {
      hu: 'Brit Indiai-óceáni Terület',
      en: 'British Indian Ocean Territory',
    },
  },
  {
    value: 'VG',
    label: { hu: 'Brit Virgin-szigetek', en: 'British Virgin Islands' },
  },
  { value: 'BN', label: { hu: 'Brunei', en: 'Brunei' } },
  { value: 'BF', label: { hu: 'Burkina Faso', en: 'Burkina Faso' } },
  { value: 'BI', label: { hu: 'Burundi', en: 'Burundi' } },
  { value: 'CL', label: { hu: 'Chile', en: 'Chile' } },
  { value: 'KM', label: { hu: 'Comore-szigetek', en: 'Comoros' } },
  { value: 'CK', label: { hu: 'Cook-szigetek', en: 'Cook Islands' } },
  { value: 'CR', label: { hu: 'Costa Rica', en: 'Costa Rica' } },
  { value: 'CW', label: { hu: 'Curaçao', en: 'Curaçao' } },
  { value: 'TD', label: { hu: 'Csád', en: 'Chad' } },
  { value: 'ZA', label: { hu: 'Dél-afrikai Köztársaság', en: 'South Africa' } },
  {
    value: 'GS',
    label: {
      hu: 'Déli-Georgia és Déli-Sandwich-szigetek',
      en: 'South Georgia and the South Sandwich Islands',
    },
  },
  { value: 'SS', label: { hu: 'Dél-Szudán', en: 'South Sudan' } },
  {
    value: 'KR',
    label: { hu: 'Dél-Korea (Koreai Köztársaság)', en: 'South Korea' },
  },
  { value: 'DM', label: { hu: 'Dominikai Közösség', en: 'Dominica' } },
  {
    value: 'DO',
    label: { hu: 'Dominikai Köztársaság', en: 'Dominican Republic' },
  },
  { value: 'DJ', label: { hu: 'Dzsibuti', en: 'Djibouti' } },
  { value: 'EC', label: { hu: 'Ecuador', en: 'Ecuador' } },
  { value: 'GQ', label: { hu: 'Egyenlítői-Guinea', en: 'Equatorial Guinea' } },
  {
    value: 'AE',
    label: { hu: 'Egyesült Arab Emírségek', en: 'United Arab Emirates' },
  },
  { value: 'EG', label: { hu: 'Egyiptom', en: 'Egypt' } },
  { value: 'CI', label: { hu: 'Elefántcsontpart', en: 'Ivory Coast' } },
  { value: 'SV', label: { hu: 'Salvador', en: 'El Salvador' } },
  { value: 'ER', label: { hu: 'Eritrea', en: 'Eritrea' } },
  {
    value: 'MP',
    label: { hu: 'Északi-Mariana-szigetek', en: 'Northern Mariana Islands' },
  },
  { value: 'KP', label: { hu: 'Észak-Korea (Koreai NDK)', en: 'North Korea' } },
  { value: 'ET', label: { hu: 'Etiópia', en: 'Ethiopia' } },
  { value: 'FK', label: { hu: 'Falkland-szigetek', en: 'Falkland Islands' } },
  { value: 'FO', label: { hu: 'Feröer', en: 'Faroe Islands' } },
  { value: 'FJ', label: { hu: 'Fidzsi', en: 'Fiji' } },
  {
    value: 'TF',
    label: {
      hu: 'Francia déli és antarktiszi területek',
      en: 'French Southern and Antarctic Lands',
    },
  },
  { value: 'GF', label: { hu: 'Francia Guyana', en: 'French Guiana' } },
  { value: 'PF', label: { hu: 'Francia Polinézia', en: 'French Polynesia' } },
  { value: 'PH', label: { hu: 'Fülöp-szigetek', en: 'Philippines' } },
  { value: 'GA', label: { hu: 'Gabon', en: 'Gabon' } },
  { value: 'GM', label: { hu: 'Gambia', en: 'Gambia' } },
  { value: 'GH', label: { hu: 'Ghána', en: 'Ghana' } },
  { value: 'GI', label: { hu: 'Gibraltár', en: 'Gibraltar' } },
  { value: 'GD', label: { hu: 'Grenada', en: 'Grenada' } },
  { value: 'GE', label: { hu: 'Grúzia', en: 'Georgia' } },
  { value: 'GP', label: { hu: 'Guadeloupe', en: 'Guadeloupe' } },
  { value: 'GU', label: { hu: 'Guam', en: 'Guam' } },
  { value: 'GT', label: { hu: 'Guatemala', en: 'Guatemala' } },
  { value: 'GG', label: { hu: 'Guernsey Bailiffség', en: 'Guernsey' } },
  { value: 'GN', label: { hu: 'Guinea', en: 'Guinea' } },
  { value: 'GY', label: { hu: 'Guyana', en: 'Guyana' } },
  { value: 'HT', label: { hu: 'Haiti', en: 'Haiti' } },
  {
    value: 'HM',
    label: {
      hu: 'Heard-sziget és McDonald-szigetek',
      en: 'Heard Island and McDonald Islands',
    },
  },
  { value: 'HN', label: { hu: 'Honduras', en: 'Honduras' } },
  { value: 'HK', label: { hu: 'Hongkong', en: 'Hong Kong' } },
  { value: 'IN', label: { hu: 'India', en: 'India' } },
  { value: 'ID', label: { hu: 'Indonézia', en: 'Indonesia' } },
  { value: 'IQ', label: { hu: 'Irak', en: 'Iraq' } },
  { value: 'IR', label: { hu: 'Irán', en: 'Iran' } },
  { value: 'IL', label: { hu: 'Izrael', en: 'Israel' } },
  { value: 'JM', label: { hu: 'Jamaica', en: 'Jamaica' } },
  { value: 'JP', label: { hu: 'Japán', en: 'Japan' } },
  { value: 'YE', label: { hu: 'Jemen', en: 'Yemen' } },
  { value: 'JE', label: { hu: 'Jersey Bailiffség', en: 'Jersey' } },
  { value: 'JO', label: { hu: 'Jordánia', en: 'Jordan' } },
  { value: 'KY', label: { hu: 'Kajmán-szigetek', en: 'Cayman Islands' } },
  { value: 'KH', label: { hu: 'Kambodzsa', en: 'Cambodia' } },
  { value: 'CM', label: { hu: 'Kamerun', en: 'Cameroon' } },
  { value: 'CA', label: { hu: 'Kanada', en: 'Canada' } },
  { value: 'CX', label: { hu: 'Karácsony-sziget', en: 'Christmas Island' } },
  {
    value: 'BQ',
    label: {
      hu: 'Karibi Hollandia (Bonaire, Saba, Sint Eustatius)',
      en: 'Caribbean Netherlands (Bonaire, Saba, Sint Eustatius)',
    },
  },
  { value: 'QA', label: { hu: 'Katar', en: 'Qatar' } },
  { value: 'KZ', label: { hu: 'Kazahsztán', en: 'Kazakhstan' } },
  { value: 'TL', label: { hu: 'Kelet-Timor', en: 'Timor-Leste' } },
  { value: 'KE', label: { hu: 'Kenya', en: 'Kenya' } },
  { value: 'CN', label: { hu: 'Kína', en: 'China' } },
  { value: 'KG', label: { hu: 'Kirgizisztán', en: 'Kyrgyzstan' } },
  { value: 'KI', label: { hu: 'Kiribati', en: 'Kiribati' } },
  {
    value: 'CC',
    label: { hu: 'Kókusz (Keeling)-szigetek', en: 'Cocos (Keeling) Islands' },
  },
  { value: 'CO', label: { hu: 'Kolumbia', en: 'Colombia' } },
  {
    value: 'CD',
    label: {
      hu: 'Kongói Demokratikus Köztársaság (Zaire)',
      en: 'Democratic Republic of the Congo (Zaire)',
    },
  },
  {
    value: 'CG',
    label: {
      hu: 'Kongói Köztársaság (Kongó)',
      en: 'Republic of the Congo (Congo)',
    },
  },
  { value: 'XK', label: { hu: 'Koszovó', en: 'Kosovo' } },
  {
    value: 'CF',
    label: { hu: 'Közép-Afrika', en: 'Central African Republic' },
  },
  { value: 'CU', label: { hu: 'Kuba', en: 'Cuba' } },
  { value: 'KW', label: { hu: 'Kuvait', en: 'Kuwait' } },
  { value: 'LA', label: { hu: 'Laosz', en: 'Laos' } },
  { value: 'LS', label: { hu: 'Lesotho', en: 'Lesotho' } },
  { value: 'LB', label: { hu: 'Libanon', en: 'Lebanon' } },
  { value: 'LR', label: { hu: 'Libéria', en: 'Liberia' } },
  { value: 'LY', label: { hu: 'Líbia', en: 'Libya' } },
  { value: 'MG', label: { hu: 'Madagaszkár', en: 'Madagascar' } },
  { value: 'MO', label: { hu: 'Makaó', en: 'Macau' } },
  { value: 'MY', label: { hu: 'Malajzia', en: 'Malaysia' } },
  { value: 'MW', label: { hu: 'Malawi', en: 'Malawi' } },
  { value: 'MV', label: { hu: 'Maldív-szigetek', en: 'Maldives' } },
  { value: 'ML', label: { hu: 'Mali', en: 'Mali' } },
  { value: 'IM', label: { hu: 'Man', en: 'Isle of Man' } },
  { value: 'MA', label: { hu: 'Marokkó', en: 'Morocco' } },
  { value: 'MH', label: { hu: 'Marshall-szigetek', en: 'Marshall Islands' } },
  { value: 'MQ', label: { hu: 'Martinique', en: 'Martinique' } },
  { value: 'MR', label: { hu: 'Mauritánia', en: 'Mauritania' } },
  { value: 'MU', label: { hu: 'Mauritius', en: 'Mauritius' } },
  { value: 'YT', label: { hu: 'Mayotte', en: 'Mayotte' } },
  { value: 'MX', label: { hu: 'Mexikó', en: 'Mexico' } },
  { value: 'MM', label: { hu: 'Mianmar', en: 'Myanmar' } },
  { value: 'FM', label: { hu: 'Mikronézia', en: 'Micronesia' } },
  { value: 'MN', label: { hu: 'Mongólia', en: 'Mongolia' } },
  { value: 'ME', label: { hu: 'Montenegró', en: 'Montenegro' } },
  { value: 'MS', label: { hu: 'Montserrat', en: 'Montserrat' } },
  { value: 'MZ', label: { hu: 'Mozambik', en: 'Mozambique' } },
  { value: 'NA', label: { hu: 'Namíbia', en: 'Namibia' } },
  { value: 'NR', label: { hu: 'Nauru', en: 'Nauru' } },
  { value: 'NP', label: { hu: 'Nepál', en: 'Nepal' } },
  { value: 'NI', label: { hu: 'Nicaragua', en: 'Nicaragua' } },
  { value: 'NE', label: { hu: 'Niger', en: 'Niger' } },
  { value: 'NG', label: { hu: 'Nigéria', en: 'Nigeria' } },
  { value: 'NU', label: { hu: 'Niue', en: 'Niue' } },
  { value: 'NF', label: { hu: 'Norfolk-sziget', en: 'Norfolk Island' } },
  { value: 'EH', label: { hu: 'Nyugat-Szahara', en: 'Western Sahara' } },
  { value: 'OM', label: { hu: 'Omán', en: 'Oman' } },
  { value: 'PK', label: { hu: 'Pakisztán', en: 'Pakistan' } },
  { value: 'PW', label: { hu: 'Palau', en: 'Palau' } },
  { value: 'PS', label: { hu: 'Palesztina', en: 'Palestine' } },
  { value: 'PA', label: { hu: 'Panama', en: 'Panama' } },
  { value: 'PG', label: { hu: 'Pápua Új-Guinea', en: 'Papua New Guinea' } },
  { value: 'PY', label: { hu: 'Paraguay', en: 'Paraguay' } },
  { value: 'PE', label: { hu: 'Peru', en: 'Peru' } },
  { value: 'PN', label: { hu: 'Pitcairn-szigetek', en: 'Pitcairn Islands' } },
  { value: 'PR', label: { hu: 'Puerto Rico', en: 'Puerto Rico' } },
  { value: 'RE', label: { hu: 'Réunion', en: 'Réunion' } },
  { value: 'RW', label: { hu: 'Ruanda', en: 'Rwanda' } },
  { value: 'BL', label: { hu: 'Saint-Barthélemy', en: 'Saint Barthélemy' } },
  {
    value: 'KN',
    label: { hu: 'Saint Kitts és Nevis', en: 'Saint Kitts and Nevis' },
  },
  { value: 'LC', label: { hu: 'Saint Lucia', en: 'Saint Lucia' } },
  { value: 'MF', label: { hu: 'Saint-Martin', en: 'Saint Martin' } },
  {
    value: 'PM',
    label: { hu: 'Saint-Pierre és Miquelon', en: 'Saint Pierre and Miquelon' },
  },
  {
    value: 'VC',
    label: {
      hu: 'Saint Vincent és a Grenadine-szigetek',
      en: 'Saint Vincent and the Grenadines',
    },
  },
  { value: 'SB', label: { hu: 'Salamon-szigetek', en: 'Solomon Islands' } },
  { value: 'SM', label: { hu: 'San Marino', en: 'San Marino' } },
  {
    value: 'ST',
    label: { hu: 'Sao Tomé és Príncipe', en: 'São Tomé and Príncipe' },
  },
  { value: 'SC', label: { hu: 'Seychelle-szigetek', en: 'Seychelles' } },
  { value: 'SL', label: { hu: 'Sierra Leone', en: 'Sierra Leone' } },
  { value: 'SX', label: { hu: 'Sint Maarten', en: 'Sint Maarten' } },
  {
    value: 'SJ',
    label: {
      hu: 'Norvégia Spitzbergák és Jan Mayen-sziget',
      en: 'Svalbard and Jan Mayen',
    },
  },
  { value: 'LK', label: { hu: 'Srí Lanka', en: 'Sri Lanka' } },
  { value: 'SR', label: { hu: 'Suriname', en: 'Suriname' } },
  { value: 'WS', label: { hu: 'Szamoa', en: 'Samoa' } },
  { value: 'SA', label: { hu: 'Szaúd-Arábia', en: 'Saudi Arabia' } },
  { value: 'SN', label: { hu: 'Szenegál', en: 'Senegal' } },
  { value: 'SH', label: { hu: 'Szent Ilona', en: 'Saint Helena' } },
  { value: 'RS', label: { hu: 'Szerbia', en: 'Serbia' } },
  { value: 'SG', label: { hu: 'Szingapúr', en: 'Singapore' } },
  { value: 'SY', label: { hu: 'Szíria', en: 'Syria' } },
  { value: 'SO', label: { hu: 'Szomália', en: 'Somalia' } },
  { value: 'SD', label: { hu: 'Szudán', en: 'Sudan' } },
  { value: 'SZ', label: { hu: 'Szváziföld', en: 'Eswatini' } },
  { value: 'TJ', label: { hu: 'Tádzsikisztán', en: 'Tajikistan' } },
  { value: 'TW', label: { hu: 'Tajvan', en: 'Taiwan' } },
  { value: 'TZ', label: { hu: 'Tanzánia', en: 'Tanzania' } },
  { value: 'TH', label: { hu: 'Thaiföld', en: 'Thailand' } },
  { value: 'TG', label: { hu: 'Togo', en: 'Togo' } },
  { value: 'TK', label: { hu: 'Tokelau-szigetek', en: 'Tokelau' } },
  { value: 'TO', label: { hu: 'Tonga', en: 'Tonga' } },
  { value: 'TR', label: { hu: 'Törökország', en: 'Turkey' } },
  {
    value: 'TT',
    label: { hu: 'Trinidad és Tobago', en: 'Trinidad and Tobago' },
  },
  { value: 'TN', label: { hu: 'Tunézia', en: 'Tunisia' } },
  {
    value: 'TC',
    label: { hu: 'Turks- és Caicos-szigetek', en: 'Turks and Caicos Islands' },
  },
  { value: 'TV', label: { hu: 'Tuvalu', en: 'Tuvalu' } },
  { value: 'TM', label: { hu: 'Türkmenisztán', en: 'Turkmenistan' } },
  { value: 'UG', label: { hu: 'Uganda', en: 'Uganda' } },
  { value: 'NC', label: { hu: 'Új-Kaledónia', en: 'New Caledonia' } },
  { value: 'NZ', label: { hu: 'Új-Zéland', en: 'New Zealand' } },
  { value: 'UY', label: { hu: 'Uruguay', en: 'Uruguay' } },
  { value: 'UZ', label: { hu: 'Üzbegisztán', en: 'Uzbekistan' } },
  { value: 'VU', label: { hu: 'Vanuatu', en: 'Vanuatu' } },
  { value: 'VE', label: { hu: 'Venezuela', en: 'Venezuela' } },
  { value: 'VN', label: { hu: 'Vietnám', en: 'Vietnam' } },
  { value: 'WF', label: { hu: 'Wallis és Futuna', en: 'Wallis and Futuna' } },
  { value: 'ZM', label: { hu: 'Zambia', en: 'Zambia' } },
  { value: 'ZW', label: { hu: 'Zimbabwe', en: 'Zimbabwe' } },
  { value: 'CV', label: { hu: 'Zöld-foki Köztársaság', en: 'Cape Verde' } },
];

export const roomTypeOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válasszon szoba típust',
      en: 'Select room type',
    },
  },
  {
    value: 'APARTMAN',
    label: {
      hu: 'Apartman',
      en: 'Apartment',
    },
  },
  {
    value: 'ECONOMY',
    label: {
      hu: 'Economy',
      en: 'Economy',
    },
  },
  {
    value: 'STANDARD',
    label: {
      hu: 'Standard',
      en: 'Standard',
    },
  },
  {
    value: 'SUPERIOR',
    label: {
      hu: 'Superior',
      en: 'Superior',
    },
  },
  {
    value: 'JUNIOR_SUITE',
    label: {
      hu: 'Junior suite',
      en: 'Junior suite',
    },
  },
  {
    value: 'SUITE',
    label: {
      hu: 'Suite',
      en: 'Suite',
    },
  },
  {
    value: 'MOBILHAZ',
    label: {
      hu: 'Mobilház',
      en: 'Mobilehome',
    },
  },
  {
    value: 'UDULOHAZ',
    label: {
      hu: 'Üdülőház',
      en: 'Resort',
    },
  },
  {
    value: 'PARCELLA',
    label: {
      hu: 'Parcella',
      en: 'Plot',
    },
  },
  {
    value: 'SATORHELY_KEMPINGHELY',
    label: {
      hu: 'Sátorhely/ Kempinghely',
      en: 'Campsite',
    },
  },
  {
    value: 'EGYEDI',
    label: {
      hu: 'Egyedi (pl: jurta)',
      en: 'Custom',
    },
  },
  {
    value: 'PRIVAT_SZOBA_KOZOS_FURDOVEL',
    label: {
      hu: 'Privát szoba közös fürdővel (ifjúsági szálláshely)',
      en: 'Private room with shared bathroom (hostel)',
    },
  },
  {
    value: 'PRIVAT_SZOBA_SAJAT_FURDOVEL',
    label: {
      hu: 'Privát szoba saját fürdővel (ifjúsági szálláshely)',
      en: 'Private room with private bathroom (hostel)',
    },
  },
  {
    value: 'HALOTERMI_AGY',
    label: {
      hu: 'Hálótermi ágy',
      en: 'Dorm room',
    },
  },
  {
    value: 'EGYEB',
    label: {
      hu: 'Egyéb',
      en: 'Other',
    },
  },
];

export const floorOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válasszon emeletet',
      en: 'Select floor',
    },
  },
  {
    value: -1,
    label: {
      hu: 'Alagsor',
      en: 'Basement',
    },
  },
  {
    value: 0,
    label: {
      hu: 'Földszint',
      en: 'Ground floor',
    },
  },
  {
    value: 1,
    label: {
      hu: '1. emelet',
      en: '1st floor',
    },
  },
  {
    value: 2,
    label: {
      hu: '2. emelet',
      en: '2nd floor',
    },
  },
  {
    value: 3,
    label: {
      hu: '3. emelet',
      en: '3rd floor',
    },
  },
  {
    value: 4,
    label: {
      hu: '4. emelet',
      en: '4th floor',
    },
  },
  {
    value: 5,
    label: {
      hu: '5. emelet',
      en: '5th floor',
    },
  },
  {
    value: 6,
    label: {
      hu: '6. emelet',
      en: '6th floor',
    },
  },
  {
    value: 7,
    label: {
      hu: '7. emelet',
      en: '7th floor',
    },
  },
  {
    value: 8,
    label: {
      hu: '8. emelet',
      en: '8th floor',
    },
  },
  {
    value: 9,
    label: {
      hu: '9. emelet',
      en: '9th floor',
    },
  },
  {
    value: 10,
    label: {
      hu: '10. emelet',
      en: '10th floor',
    },
  },
];

export const genderOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válasszon nemet',
      en: 'Select gender',
    },
  },

  {
    value: 'FERFI',
    label: {
      hu: 'Férfi',
      en: 'Male',
    },
  },
  {
    value: 'NO',
    label: {
      hu: 'Nő',
      en: 'Female',
    },
  },
  {
    value: 'EGYEB_VAGY_NEM_ISMERT',
    label: {
      hu: 'Egyéb',
      en: 'Other',
    },
  },
];

export const documentTypeOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válasszon okmány típust',
      en: 'Select identification document',
    },
  },

  {
    value: 'SZEM_IG',
    label: {
      hu: 'Személyi',
      en: 'ID card',
    },
  },
  {
    value: 'UTLEVEL',
    label: {
      hu: 'Útlevél',
      en: 'Passport',
    },
  },
  {
    value: 'VEZ_ENG',
    label: {
      hu: 'Jogosítvány',
      en: "Driver's license",
    },
  },
  {
    value: 'EGYEB',
    label: {
      hu: 'Egyéb',
      en: 'Other',
    },
  },
  {
    value: 'U14',
    label: {
      hu: '14 év alatti',
      en: 'Under 14 years old',
    },
  },
];

export const countryOfResidenceOptions = [
  {
    selected: true,
    disabled: true,
    label: { hu: 'Válasszon lakóhelyet', en: 'Select residency' },
  },
  { value: 'HU', label: { hu: 'Magyarország', en: 'Hungary' } },
  // Europe
  { value: 'AL', label: { hu: 'Albánia', en: 'Albania' } },
  { value: 'AD', label: { hu: 'Andorra', en: 'Andorra' } },
  { value: 'AT', label: { hu: 'Ausztria', en: 'Austria' } },
  { value: 'BE', label: { hu: 'Belgium', en: 'Belgium' } },
  {
    value: 'BA',
    label: { hu: 'Bosznia-Hercegovina', en: 'Bosnia and Herzegovina' },
  },
  { value: 'BG', label: { hu: 'Bulgária', en: 'Bulgaria' } },
  { value: 'CY', label: { hu: 'Ciprus', en: 'Cyprus' } },
  { value: 'CZ', label: { hu: 'Csehország', en: 'Czech Republic' } },
  { value: 'DK', label: { hu: 'Dánia', en: 'Denmark' } },
  { value: 'GB', label: { hu: 'Egyesült Királyság', en: 'United Kingdom' } },
  { value: 'EE', label: { hu: 'Észtország', en: 'Estonia' } },
  { value: 'BY', label: { hu: 'Fehéroroszország', en: 'Belarus' } },
  { value: 'FI', label: { hu: 'Finnország', en: 'Finland' } },
  { value: 'FR', label: { hu: 'Franciaország', en: 'France' } },
  { value: 'GR', label: { hu: 'Görögország', en: 'Greece' } },
  { value: 'GL', label: { hu: 'Grönland', en: 'Greenland' } },
  { value: 'NL', label: { hu: 'Hollandia', en: 'Netherlands' } },
  { value: 'HR', label: { hu: 'Horvátország', en: 'Croatia' } },
  { value: 'IE', label: { hu: 'Írország', en: 'Ireland' } },
  { value: 'IS', label: { hu: 'Izland', en: 'Iceland' } },
  { value: 'PL', label: { hu: 'Lengyelország', en: 'Poland' } },
  { value: 'LV', label: { hu: 'Lettország', en: 'Latvia' } },
  { value: 'LI', label: { hu: 'Liechtenstein', en: 'Liechtenstein' } },
  { value: 'LT', label: { hu: 'Litvánia', en: 'Lithuania' } },
  { value: 'LU', label: { hu: 'Luxemburg', en: 'Luxembourg' } },
  { value: 'MK', label: { hu: 'Macedónia', en: 'North Macedonia' } },
  { value: 'MT', label: { hu: 'Málta', en: 'Malta' } },
  { value: 'MD', label: { hu: 'Moldova', en: 'Moldova' } },
  { value: 'MC', label: { hu: 'Monaco', en: 'Monaco' } },
  { value: 'DE', label: { hu: 'Németország', en: 'Germany' } },
  { value: 'NO', label: { hu: 'Norvégia', en: 'Norway' } },
  { value: 'IT', label: { hu: 'Olaszország', en: 'Italy' } },
  { value: 'RU', label: { hu: 'Oroszország', en: 'Russia' } },
  { value: 'AM', label: { hu: 'Örményország', en: 'Armenia' } },
  { value: 'PT', label: { hu: 'Portugália', en: 'Portugal' } },
  { value: 'RO', label: { hu: 'Románia', en: 'Romania' } },
  { value: 'ES', label: { hu: 'Spanyolország', en: 'Spain' } },
  { value: 'CH', label: { hu: 'Svájc', en: 'Switzerland' } },
  { value: 'SE', label: { hu: 'Svédország', en: 'Sweden' } },
  { value: 'SK', label: { hu: 'Szlovákia', en: 'Slovakia' } },
  { value: 'SI', label: { hu: 'Szlovénia', en: 'Slovenia' } },
  { value: 'UA', label: { hu: 'Ukrajna', en: 'Ukraine' } },
  { value: 'VA', label: { hu: 'Vatikán', en: 'Vatican City' } },

  // Rest
  { value: 'AF', label: { hu: 'Afganisztán', en: 'Afghanistan' } },
  { value: 'AX', label: { hu: 'Aland', en: 'Aland' } },
  { value: 'DZ', label: { hu: 'Algéria', en: 'Algeria' } },
  {
    value: 'UM',
    label: {
      hu: 'USA Amerikai Csendes-óceáni szigetek',
      en: 'USA Minor Outlying Islands',
    },
  },
  {
    value: 'US',
    label: { hu: 'Amerikai Egyesült Államok', en: 'United States' },
  },
  { value: 'AS', label: { hu: 'Amerikai Szamoa', en: 'American Samoa' } },
  {
    value: 'VI',
    label: { hu: 'Amerikai Virgin-szigetek', en: 'U.S. Virgin Islands' },
  },
  { value: 'AO', label: { hu: 'Angola', en: 'Angola' } },
  { value: 'AI', label: { hu: 'Anguilla', en: 'Anguilla' } },
  { value: 'AQ', label: { hu: 'Antarktisz', en: 'Antarctica' } },
  {
    value: 'AG',
    label: { hu: 'Antigua és Barbuda', en: 'Antigua and Barbuda' },
  },
  { value: 'AR', label: { hu: 'Argentína', en: 'Argentina' } },
  { value: 'AW', label: { hu: 'Aruba', en: 'Aruba' } },
  { value: 'AU', label: { hu: 'Ausztrália', en: 'Australia' } },
  { value: 'AZ', label: { hu: 'Azerbajdzsán', en: 'Azerbaijan' } },
  { value: 'BS', label: { hu: 'Bahama-szigetek', en: 'Bahamas' } },
  { value: 'BH', label: { hu: 'Bahrein', en: 'Bahrain' } },
  { value: 'BD', label: { hu: 'Banglades', en: 'Bangladesh' } },
  { value: 'BB', label: { hu: 'Barbados', en: 'Barbados' } },
  { value: 'BZ', label: { hu: 'Belize', en: 'Belize' } },
  { value: 'BJ', label: { hu: 'Benin', en: 'Benin' } },
  { value: 'BM', label: { hu: 'Bermuda', en: 'Bermuda' } },
  { value: 'BT', label: { hu: 'Bhután', en: 'Bhutan' } },
  { value: 'GW', label: { hu: 'Bissau-Guinea', en: 'Guinea-Bissau' } },
  { value: 'BO', label: { hu: 'Bolívia', en: 'Bolivia' } },
  { value: 'BW', label: { hu: 'Botswana', en: 'Botswana' } },
  { value: 'BV', label: { hu: 'Norvégia Bouvet-sziget', en: 'Bouvet Island' } },
  { value: 'BR', label: { hu: 'Brazília', en: 'Brazil' } },
  {
    value: 'IO',
    label: {
      hu: 'Brit Indiai-óceáni Terület',
      en: 'British Indian Ocean Territory',
    },
  },
  {
    value: 'VG',
    label: { hu: 'Brit Virgin-szigetek', en: 'British Virgin Islands' },
  },
  { value: 'BN', label: { hu: 'Brunei', en: 'Brunei' } },
  { value: 'BF', label: { hu: 'Burkina Faso', en: 'Burkina Faso' } },
  { value: 'BI', label: { hu: 'Burundi', en: 'Burundi' } },
  { value: 'CL', label: { hu: 'Chile', en: 'Chile' } },
  { value: 'KM', label: { hu: 'Comore-szigetek', en: 'Comoros' } },
  { value: 'CK', label: { hu: 'Cook-szigetek', en: 'Cook Islands' } },
  { value: 'CR', label: { hu: 'Costa Rica', en: 'Costa Rica' } },
  { value: 'CW', label: { hu: 'Curaçao', en: 'Curaçao' } },
  { value: 'TD', label: { hu: 'Csád', en: 'Chad' } },
  { value: 'ZA', label: { hu: 'Dél-afrikai Köztársaság', en: 'South Africa' } },
  {
    value: 'GS',
    label: {
      hu: 'Déli-Georgia és Déli-Sandwich-szigetek',
      en: 'South Georgia and the South Sandwich Islands',
    },
  },
  { value: 'SS', label: { hu: 'Dél-Szudán', en: 'South Sudan' } },
  {
    value: 'KR',
    label: { hu: 'Dél-Korea (Koreai Köztársaság)', en: 'South Korea' },
  },
  { value: 'DM', label: { hu: 'Dominikai Közösség', en: 'Dominica' } },
  {
    value: 'DO',
    label: { hu: 'Dominikai Köztársaság', en: 'Dominican Republic' },
  },
  { value: 'DJ', label: { hu: 'Dzsibuti', en: 'Djibouti' } },
  { value: 'EC', label: { hu: 'Ecuador', en: 'Ecuador' } },
  { value: 'GQ', label: { hu: 'Egyenlítői-Guinea', en: 'Equatorial Guinea' } },
  {
    value: 'AE',
    label: { hu: 'Egyesült Arab Emírségek', en: 'United Arab Emirates' },
  },
  { value: 'EG', label: { hu: 'Egyiptom', en: 'Egypt' } },
  { value: 'CI', label: { hu: 'Elefántcsontpart', en: 'Ivory Coast' } },
  { value: 'SV', label: { hu: 'Salvador', en: 'El Salvador' } },
  { value: 'ER', label: { hu: 'Eritrea', en: 'Eritrea' } },
  {
    value: 'MP',
    label: { hu: 'Északi-Mariana-szigetek', en: 'Northern Mariana Islands' },
  },
  { value: 'KP', label: { hu: 'Észak-Korea (Koreai NDK)', en: 'North Korea' } },
  { value: 'ET', label: { hu: 'Etiópia', en: 'Ethiopia' } },
  { value: 'FK', label: { hu: 'Falkland-szigetek', en: 'Falkland Islands' } },
  { value: 'FO', label: { hu: 'Feröer', en: 'Faroe Islands' } },
  { value: 'FJ', label: { hu: 'Fidzsi', en: 'Fiji' } },
  {
    value: 'TF',
    label: {
      hu: 'Francia déli és antarktiszi területek',
      en: 'French Southern and Antarctic Lands',
    },
  },
  { value: 'GF', label: { hu: 'Francia Guyana', en: 'French Guiana' } },
  { value: 'PF', label: { hu: 'Francia Polinézia', en: 'French Polynesia' } },
  { value: 'PH', label: { hu: 'Fülöp-szigetek', en: 'Philippines' } },
  { value: 'GA', label: { hu: 'Gabon', en: 'Gabon' } },
  { value: 'GM', label: { hu: 'Gambia', en: 'Gambia' } },
  { value: 'GH', label: { hu: 'Ghána', en: 'Ghana' } },
  { value: 'GI', label: { hu: 'Gibraltár', en: 'Gibraltar' } },
  { value: 'GD', label: { hu: 'Grenada', en: 'Grenada' } },
  { value: 'GE', label: { hu: 'Grúzia', en: 'Georgia' } },
  { value: 'GP', label: { hu: 'Guadeloupe', en: 'Guadeloupe' } },
  { value: 'GU', label: { hu: 'Guam', en: 'Guam' } },
  { value: 'GT', label: { hu: 'Guatemala', en: 'Guatemala' } },
  { value: 'GG', label: { hu: 'Guernsey Bailiffség', en: 'Guernsey' } },
  { value: 'GN', label: { hu: 'Guinea', en: 'Guinea' } },
  { value: 'GY', label: { hu: 'Guyana', en: 'Guyana' } },
  { value: 'HT', label: { hu: 'Haiti', en: 'Haiti' } },
  {
    value: 'HM',
    label: {
      hu: 'Heard-sziget és McDonald-szigetek',
      en: 'Heard Island and McDonald Islands',
    },
  },
  { value: 'HN', label: { hu: 'Honduras', en: 'Honduras' } },
  { value: 'HK', label: { hu: 'Hongkong', en: 'Hong Kong' } },
  { value: 'IN', label: { hu: 'India', en: 'India' } },
  { value: 'ID', label: { hu: 'Indonézia', en: 'Indonesia' } },
  { value: 'IQ', label: { hu: 'Irak', en: 'Iraq' } },
  { value: 'IR', label: { hu: 'Irán', en: 'Iran' } },
  { value: 'IL', label: { hu: 'Izrael', en: 'Israel' } },
  { value: 'JM', label: { hu: 'Jamaica', en: 'Jamaica' } },
  { value: 'JP', label: { hu: 'Japán', en: 'Japan' } },
  { value: 'YE', label: { hu: 'Jemen', en: 'Yemen' } },
  { value: 'JE', label: { hu: 'Jersey Bailiffség', en: 'Jersey' } },
  { value: 'JO', label: { hu: 'Jordánia', en: 'Jordan' } },
  { value: 'KY', label: { hu: 'Kajmán-szigetek', en: 'Cayman Islands' } },
  { value: 'KH', label: { hu: 'Kambodzsa', en: 'Cambodia' } },
  { value: 'CM', label: { hu: 'Kamerun', en: 'Cameroon' } },
  { value: 'CA', label: { hu: 'Kanada', en: 'Canada' } },
  { value: 'CX', label: { hu: 'Karácsony-sziget', en: 'Christmas Island' } },
  {
    value: 'BQ',
    label: {
      hu: 'Karibi Hollandia (Bonaire, Saba, Sint Eustatius)',
      en: 'Caribbean Netherlands (Bonaire, Saba, Sint Eustatius)',
    },
  },
  { value: 'QA', label: { hu: 'Katar', en: 'Qatar' } },
  { value: 'KZ', label: { hu: 'Kazahsztán', en: 'Kazakhstan' } },
  { value: 'TL', label: { hu: 'Kelet-Timor', en: 'Timor-Leste' } },
  { value: 'KE', label: { hu: 'Kenya', en: 'Kenya' } },
  { value: 'CN', label: { hu: 'Kína', en: 'China' } },
  { value: 'KG', label: { hu: 'Kirgizisztán', en: 'Kyrgyzstan' } },
  { value: 'KI', label: { hu: 'Kiribati', en: 'Kiribati' } },
  {
    value: 'CC',
    label: { hu: 'Kókusz (Keeling)-szigetek', en: 'Cocos (Keeling) Islands' },
  },
  { value: 'CO', label: { hu: 'Kolumbia', en: 'Colombia' } },
  {
    value: 'CD',
    label: {
      hu: 'Kongói Demokratikus Köztársaság (Zaire)',
      en: 'Democratic Republic of the Congo (Zaire)',
    },
  },
  {
    value: 'CG',
    label: {
      hu: 'Kongói Köztársaság (Kongó)',
      en: 'Republic of the Congo (Congo)',
    },
  },
  { value: 'XK', label: { hu: 'Koszovó', en: 'Kosovo' } },
  {
    value: 'CF',
    label: { hu: 'Közép-Afrika', en: 'Central African Republic' },
  },
  { value: 'CU', label: { hu: 'Kuba', en: 'Cuba' } },
  { value: 'KW', label: { hu: 'Kuvait', en: 'Kuwait' } },
  { value: 'LA', label: { hu: 'Laosz', en: 'Laos' } },
  { value: 'LS', label: { hu: 'Lesotho', en: 'Lesotho' } },
  { value: 'LB', label: { hu: 'Libanon', en: 'Lebanon' } },
  { value: 'LR', label: { hu: 'Libéria', en: 'Liberia' } },
  { value: 'LY', label: { hu: 'Líbia', en: 'Libya' } },
  { value: 'MG', label: { hu: 'Madagaszkár', en: 'Madagascar' } },
  { value: 'MO', label: { hu: 'Makaó', en: 'Macau' } },
  { value: 'MY', label: { hu: 'Malajzia', en: 'Malaysia' } },
  { value: 'MW', label: { hu: 'Malawi', en: 'Malawi' } },
  { value: 'MV', label: { hu: 'Maldív-szigetek', en: 'Maldives' } },
  { value: 'ML', label: { hu: 'Mali', en: 'Mali' } },
  { value: 'IM', label: { hu: 'Man', en: 'Isle of Man' } },
  { value: 'MA', label: { hu: 'Marokkó', en: 'Morocco' } },
  { value: 'MH', label: { hu: 'Marshall-szigetek', en: 'Marshall Islands' } },
  { value: 'MQ', label: { hu: 'Martinique', en: 'Martinique' } },
  { value: 'MR', label: { hu: 'Mauritánia', en: 'Mauritania' } },
  { value: 'MU', label: { hu: 'Mauritius', en: 'Mauritius' } },
  { value: 'YT', label: { hu: 'Mayotte', en: 'Mayotte' } },
  { value: 'MX', label: { hu: 'Mexikó', en: 'Mexico' } },
  { value: 'MM', label: { hu: 'Mianmar', en: 'Myanmar' } },
  { value: 'FM', label: { hu: 'Mikronézia', en: 'Micronesia' } },
  { value: 'MN', label: { hu: 'Mongólia', en: 'Mongolia' } },
  { value: 'ME', label: { hu: 'Montenegró', en: 'Montenegro' } },
  { value: 'MS', label: { hu: 'Montserrat', en: 'Montserrat' } },
  { value: 'MZ', label: { hu: 'Mozambik', en: 'Mozambique' } },
  { value: 'NA', label: { hu: 'Namíbia', en: 'Namibia' } },
  { value: 'NR', label: { hu: 'Nauru', en: 'Nauru' } },
  { value: 'NP', label: { hu: 'Nepál', en: 'Nepal' } },
  { value: 'NI', label: { hu: 'Nicaragua', en: 'Nicaragua' } },
  { value: 'NE', label: { hu: 'Niger', en: 'Niger' } },
  { value: 'NG', label: { hu: 'Nigéria', en: 'Nigeria' } },
  { value: 'NU', label: { hu: 'Niue', en: 'Niue' } },
  { value: 'NF', label: { hu: 'Norfolk-sziget', en: 'Norfolk Island' } },
  { value: 'EH', label: { hu: 'Nyugat-Szahara', en: 'Western Sahara' } },
  { value: 'OM', label: { hu: 'Omán', en: 'Oman' } },
  { value: 'PK', label: { hu: 'Pakisztán', en: 'Pakistan' } },
  { value: 'PW', label: { hu: 'Palau', en: 'Palau' } },
  { value: 'PS', label: { hu: 'Palesztina', en: 'Palestine' } },
  { value: 'PA', label: { hu: 'Panama', en: 'Panama' } },
  { value: 'PG', label: { hu: 'Pápua Új-Guinea', en: 'Papua New Guinea' } },
  { value: 'PY', label: { hu: 'Paraguay', en: 'Paraguay' } },
  { value: 'PE', label: { hu: 'Peru', en: 'Peru' } },
  { value: 'PN', label: { hu: 'Pitcairn-szigetek', en: 'Pitcairn Islands' } },
  { value: 'PR', label: { hu: 'Puerto Rico', en: 'Puerto Rico' } },
  { value: 'RE', label: { hu: 'Réunion', en: 'Réunion' } },
  { value: 'RW', label: { hu: 'Ruanda', en: 'Rwanda' } },
  { value: 'BL', label: { hu: 'Saint-Barthélemy', en: 'Saint Barthélemy' } },
  {
    value: 'KN',
    label: { hu: 'Saint Kitts és Nevis', en: 'Saint Kitts and Nevis' },
  },
  { value: 'LC', label: { hu: 'Saint Lucia', en: 'Saint Lucia' } },
  { value: 'MF', label: { hu: 'Saint-Martin', en: 'Saint Martin' } },
  {
    value: 'PM',
    label: { hu: 'Saint-Pierre és Miquelon', en: 'Saint Pierre and Miquelon' },
  },
  {
    value: 'VC',
    label: {
      hu: 'Saint Vincent és a Grenadine-szigetek',
      en: 'Saint Vincent and the Grenadines',
    },
  },
  { value: 'SB', label: { hu: 'Salamon-szigetek', en: 'Solomon Islands' } },
  { value: 'SM', label: { hu: 'San Marino', en: 'San Marino' } },
  {
    value: 'ST',
    label: { hu: 'Sao Tomé és Príncipe', en: 'São Tomé and Príncipe' },
  },
  { value: 'SC', label: { hu: 'Seychelle-szigetek', en: 'Seychelles' } },
  { value: 'SL', label: { hu: 'Sierra Leone', en: 'Sierra Leone' } },
  { value: 'SX', label: { hu: 'Sint Maarten', en: 'Sint Maarten' } },
  {
    value: 'SJ',
    label: {
      hu: 'Norvégia Spitzbergák és Jan Mayen-sziget',
      en: 'Svalbard and Jan Mayen',
    },
  },
  { value: 'LK', label: { hu: 'Srí Lanka', en: 'Sri Lanka' } },
  { value: 'SR', label: { hu: 'Suriname', en: 'Suriname' } },
  { value: 'WS', label: { hu: 'Szamoa', en: 'Samoa' } },
  { value: 'SA', label: { hu: 'Szaúd-Arábia', en: 'Saudi Arabia' } },
  { value: 'SN', label: { hu: 'Szenegál', en: 'Senegal' } },
  { value: 'SH', label: { hu: 'Szent Ilona', en: 'Saint Helena' } },
  { value: 'RS', label: { hu: 'Szerbia', en: 'Serbia' } },
  { value: 'SG', label: { hu: 'Szingapúr', en: 'Singapore' } },
  { value: 'SY', label: { hu: 'Szíria', en: 'Syria' } },
  { value: 'SO', label: { hu: 'Szomália', en: 'Somalia' } },
  { value: 'SD', label: { hu: 'Szudán', en: 'Sudan' } },
  { value: 'SZ', label: { hu: 'Szváziföld', en: 'Eswatini' } },
  { value: 'TJ', label: { hu: 'Tádzsikisztán', en: 'Tajikistan' } },
  { value: 'TW', label: { hu: 'Tajvan', en: 'Taiwan' } },
  { value: 'TZ', label: { hu: 'Tanzánia', en: 'Tanzania' } },
  { value: 'TH', label: { hu: 'Thaiföld', en: 'Thailand' } },
  { value: 'TG', label: { hu: 'Togo', en: 'Togo' } },
  { value: 'TK', label: { hu: 'Tokelau-szigetek', en: 'Tokelau' } },
  { value: 'TO', label: { hu: 'Tonga', en: 'Tonga' } },
  { value: 'TR', label: { hu: 'Törökország', en: 'Turkey' } },
  {
    value: 'TT',
    label: { hu: 'Trinidad és Tobago', en: 'Trinidad and Tobago' },
  },
  { value: 'TN', label: { hu: 'Tunézia', en: 'Tunisia' } },
  {
    value: 'TC',
    label: { hu: 'Turks- és Caicos-szigetek', en: 'Turks and Caicos Islands' },
  },
  { value: 'TV', label: { hu: 'Tuvalu', en: 'Tuvalu' } },
  { value: 'TM', label: { hu: 'Türkmenisztán', en: 'Turkmenistan' } },
  { value: 'UG', label: { hu: 'Uganda', en: 'Uganda' } },
  { value: 'NC', label: { hu: 'Új-Kaledónia', en: 'New Caledonia' } },
  { value: 'NZ', label: { hu: 'Új-Zéland', en: 'New Zealand' } },
  { value: 'UY', label: { hu: 'Uruguay', en: 'Uruguay' } },
  { value: 'UZ', label: { hu: 'Üzbegisztán', en: 'Uzbekistan' } },
  { value: 'VU', label: { hu: 'Vanuatu', en: 'Vanuatu' } },
  { value: 'VE', label: { hu: 'Venezuela', en: 'Venezuela' } },
  { value: 'VN', label: { hu: 'Vietnám', en: 'Vietnam' } },
  { value: 'WF', label: { hu: 'Wallis és Futuna', en: 'Wallis and Futuna' } },
  { value: 'ZM', label: { hu: 'Zambia', en: 'Zambia' } },
  { value: 'ZW', label: { hu: 'Zimbabwe', en: 'Zimbabwe' } },
  { value: 'CV', label: { hu: 'Zöld-foki Köztársaság', en: 'Cape Verde' } },
];

export const taxExemptionReasonOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válasszon mentességi okot',
      en: 'Select reason of exemption',
    },
  },

  {
    value: 'im1',
    label: {
      hu: '18 év alatti',
      en: 'Under 18 years old',
    },
  },
  {
    value: 'im2',
    label: {
      hu: 'Gyógyintézetben',
      en: 'Medical institution',
    },
  },
  {
    value: 'im3',
    label: {
      hu: 'Közép-és felsőfokú oktatási',
      en: 'Middle or higher education',
    },
  },
  {
    value: 'im4',
    label: {
      hu: 'Szolgálati kötelezettséget',
      en: 'Service duty',
    },
  },
  {
    value: 'im5',
    label: {
      hu: 'Hatósági, bírósági intézkedés folytán',
      en: 'Magisterial actions',
    },
  },
  {
    value: 'im6',
    label: {
      hu: 'A településen székhellyel, telephellyel rendelkező',
      en: 'Owns property in area',
    },
  },
  {
    value: 'im7',
    label: {
      hu: 'Honvédelmi vagy rendvédelmi feladatokat ellátó szervek',
      en: 'Soldier duty',
    },
  },
  {
    value: 'im8',
    label: {
      hu: 'A településen üdülőtulajdonnal',
      en: 'Has accommodation in area',
    },
  },
  {
    value: 'im9',
    label: {
      hu: 'Az egyház tulajdonában lévő épületben',
      en: 'Church',
    },
  },
  {
    value: 'im10',
    label: {
      hu: 'A településen lakóhellyel',
      en: 'Lives in area',
    },
  },
  {
    value: 'im11',
    label: {
      hu: 'Önkormányzati rendeleti mentesség (Egyéb)',
      en: 'Other',
    },
  },
];

export const vatRateOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válasszon ÁFÁ-t',
      en: 'Select VAT rate',
    },
  },
  {
    value: 0,
    label: {
      hu: '0%',
      en: '0%',
    },
  },
  {
    value: 5,
    label: {
      hu: '5%',
      en: '5%',
    },
  },
  {
    value: 18,
    label: {
      hu: '18%',
      en: '18%',
    },
  },
  {
    value: 27,
    label: {
      hu: '27%',
      en: '27%',
    },
  },
];

export const serviceCategoryOptions = [
  {
    selected: true,
    disabled: true,
    label: {
      hu: 'Válasszon kategóriát',
      en: 'Select category',
    },
  },
  {
    value: 'FOOD',
    label: {
      hu: 'Étel',
      en: 'Food',
    },
  },
  {
    value: 'DRINK',
    label: {
      hu: 'Ital',
      en: 'Drink',
    },
  },
  {
    value: 'WELLNESS',
    label: {
      hu: 'Wellness',
      en: 'Wellness',
    },
  },
  {
    value: 'OTHER',
    label: {
      hu: 'Egyéb',
      en: 'Other',
    },
  },
];
