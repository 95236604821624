import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Buffer } from 'buffer';
import { CameraPreview } from 'capacitor-plugin-camera';
import { Jimp } from 'jimp';
import * as MRZ from 'mrz';
import {
  ScansCompletedDialog,
  SuccessfulScanDialog,
  UnsuccessfulScanDialog,
} from 'src/app/@dialogs';
import {
  BookingService,
  CheckInService,
  GuestService,
  LanguageService,
  RouterService,
  ScannerService,
} from 'src/app/@services';
import * as Tesseract from 'tesseract.js';

@Component({
  selector: 'scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
  standalone: false,
})
export class ScannerComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private cdr: ChangeDetectorRef,
    public guestService: GuestService,
    private scannerService: ScannerService,
    private routerService: RouterService,
    private renderer: Renderer2,
    private matDialog: MatDialog,
    private checkInService: CheckInService,
    public langService: LanguageService,
    private bookingService: BookingService
  ) {}

  @Input() isCheckIn: boolean = false;
  @Input() roomId: number;
  @Output('closeScanner') closeScanner = new EventEmitter<void>();
  @ViewChild('video', { static: false }) video: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;

  public documentType: 'id' | 'passport' = 'id';

  private videoIndex: number = 0;
  private readonly acceptedLengths: number[] = [9, 30, 36, 44];
  private cameraHeight: number;
  public worker: Tesseract.Worker;
  protected isLoading: boolean;
  protected peopleScanned: number = 0;
  public videoDevices: string[] = [];
  public snapshot: string;
  public divBackgroundSource: string;
  public total: number = 0;

  ngOnInit(): void {
    const htmlElement = document.querySelector('html');
    const bodyElement = document.querySelector('body');

    ScreenOrientation.lock({ orientation: 'landscape-primary' });

    if (Capacitor.getPlatform() === 'ios') {
      //added this shit because of cuckphone users with their gay ass safari browser
      setTimeout(() => {
        this.renderer.addClass(htmlElement, 'transparent-mode');
        this.renderer.addClass(bodyElement, 'transparent-mode');
        document.documentElement.style.setProperty(
          '--ion-background-color',
          'transparent'
        );
      }, 1000);
    } else {
      this.renderer.addClass(htmlElement, 'transparent-mode');
      this.renderer.addClass(bodyElement, 'transparent-mode');
      document.documentElement.style.setProperty(
        '--ion-background-color',
        'transparent'
      );

      const qrData: any = this.guestService.readQrCode;
      if (!this.guestService.isSelfCheckIn) {
        this.guestService
          .getScansNeeded(qrData?.hotelId, qrData?.bookingId, qrData?.roomId)
          .then((data: { total: number; scanned: number }) => {
            this.total = data.total;
            this.peopleScanned = data.scanned;
          });
      } else {
        //TODO
        this.total = this.guestService.totalGuestsToScan;
        this.peopleScanned = this.guestService.guestsScanned;
      }
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.drawOverlay();
    if (!Capacitor.isNativePlatform()) return;
    const permission = (await Camera.checkPermissions()).camera;
    if (permission !== 'granted') {
      await Camera.requestPermissions();
    }
    this.worker = await Tesseract.createWorker('mrz_fast', 1, {
      langPath: '/assets/tesseractData',
      gzip: false,
    });
    await this.scannerService.checkPermission();

    CameraPreview.initialize().then(async () => {
      await CameraPreview.startCamera();
      await this.getVideoDevices();
      await this.calculateCameraROI();
      await CameraPreview.setZoom({ factor: 1 });

      this.drawOverlay();
    });
  }

  public async test(): Promise<void> {
    Camera.getPhoto({
      quality: 100,
      resultType: CameraResultType.Base64,
    }).then((photo: Photo) => {
      const snapshot = `data:image/png;base64,${photo.base64String}`;
      this.worker.recognize(snapshot).then((data) => {
        console.log('data ', data);
      });
    });
  }

  public async captureFrame(): Promise<void> {
    this.isLoading = true;
    this.snapshot = `data:image/png;base64,${
      (await CameraPreview.takeSnapshot({ quality: 100 })).base64
    }`;

    this.divBackgroundSource = this.convertBase64ToBlobUrl(this.snapshot);
    const editedImage = await this.editImage(this.snapshot);

    const data = await this.worker.recognize(
      editedImage /* , {
      rectangle: { width: 100, height: 50, top: 150, left: 0 },
    } */
    );
    console.log('data ', data);
    this.processData(data.data);
  }

  public processData(scan: any): void {
    const mrz = this.getLastThreeLines(scan.text);
    console.log('final 3 lines ', mrz);

    try {
      const scanFields = MRZ.parse(mrz, { autocorrect: true }).fields;
      console.log('scan fields ', scanFields);

      const guestDetails = {
        birth_date: scanFields.birthDate,
        document_number: scanFields.documentNumber,
        document_type: this.documentType === 'id' ? 'I' : 'P',
        first_name:
          this.postProcessString(scanFields.firstName) ?? scanFields.lastName,
        last_name:
          this.postProcessString(scanFields.lastName) ?? scanFields.firstName,
        gender: scanFields.sex,
        nationality: scanFields.nationality,
      };
      if (this.isCheckIn) {
        this.checkInService
          .sendCheckInScan(this.roomId, guestDetails)
          .then(() => {
            this.isLoading = false;
            this.snapshot = null;
            this.divBackgroundSource = null;
            this.peopleScanned++;
            if (this.peopleScanned !== this.total) {
              this.matDialog
                .open(SuccessfulScanDialog, { disableClose: true })
                .afterClosed()
                .subscribe((nextScan: boolean) => {
                  if (!nextScan) this.backToBooking();
                });
            } else {
              this.matDialog
                .open(ScansCompletedDialog, { disableClose: true })
                .afterClosed()
                .subscribe(() => {
                  this.backToBooking();
                });
            }
            console.log('person scanned ', this.peopleScanned);
          })
          .catch(() => {
            this.isLoading = false;
            this.snapshot = null;
            this.divBackgroundSource = null;
            console.log('send scan failed');
            this.matDialog.open(UnsuccessfulScanDialog, {
              data: 'Sikertelen beolvasás, kérem próbálja meg újra',
            });
          });
      } else {
        this.guestService
          .sendScan(this.guestService.readQrCode, guestDetails)
          .then(() => {
            this.isLoading = false;
            this.snapshot = null;
            this.divBackgroundSource = null;
            this.peopleScanned++;
            if (this.peopleScanned !== this.total) {
              this.matDialog
                .open(SuccessfulScanDialog, { disableClose: true })
                .afterClosed()
                .subscribe((nextScan: boolean) => {
                  if (!nextScan) this.backToBooking();
                });
            } else {
              this.matDialog
                .open(ScansCompletedDialog, { disableClose: true })
                .afterClosed()
                .subscribe(() => {
                  this.backToBooking();
                });
            }
            console.log('person scanned ', this.peopleScanned);
          })
          .catch(() => {
            this.isLoading = false;
            this.snapshot = null;
            this.divBackgroundSource = null;
            console.log('send scan failed');
            this.matDialog
              .open(UnsuccessfulScanDialog, {
                data: 'Sikertelen beolvasás, kérem próbálja meg újra',
              })
              .afterClosed()
              .subscribe((isManual: boolean) => {
                if (isManual) {
                  this.backToBooking();
                  this.guestService.enableManualScan = true;
                }
              });
          });
      }
    } catch (error) {
      this.isLoading = false;
      this.snapshot = null;
      this.divBackgroundSource = null;
      console.log('mrz err');
      this.matDialog
        .open(UnsuccessfulScanDialog, {
          data: 'Sikertelen beolvasás, kérem próbálja meg újra',
        })
        .afterClosed()
        .subscribe((isManual: boolean) => {
          if (isManual) {
            this.backToBooking();
            this.guestService.enableManualScan = true;
          }
        });
      // TODO: Error snackbar
    }

    this.cdr.detectChanges();
  }

  // This tries to get rid of faulty scans
  private postProcessString(str: string): string {
    let processedString = str;
    const match = processedString.match(
      /(  |C{3,}|K{3,}|L{3,}| K | L | C | S| KK| CK| K| C| S| L)/
    );
    if (match) {
      processedString = processedString.slice(0, match.index);
    }

    return processedString;
  }

  private drawOverlay(): void {
    const overlayCanvas = this.canvas.nativeElement;
    const overlayContext = overlayCanvas.getContext('2d');
    overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

    overlayCanvas.width = this.canvas.nativeElement.clientWidth;
    overlayCanvas.height = this.canvas.nativeElement.clientHeight;

    console.log('width height ', overlayCanvas.width, overlayCanvas.height); //703x336

    const roiHeight = overlayCanvas.height * 0.8;
    const roiWidth =
      this.documentType === 'id'
        ? (roiHeight / 204) * 321
        : (roiHeight / 207.8) * 295.5;

    const roiX = (this.canvas.nativeElement.clientWidth - roiWidth) / 2;
    const roiY = (this.canvas.nativeElement.clientHeight - roiHeight) / 2;
    console.log(
      'overlay roi xy width height ',
      roiX,
      roiY,
      roiWidth,
      roiHeight
    );

    overlayContext.strokeStyle = '#df5f10';
    overlayContext.lineWidth = 2;
    overlayContext.strokeRect(roiX, roiY, roiWidth, roiHeight);
  }

  private async calculateCameraROI(): Promise<void> {
    // Get camera resolution
    const cameraResolution = await CameraPreview.getResolution();
    const cameraWidth = Number(cameraResolution.resolution.split('x')[1]);
    const cameraHeight = Number(cameraResolution.resolution.split('x')[0]);
    console.log('Camera width x height:', cameraWidth, cameraHeight);

    // Canvas dimensions
    const canvasWidth = this.canvas.nativeElement.clientWidth;
    const canvasHeight = this.canvas.nativeElement.clientHeight;
    console.log('Canvas width x height:', canvasWidth, canvasHeight);

    // Calculate ROI on the canvas
    const roiHeight = canvasHeight * 0.8;
    const aspectRatio = this.documentType === 'id' ? 204 / 321 : 207.8 / 295.5;
    const roiWidth = roiHeight / aspectRatio;

    const roiX = (canvasWidth - roiWidth) / 2;
    const roiY = (canvasHeight - roiHeight) / 2;

    // Convert to percentage (0.0 to 1.0)
    const cameraROI = {
      left: roiX / canvasWidth,
      top: roiY / canvasHeight,
      right: (roiX + roiWidth) / canvasWidth,
      bottom: (roiY + roiHeight) / canvasHeight,
    };

    console.log(
      'Mapped Camera ROI (top, left, right, bottom) in %:',
      cameraROI
    );

    // Set the camera's cropping region using percentage values
    await CameraPreview.setScanRegion({
      region: {
        top: Math.round(cameraROI.top * 100),
        left: Math.round(cameraROI.left * 100),
        right: Math.round(cameraROI.right * 100),
        bottom: Math.round(cameraROI.bottom * 100),
        measuredByPercentage: 1, // Use percentage values
      },
    });
  }

  private async getVideoDevices(): Promise<void> {
    this.videoDevices = (await CameraPreview.getAllCameras()).cameras;
  }

  public async switchNextVideo(): Promise<void> {
    if (this.videoDevices.length < 2) return;

    this.videoIndex =
      this.videoDevices.length - 1 !== this.videoIndex
        ? this.videoIndex + 1
        : 0;

    CameraPreview.selectCamera({
      cameraID: this.videoDevices[this.videoIndex],
    });

    const cameraResolution = await CameraPreview.getResolution();

    const cameraWidth = Number(cameraResolution.resolution.split('x')[1]); // Native camera width
    const cameraHeight = Number(cameraResolution.resolution.split('x')[0]); // Native camera height

    CameraPreview.setFocus({ x: cameraWidth / 2, y: cameraHeight / 2 });
  }

  public navToQrScanner(): void {
    this.routerService.absoluteNavigation('qr-scanner');
  }

  public backToBooking(): void {
    this.bookingService.setSelectedBookingsRooms();
    if (this.isCheckIn) {
      this.closeScanner.emit();
    } else {
      this.routerService.absoluteNavigation('calendar/arrival');
    }
  }

  private enableManualScan(): void {
    this.guestService.enableManualScan = true;
    this.backToBooking();
  }

  public backToCalendar(): void {
    this.routerService.absoluteNavigation('calendar');
  }

  private getLastThreeLines(text: string): string {
    if (!text) return '';
    // Split the text by line breaks (supporting both Windows and Unix line endings)
    let lines = text.split(/\r?\n/);
    lines = lines.filter((line) => line !== '' || line.length > 5);

    // Get the last 3 lines
    let lastThreeLines =
      this.documentType === 'id' ? lines.slice(-3) : lines.slice(-2);
    console.log('lastThreeLines ', lastThreeLines);
    lastThreeLines = this.adjustLinesToClosestNumber(
      lastThreeLines,
      this.acceptedLengths
    );
    console.log('adjustLinesToClosestNumber ', lastThreeLines);

    // Join the lines back with line breaks
    return lastThreeLines.join('\n');
  }

  private adjustLinesToClosestNumber(
    lines: string[],
    numbers: number[]
  ): string[] {
    const firstLine = lines[0];

    const closestNumber = this.documentType === 'id' ? 30 : 44;

    const adjustedLines = lines.map((line) => {
      const lineLength = line.length;
      const difference = closestNumber - lineLength;

      if (difference > 0) {
        return line.padEnd(closestNumber, '<');
      } else if (difference < 0) {
        return line.slice(0, closestNumber);
      } else {
        return line;
      }
    });

    return adjustedLines;
  }

  public switchIdType(): void {
    this.documentType = this.documentType === 'id' ? 'passport' : 'id';
    this.drawOverlay();
  }

  private convertBase64ToBlobUrl(base64: string): string {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([uintArray], { type: 'image/png' });
    return URL.createObjectURL(blob);
  }

  private async editImage(base64: string): Promise<string> {
    const base64Data = base64.split(',')[1];
    const buffer = Buffer.from(base64Data, 'base64');
    const image = await Jimp.read(buffer);
    image.contrast(0.3).greyscale();
    return image.getBase64('image/png');
  }

  ngOnDestroy(): void {
    const htmlElement = document.querySelector('html');
    const bodyElement = document.querySelector('body');
    this.renderer.removeClass(htmlElement, 'transparent-mode');
    this.renderer.removeClass(bodyElement, 'transparent-mode');
    this.guestService.totalGuestsToScan = null;
    this.guestService.readQrCode = null;
    CameraPreview.stopCamera().catch(() => {
      console.log('catch stop camera');
    });
    ScreenOrientation.unlock();
  }
}
