import { Component } from '@angular/core';
import { LanguageService, RouterService } from 'src/app/@services';

@Component({
    selector: 'welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    standalone: false
})
export class WelcomeComponent {
  constructor(
    private routerService: RouterService,
    public langService: LanguageService
  ) {}

  public navToCheckIn(): void {
    this.routerService.absoluteNavigation('online-check-in');
  }

  public navToLogin(): void {
    this.routerService.absoluteNavigation('login');
  }
}
