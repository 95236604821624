import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../@services';
import { inject } from '@angular/core';
import { map } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isAuthenticatedAsync) return true;

  return authService.isAuthenticatedAsync().pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return true; // Allow access if authenticated
      } else {
        return false;
      }
    })
  );
};
