import { Component, Input, OnInit } from '@angular/core';
import { BookingView } from 'src/app/@Interfaces';
import {
  BookingService,
  CalendarService,
  LanguageService,
  RouterService,
  TableService,
} from 'src/app/@services';

@Component({
  selector: 'booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  standalone: false,
})
export class BookingComponent implements OnInit {
  constructor(
    private calendarService: CalendarService,
    public bookingService: BookingService,
    private routerService: RouterService,
    private tableService: TableService,
    public langService: LanguageService
  ) {}

  @Input() bookingView: BookingView;

  public width: string;
  public offSet: string;

  ngOnInit(): void {
    this.setBookingWidth();
  }

  public setBookingWidth(): void {
    const nights =
      this.calendarService.calculateDaysDifference(
        this.bookingView.arrDate,
        this.bookingView.depDate
      ) - 1;
    const width = nights * 70 + 60;

    if (this.bookingView.reversed) {
      this.offSet = (width - 30) * -1 + 'px';
    }
    this.width = width + 'px';
  }

  public selectBooking(event: Event): void {
    event.stopPropagation();
    this.routerService.addQueryParams({
      booking: this.bookingView.bookingId,
      room: this.bookingView.roomId,
    });

    this.routerService.absoluteNavigation('calendar/modify-rooms');
  }

  public getBookingStatus(): string {
    const booking = this.bookingService.bookings.find(
      (booking) =>
        booking.booking_id === this.bookingView.bookingId &&
        booking.room_id === this.bookingView.roomId
    );
    if (!booking) {
      return '';
    }
    const departed = booking.departed === true;
    const paid = booking.paid === true;
    const arrived = booking.arrived === true;

    if (departed) return 'departed';
    if (paid) return 'paid';
    if (arrived) return 'arrived';

    return '';
  }
  public getBookingSource(): string {
    const booking = this.bookingService.bookings.find(
      (booking) =>
        booking.booking_id === this.bookingView.bookingId &&
        booking.room_id === this.bookingView.roomId
    );
    if (!booking) {
      return null;
    }

    return booking.source;
  }
}
