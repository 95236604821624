import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/@services';

@Component({
  selector: 'scans-completed-dialog',
  templateUrl: './scans-completed.dialog.html',
  styleUrls: ['./scans-completed.dialog.scss'],
  standalone: false,
})
export class ScansCompletedDialog {
  constructor(
    private dialogRef: MatDialogRef<ScansCompletedDialog>,
    public langService: LanguageService
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
