import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { paymentOptions, vatRateOptions } from 'src/app/@consts';
import { InvoiceDialog } from 'src/app/@dialogs/invoice/invoice.dialog';
import { ServiceSelectDialog } from 'src/app/@dialogs/service-select/service-select';
import {
  ChargeInterface,
  ExtendedBookingRoom,
  ServiceInterface,
} from 'src/app/@Interfaces';
import {
  BookingService,
  CalendarService,
  ChargeService,
  DateService,
  FocusService,
  HotelService,
  LanguageService,
  RoomService,
  RouterService,
  ServiceService,
} from 'src/app/@services';
import { ChargeTypes } from 'src/app/enums';

@Component({
  selector: 'booking-rooms-payment',
  templateUrl: './booking-rooms-payment.component.html',
  styleUrls: ['./booking-rooms-payment.component.scss'],
  standalone: false,
})
export class BookingRoomsPaymentComponent implements OnInit, OnDestroy {
  constructor(
    private chargeService: ChargeService,
    private matDialog: MatDialog,
    private routerService: RouterService,
    private serviceService: ServiceService,
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public dateService: DateService,
    public focusService: FocusService,
    public roomService: RoomService,
    public langService: LanguageService,

    private hotelService: HotelService
  ) {}

  public readonly paymentOptions: any = paymentOptions;
  public readonly vatRateOptions: any = vatRateOptions;
  public isLoading: boolean;
  public rooms: ExtendedBookingRoom[] = [];
  public charges: ChargeInterface[];
  public totalPrice: number;
  public paidAmount: number;
  public remainingAmount: number;
  public selectedRoom: ExtendedBookingRoom;
  public areAllRoomsSelected: boolean;
  public chargeTypes = ChargeTypes;
  public groupPayment = { payment_date: null, payment_method: null };
  private paramSub: Subscription;
  private params: any = {};
  private bookingSub: Subscription;

  ngOnInit(): void {
    this.paramSub = this.routerService.queryParams$.subscribe((params) => {
      if (!params) return;
      this.params = params;
    });
    this.bookingSub = this.bookingService.selectedBooking$.subscribe(() => {
      if (!this.bookingService.sidePanelRooms.length) return;
      this.rooms = this.bookingService.sidePanelRooms;
      const room = this.rooms.find(
        (room) => room.room_id === Number(this.params.room)
      );
      this.selectRoom(room);
      this.getCharges(this.params.hotel, this.params.booking);
    });
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public addCharge(): void {
    this.matDialog
      .open(ServiceSelectDialog, { data: this.serviceService.services })
      .afterClosed()
      .subscribe((service: ServiceInterface) => {
        if (!service) return;
        this.getCharges();
      });
  }

  public getCharges(
    hotelId: string | number = null,
    bookingId: string | number = null
  ): void {
    this.chargeService
      .getCharges(hotelId, bookingId)
      .then((charges: ChargeInterface[]) => {
        this.groupPayment.payment_date = this.dateService.formatDate(
          new Date()
        );

        this.setPayDayToday(charges);
        this.charges = charges;
        this.setChargeSummary();
        this.remainingAmountForRoom();
      })
      .catch((err) => {
        console.log('get charges error', err);
      });
  }

  public selectRoom(room: ExtendedBookingRoom): void {
    this.selectedRoom = room;

    this.routerService.addQueryParams({ room: room.room_id });
    this.setChargeSummary();
  }

  public async payRoom(roomId: string): Promise<void> {
    this.isLoading = true;
    this.bookingService
      .payRoom(this.bookingService.selectedBooking.booking_id, {
        rooms: [
          {
            room_id: Number(roomId),
            gross_price: this.bookingService.sidePanelRooms[roomId].gross_price,
          },
        ],
      })
      .then(async () => {
        this.isLoading = false;
        this.bookingService.getBookings().catch(() => {});
        this.getCharges();
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public formatDate(charge: ChargeInterface, date: Date): void {
    charge.payment_date = this.dateService.formatDate(date);
  }

  public setVatIfKnown(charge: ChargeInterface): void {
    if (charge.charge_type === this.chargeTypes.prepayment) {
      charge.vat_rate = 5;
    }
  }

  public payAllCharges(): void {
    const requestBody: { charges: any[] } = { charges: [] };
    this.charges.forEach((charge) => {
      if (
        (this.areAllRoomsSelected ||
          charge.room_id === this.selectedRoom.room_id) &&
        !charge.paid
      ) {
        requestBody.charges.push({
          charge_id: charge.charge_id,
          payment_method: this.groupPayment.payment_method,
          payment_date: this.dateService.formatDate(
            new Date(this.groupPayment.payment_date)
          ),
        });
      }
    });
    this.chargeService
      .payCharge(requestBody)
      .then(() => {
        this.getCharges();
        const hotelId = this.hotelService.selectedHotel.hotel_id.toString();
        const bookingId =
          this.bookingService.selectedBooking.booking_id.toString();
        const roomId = this.bookingService.selectedBooking.room_id.toString();
        this.bookingService
          .getBooking(hotelId, bookingId, roomId)
          .catch(() => {});
      })
      .catch(() => {});
  }

  public selectAllRooms(): void {
    this.areAllRoomsSelected = true;
    this.setChargeSummary();
  }

  public deselectAllRooms(): void {
    this.areAllRoomsSelected = false;
    this.setChargeSummary();
  }

  public remainingAmountForRoom(): void {
    let remainingAmounts: { [key: number]: number } = {};

    this.charges.forEach((charge: ChargeInterface) => {
      if (charge.paid) return;
      if (!remainingAmounts[charge.room_id])
        remainingAmounts[charge.room_id] = 0;

      remainingAmounts[charge.room_id] += charge.gross_price * charge.qty;
    });

    this.rooms.forEach((room) => {
      room.remainingAmount = Math.round(remainingAmounts[room.room_id]);
      if (isNaN(room.remainingAmount)) room.remainingAmount = 0;
    });
  }

  public setAllPaymentMethod(paymentMethod: any): void {
    if (!this.charges) return;

    this.charges = this.charges.map((charge: ChargeInterface) => {
      if (!charge.paid) {
        charge.payment_method = paymentMethod;
      }
      return charge;
    });
  }

  public setAllPaymentDate(paymentDate: any): void {
    this.charges = this.charges.map((charge: ChargeInterface) => {
      if (!charge.paid) {
        charge.payment_date = paymentDate;
      }
      return charge;
    });
  }

  public openInvoiceDialog(): void {
    this.matDialog.open(InvoiceDialog, { data: this.charges });
  }

  public refreshData(): void {
    this.getCharges();
    this.bookingService.getBookings().catch(() => {});
  }

  private setChargeSummary(): void {
    this.totalPrice = 0;
    this.paidAmount = 0;
    this.remainingAmount = 0;
    if (!this.charges) return;
    this.charges.forEach((charge: ChargeInterface) => {
      if (
        this.selectedRoom.room_id !== charge.room_id &&
        !this.areAllRoomsSelected
      ) {
        return;
      }
      this.totalPrice += Math.round(charge.gross_price * charge.qty);
      if (charge.paid) {
        this.paidAmount += Math.round(charge.gross_price * charge.qty);
      } else {
        this.remainingAmount += Math.round(charge.gross_price * charge.qty);
      }
    });
  }

  private setPayDayToday(charges: ChargeInterface[]): void {
    charges.map((charge) => {
      if (!charge.paid)
        charge.payment_date = this.dateService.formatDate(new Date());
    });
  }

  public newReference(): void {
    this.groupPayment = { ...this.groupPayment };
  }

  ngOnDestroy(): void {
    this.paramSub.unsubscribe();
    this.bookingSub.unsubscribe();
  }
}
