import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ExtendedBookingRoom } from 'src/app/@Interfaces';
import { LanguageService } from 'src/app/@services';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { RoomService } from 'src/app/@services/room.service';

@Component({
  selector: 'booking-rooms-depart',
  templateUrl: './booking-rooms-depart.component.html',
  styleUrls: ['./booking-rooms-depart.component.scss'],
  standalone: false,
})
export class BookingRoomsDepartComponent implements OnInit, OnDestroy {
  constructor(
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    public langService: LanguageService
  ) {}

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  public roomsToDepart: number[] = [];
  public isLoading: boolean;
  public rooms: ExtendedBookingRoom[] = [];
  private bookingSub: Subscription;

  ngOnInit(): void {
    this.bookingSub = this.bookingService.selectedBooking$.subscribe(() => {
      if (!this.bookingService.sidePanelRooms.length) return;
      this.rooms = this.bookingService.sidePanelRooms;
    });
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public cancel(): void {
    this.closePanel.emit();
  }

  public selectToDepart(room: ExtendedBookingRoom): void {
    if (room.departed || !room.paid || !room.arrived) {
      return;
    }

    const index = this.roomsToDepart.indexOf(room.room_id);

    if (index !== -1) {
      this.roomsToDepart.splice(index, 1);
    } else {
      this.roomsToDepart.push(room.room_id);
    }
  }

  public departSelectedRooms(): void {
    const roomIds = this.roomsToDepart.map((room) => Number(room));
    this.isLoading = true;
    this.bookingService
      .departRooms(
        this.bookingService.selectedBooking.booking_id,
        this.roomsToDepart
      )
      .then(() => {
        this.isLoading = false;
        this.bookingService.updateBookingRoomStatuses(
          this.bookingService.selectedBooking.booking_id,
          roomIds,
          'departed',
          true
        );
        this.bookingService.setSelectedBookingsRooms();
        this.roomsToDepart = [];
        this.rooms = this.bookingService.sidePanelRooms;
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public selectAll(): void {
    this.rooms.forEach((room) => {
      if (room.paid && room.arrived) {
        this.selectToDepart(room);
      }
    });
  }

  ngOnDestroy(): void {
    this.bookingSub.unsubscribe();
  }
}
