import { ElementRef, Injectable } from '@angular/core';
import { Camera } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  constructor() {}

  public async checkPermission(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!Capacitor.isNativePlatform()) resolve();
      const permission = await Camera.checkPermissions().catch(() => {
        return { camera: 'denied' };
      });
      console.log('permission ', permission);

      if (permission.camera !== 'denied') {
        resolve();
      } else {
        Camera.requestPermissions();
        resolve();
      }
    });
  }
}
